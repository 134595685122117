<div class="container mt-3">
  <div class="row mb-3">
    <!-- Upload CV Button -->
    <div class="col-md-6">
      <label for="cv-upload" class="btn btn-primary btn-block cvjd">Add CV</label>
      <input id="cv-upload" type="file" class="d-none" (change)="onFileSelected($event, 'CV')">
    </div>

    <!-- Upload JD Button -->
    <div class="col-md-6">
      <label for="jd-upload" class="btn btn-primary btn-block cvjd">Add JD</label>
      <input id="jd-upload" type="file" class="d-none" (change)="onFileSelected($event, 'JD')">
    </div>
  </div>

  <!-- <div class="mb-3 text-center " style="display: flex; justify-content: center; ">
    <select class="form-select form-select-sm" style="width: auto; margin-top:10px;" [(ngModel)]="displayType">
      <option value="All">All</option>
      <option value="CV">CV</option>
      <option value="JD">JD</option>
    </select>
  </div> -->

  <table class="table">
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Size (bytes)</th>
        <th>Format</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let fileDetail of displayedFiles(); let i = index">
        <td>{{ fileDetail.file.name }}</td>
        <td>{{ fileDetail.type }}</td>
        <td>{{ fileDetail.file.size }}</td>
        <td>{{ fileDetail.format }}</td>
        <td>
          <button class="btn btn-danger btn-sm" (click)="removeFile(i)">
            <i class="fas fa-trash"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="mb-3 d-flex " style="gap: 10px;">
    <button (click)="uploadAllCVs()" class="btn btn-info" style="border-radius: 0;">Upload</button>
    <!-- <button (click)="uploadAllJDs()" class="btn btn-info" style="border-radius: 0;">Upload JD(s)</button> -->
    <!-- <button (click)="changestatus()" class="btn btn-success" style="border-radius: 0;">Save</button> -->
  </div>
</div>
