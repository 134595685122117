import { Injectable } from '@angular/core';
import { CommonService } from '../Utils/CommonService';
import { Constants } from '../Utils/Constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private commonService: CommonService) {}

  getConfig = () => new Promise((resolve, reject) => {
    let configUrl: string;

    if (environment.dev) {
      configUrl = 'assets/config.json';
    } else if (environment.uat) {
      configUrl = 'assets/config-uat.json';
    } else if (environment.production) {
      configUrl = 'assets/config-prod.json';
    } else {
      configUrl = 'assets/config.json';
    }

    this.commonService.getMethodAxios(configUrl)
      .then((response: any) => {
        Constants.config = response.data;
        resolve(Constants);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
