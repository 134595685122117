import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Input } from '@angular/core';
import { InterAssistService } from '../service/inter-assist.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';

interface FileDetail {
  file: File;
  type: 'CV' | 'JD';
  format: string;
}

@Component({
  selector: 'app-cv-jd',
  templateUrl: './cv-jd.component.html',
  styleUrls: ['./cv-jd.component.scss']
})
export class CvJdComponent {
  @Input() uniqid!: string; // Input decorator to accept id from parent component
  files: FileDetail[] = [];
  displayType: 'CV' | 'JD' | 'All' = 'All';

  constructor(private http: HttpClient,
    private panelservice: InterAssistService,
    private toastservice : ToastMessageService,
    private loaderservice : LoaderService,
  ) {}

  onFileSelected(event: any, fileType: 'CV' | 'JD'): void {
    const file = event.target.files[0];
    if (file) {
      this.files.push({
        file: file,
        type: fileType,
        format: file.name.split('.').pop()
      });
    }
  }

  uploadAllCVs(): void {
    this.loaderservice.show();
    const formData = new FormData();
    this.files.filter(f => f.type === 'CV').forEach(cv => {
      formData.append('cv_file', cv.file, cv.file.name);
    });

    this.files.filter(f => f.type === 'JD').forEach(jd => {
      formData.append('jd_file', jd.file, jd.file.name);
    });

    formData.append('id', this.uniqid); // Append the unique id to the form data

    this.panelservice.uploadcvjd(formData).subscribe
    (
      (response:any) => {

        if(response.status ==  'success'){
      this.loaderservice.hide();
      this.toastservice.showSuccess(response.message);
        }
    },
    (error) => {
      this.loaderservice.hide();
      this.toastservice.showError(error.message);
    }


    );
  }

  uploadAllJDs(): void {
    const formData = new FormData();
    this.files.filter(f => f.type === 'JD').forEach(jd => {
      formData.append('jds', jd.file, jd.file.name);
    });

    this.http.post('your-jd-api-url', formData).subscribe(
      response => console.log('JDs uploaded successfully!', response),
      error => console.error('Failed to upload JDs', error)
    );
  }

  submitFiles(): void {
    const formData = new FormData();
    this.files.forEach(fd => {
      formData.append(fd.type, fd.file, fd.file.name);
    });

    this.http.post('your-general-api-url', formData).subscribe(
      response => console.log('Upload successful', response),
      error => console.error('Upload failed', error)
    );
  }

  removeFile(index: number): void {
    this.files.splice(index, 1);
  }

  displayedFiles(): FileDetail[] {
    return this.displayType === 'All' ? this.files : this.files.filter(file => file.type === this.displayType);
  }

  changestatus(){
    this.loaderservice.show();
    const formData = new FormData();
    formData.append('id', this.uniqid); // Append the unique id to the form data
    formData.append('status', 'QnA'); // Append the status to the form data
 this.panelservice.changestatus(formData).subscribe(
      (response:any) => {

        if(response.status ==  'success'){
      this.loaderservice.hide();
      this.toastservice.showSuccess(response.message);
        }
        else{
          this.loaderservice.hide();
        }
    },
    (error) => {
      this.loaderservice.hide();
      this.toastservice.showError(error.message);
    }
 );


  }


}
