<div class="background-container px-md-4 pb-5 px-sm-3">
      <div class="col-12 text-center mb-3">
        <h3 style="font-family: Georgia; color: #073262; ">
            <b>SharePoint2Vector</b>
        </h3>
      </div>
      <div>
      <div class="row p-3 justify-content-center content shadow border">
        <div class="col-md-6 mt-2">
            <div class="input">URL</div>
            <input type="text" style="width: 70%; border-radius: 0; margin-top: 10px;" class="form-control"
              placeholder="Enter url" [(ngModel)]="sharepointURL"
              (input)="windowRef.localStorage.setItem('path', sharepointURL)">
          </div>
          <div class="col-md-6 mt-4" style="display: flex; justify-content: center;">
            <button class="bg-primary text-light p-2 px-4 border border-r1" style="margin-top: 10px; width: 40%; border-radius: 0;" (click)="getSitefromURL()">Get Sites</button>
          </div>
          <!-- <div class="col-md-6  mt-4" style="display: flex; justify-content: center;">
            <button [disabled]="siteId === 'all'" class="bg-primary text-light p-2 px-4 border border-r1" style="margin-top: 10px; width: 40%; border-radius: 0;" (click)="getDrivesfromSite()">Get Drives</button>
          </div> -->
          
            <div class="col-md-6 mt-2">            
                <div class="input">Sites list</div>
                  <select class="form-select" title="Load an Example" name="siteId" [(ngModel)]="siteId"
                    style="border-radius: 0; width: 70%; margin-top: 10px;">
                    <option *ngFor="let site of sitesList" [value]="site.id">{{ site.name }}</option>
                  </select>
                  </div> 
                  <div class="col-md-6  mt-4" style="display: flex; justify-content: center;">
                    <button [disabled]="siteId === 'all'" class="btn bg-primary text-light p-2 px-4 border border-r1" style="margin-top: 10px; width: 40%; border-radius: 0;" (click)="getDrivesfromSite()">Get Drives</button>
                  </div>

                  <div class="col-md-6 mt-2">            
                    <div class="input">Drives list</div>
                      <select class="form-select" title="Load an Example" name="driveId" [(ngModel)]="driveId"
                        style="border-radius: 0; width: 70%; margin-top: 10px;">
                        <option *ngFor="let drive of drivesList" [value]="drive.id">{{ drive.name }}</option>
                      </select>
                      <!-- <input class="form-control" *ngIf="reviewCategory === 'other'" type="text" [(ngModel)]="newReviewCategoryOption" placeholder="Enter a New Review Category" style="margin-top: 10px; width: 100%; border-radius: 0;" (input)="windowRef.localStorage.setItem('file_type', reviewCategory)"> -->
                      </div> 

                      <div class="col-md-6  mt-2 ">
                        <div class="input">SAS Token</div>
                        <input type="text" style="width: 70%; border-radius: 0; margin-top: 10px;" class="form-control"
                          placeholder="Enter SAS Token" [(ngModel)]="sToken"
                          (input)="windowRef.localStorage.setItem('sas_token', sToken)">
                      </div>
                      
                      
                        <div class="col-md-6  mt-2 ">
                          <div class="input">Collection Name</div>
                          <input type="text" style="width: 70%; border-radius: 0; margin-top: 10px;" class="form-control"
                            placeholder="Enter Collection Name" [(ngModel)]="collectionName"
                            (input)="windowRef.localStorage.setItem('collection_name', collectionName)">
                        </div>

                        <div class="col-md-6 mt-4" style="display: flex; justify-content: center;">
                          <button class="bg-primary text-light p-2 px-4 border border-r1" style="margin-top: 10px; width: 40%; border-radius: 0;" (click)="getSharepointUpload()">Upload</button>
                           </div>


                        <div class="col-md-6 mt-4" style="display: flex; justify-content: center;">
                        <button class="bg-primary text-light p-2 px-4 border border-r1" style="margin-top: 10px; width: 40%; border-radius: 0; align-items: center;" (click)="getStatusfromDrives()">Get Status</button>
                      </div>
                      </div>
                        <div class="row content shadow border mt-3  p-3 " *ngIf="successList.length > 0">
                          <div class="shine-dim-container">
                            <h3 style="font-family: Georgia; color: #073262; align-items: center; display: flex; justify-content: center;">
                              {{ uploadStatus }}
                            </h3>
                          </div>
                          
                          <div style="display: flex; justify-content: space-between;" >
                            <div style="width: 48%; box-sizing: border-box;" >
                              <h2 style="font-family: Georgia; color: #073262;display: flex;justify-content: center; ">
                                Passed Files<i class="fa fa-check" aria-hidden="true" style="color: green;"></i></h2>
                              <ul class="list-group">
                                <li class="list-group-item" *ngFor="let file of successList">{{ file }}</li>
                              </ul>
                            </div>
                            <div style="width: 48%; box-sizing: border-box;" *ngIf="failedList.length > 0">
                              <h2 style="font-family: Georgia; color: #073262;display: flex;justify-content: center;">
                              Failed Files<i class="fa fa-times" aria-hidden="true" style="color: red;"></i></h2>
                              <ul class="list-group">
                                <li class="list-group-item" *ngFor="let file of failedList">{{ file }}</li>
                              </ul>
                            </div>
                          </div>
                        </div>
</div>
                      
    
</div>    
