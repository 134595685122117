<div class="wrapper">
<!-- <app-sop-sidenav></app-sop-sidenav> -->

<body >
<section class="content">


    <div class="background-container">

        <!-- <img src="assets/background.png" alt="Background Image" class="background-image"> -->
        <div class="text-overlay">
          <!-- <h4 style="text-align: center;"><em><b>Virtual Assist</b></em></h4> -->
            <p>
                Our advanced AI-powered virtual assistant is designed to be your go-to solution for all your queries and information needs. Whether you're seeking quick answers or in-depth knowledge, our bot has got you covered.
            </p>
            <p>
<b style="font-size: larger;">How It Works:</b><br>

Using our virtual assistant is simple and user-friendly. Just type in your question or query, and our AI-powered bot will swiftly analyze your input to provide you with the most relevant and precise answers from our knowledge base. Our advanced natural language processing (NLP) capabilities allow the bot to understand complex queries and respond in a conversational manner, making your interactions feel seamless and intuitive.
</p>
<p>
<b style="font-size: larger;">Customer Support:</b><br>

While our virtual assistant is designed to be highly efficient and accurate, we understand that questions or issues may arise. If you encounter any difficulties or have feedback, our friendly customer support team is ready to assist you. Feel free to reach out to us via 800100279@genpact.com.
            </p>
          </div>
    </div>
  </section>
  </body>
</div>
