import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';

import { OktaAuth } from '@okta/okta-auth-js';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Subscription } from 'rxjs';
import { SopService } from '../shared/service/sop.service';
import { OktaAuthService } from '../shared/okta/okta.service';
import { ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-sop-sidenav',
  templateUrl: './sop-sidenav.component.html',
  styleUrls: ['./sop-sidenav.component.scss']
})
export class SopSidenavComponent implements OnInit, OnDestroy {
  [x: string]: any;

title: any;
  selectedRole: any ='User';
  subscription!: Subscription;
  username:any;
  testcase:boolean = false;

  constructor(
    @Inject(OKTA_AUTH)public oktaAuth: OktaAuth,
     private router: Router,
     private sopService: SopService,
     private route: ActivatedRoute,
     private oktaService:OktaAuthService
     ) { }

     ngOnInit(): void {
       this.getUserRole();
      this.sopService.pageTitle.subscribe((res:any)=>{
        this.title = res?.title;
      });
//for test case generator
this.route.params.subscribe(params => {
  const currentRoute = this.router.url;

  if (currentRoute === '/tcg') {
    this.testcase = true;
  } else {
    this.testcase = false;
  }

  // Add more else if conditions for other routes
});
     }


  clos(){
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "250px" || width == "") {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
   }



  Navi() {
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "0px" || width == "") {
      el.style.width = "250px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
  }

  toggleClass(arg0: string, arg1: Element) {
    // throw new Error('Method not implemented.');
  }

  getUserRole() {
    this.subscription = this.sopService.activeUserRole.subscribe(
      (res: any) => {
        if (res?.name) {
         this.selectedRole = res?.name;

        }
      });
  }

  logout() {

    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.removeItem('home_policy');
    localStorage.clear();
    sessionStorage.clear();
    this.oktaService.logout();
    this.router.navigate(['logout']);
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

}
