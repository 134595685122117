import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UsermanageComponent } from './usermanage/usermanage.component';

import { OktaCallbackComponent } from '@okta/okta-angular';
import { ConverseAiComponent } from './converse-ai/converse-ai.component';
import { ConverseComponent } from './converse/converse.component';
import { SopChatbotComponent } from './sop-chatbot/sop-chatbot.component';
import { SopPreviousresponsesComponent } from './sop-previousresponses/sop-previousresponses.component';
import { SopToolComponent } from './sop-tool/sop-tool.component';
import { SopUploadBotdocComponent } from './sop-upload-botdoc/sop-upload-botdoc.component';
import { SopUploadComponent } from './sop-upload/sop-upload.component';

import { AdminRoleGuard } from './guards/admin-role.guard';

import { ChatbotSectionComponent } from './Devopschat/Components/chatbot-section/chatbot-section.component';
import { DevOpsGPTComponent } from './Devopschat/Components/dev-ops-gpt/dev-ops-gpt.component';
import { Sharepoint2vectorComponent } from './sharepoint2vector/sharepoint2vector.component';
import { UsecaseTwoFineTuneModelComponent } from './Devopschat/Components/usecase-two-fine-tune-model/usecase-two-fine-tune-model.component';
import { AdminPortalComponent } from './admin-portal/admin-portal.component';


import { HomeComponent1 } from './codeGenYLite/components/home/home.component';
import { ProductsComponent } from './codeGenYLite//components/products/products.component';
import { CompanyComponent } from './codeGenYLite//components/company/company.component';
import { FeaturesComponent } from './codeGenYLite//components/features/features.component';
import { HomeMainComponent } from './codeGenYLite//components/home-main/home-main.component';
// import { DevhomeComponent } from './Devflow/components/devhome/devhome.component';
import { TestcaseGeneratorComponent } from './testcase-generator/testcase-generator.component';
import { SystranTranslateComponent } from './systran-translate/systran-translate.component';

// import { AuthGuard } from './Devflow/Services/auth.guard';
import { CodeGenYLiteAIComponent} from './codeGenYLiteAI/code-gen-ylite-ai.component';
import { DocgenerateComponent} from './codeGenYLiteAI/docgenerate/docgenerate.component';
import { ApigenerateComponent } from './codeGenYLiteAI/apigenerate/apigenerate.component';
import { CodegenerateComponent } from './codeGenYLiteAI/codegenerate/codegenerate.component';
import { SddaiComponent } from './codeGenYLiteAI/sddai/sddai.component';
import { EnhancecodeComponent} from './codeGenYLiteAI/enhancecode/enhancecode.component';
import { DocumentcodeComponent } from './codeGenYLiteAI/documentcode/documentcode.component';
import { ChatcodeComponent } from './codeGenYLiteAI/chatcode/chatcode.component';
import { TcgenerateComponent } from './codeGenYLiteAI/tcgenerate/tcgenerate.component';
import { CvJdComponent } from './interview-assistant/cv-jd/cv-jd.component';
import { InterviewAssistantComponent } from './interview-assistant/interview-assistant.component';
import { QandAComponent } from './interview-assistant/qand-a/qand-a.component';
import { TranscriptComponent } from './interview-assistant/transcript/transcript.component';
import { IaloginComponent } from './interview-assistant/ialogin/ialogin.component';
import { IhomeComponent } from './interview-assistant/ihome/ihome.component';
import { ReportAnalysisComponent } from './interview-assistant/report-analysis/report-analysis.component';

import { ChatComponent } from './chat/chat.component';
import { TestcaseComponent } from './testcase/testcase.component';

const routes: Routes = [


  { path: '', component: HomeComponent },
  { path: 'home', component: OktaCallbackComponent },
  // { path: ':path', component: HomeComponent }, // Wildcard route to capture all paths

  { path: 'user', component: OktaCallbackComponent },

  { path: 'logout', component: LogoutComponent },

  // { path: 'devops', component: ChatbotSectionComponent },
  // { path: 'use-case-one', component: ChatbotSectionComponent },
  // { path: 'fine-tune-model', component: UsecaseTwoFineTuneModelComponent },
  // { path: 'codeGenYLiteAI1', component: DevOpsGPTComponent },
  // { path: 'sharepoint2vector', component: Sharepoint2vectorComponent},
    { path: 'tcg', component: TestcaseComponent},
  // { path: 'translate', component: SystranTranslateComponent},


  { path:'admin', component:AdminPortalComponent,children:[
    {
      path: 'rag-process',
      loadChildren: () =>
        import('./admin-portal/rag-process/rag-process.module').then((m) => m.RagProcessModule),
    },
    {
      path: 'api-access',
      loadChildren: () =>
        import('./admin-portal/api-access/api-access.module').then((m) => m.ApiAccessModule),
    },
    {
      path: 'user-role',
      loadChildren: () =>
        import('./admin-portal/user-role/user-role.module').then((m) => m.UserRoleModule),
    },
  ]},

  {
    path: 'interview-assist', component: InterviewAssistantComponent, children: [
      { path: '', redirectTo: 'Assisthome', pathMatch: 'full' },
      {path: 'Assisthome' , component: IhomeComponent, canActivate: [AuthGuard]},
      {path: 'TalentAdmin', component: IaloginComponent,  canActivate: [AuthGuard]},
      { path: 'cv-jd', component: CvJdComponent,  canActivate: [AuthGuard] },
      { path: ':rid/:id/IntelliQ&A', component: QandAComponent, canActivate: [AuthGuard] },
      // { path: '/evaluate', component: QandAComponent},
      {path: ':rid/:id/Report-Gen', component: TranscriptComponent,  canActivate: [AuthGuard]},
      {path: 'feedback', component: ReportAnalysisComponent,  canActivate: [AuthGuard]},
      // {path: 'transcript-manager', component: TranscriptComponent},
    ]

  },

  {
    path: '', component: ConverseAiComponent, children: [
      // {path:'',redirectTo: '/konverse',pathMatch:'full'},
      { path: 'sop-tool', component: SopToolComponent, canActivate: [AuthGuard] },
      { path: 'sop-upload', component: SopUploadComponent, canActivate: [AuthGuard, AdminRoleGuard] },
      { path: 'sop-chat', component: SopChatbotComponent, canActivate: [AuthGuard] },
      { path: 'sop-previousresponses', component: SopPreviousresponsesComponent, canActivate: [AuthGuard] },
      { path: 'konverse', component: ConverseComponent, canActivate: [AuthGuard] },
      { path: 'konverse/:id', component: ConverseComponent, canActivate: [AuthGuard] },
      { path: 'sop-file-upload', component: SopUploadBotdocComponent, canActivate: [AuthGuard,AdminRoleGuard] },
      { path: 'manage_user', component: UsermanageComponent, canActivate: [AuthGuard,AdminRoleGuard]},
      { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [AuthGuard] },

    ]
  },

{ path: 'codeGenYLiteAI', component: CodeGenYLiteAIComponent, children: [
  {path: '', redirectTo: 'brd', pathMatch: 'full'},
  //{ path: 'home', component: HomeComponent1, canActivate: [AuthGuard]},
  {path: 'sdd', component: SddaiComponent, canActivate: [AuthGuard]},
  {path: 'brd', component: DocgenerateComponent, canActivate: [AuthGuard]},
  {path: 'apigenerate', component: ApigenerateComponent, canActivate: [AuthGuard]},
  {path: 'codegenerate', component: CodegenerateComponent, canActivate: [AuthGuard]},
  {path: 'enhancecode', component: EnhancecodeComponent, canActivate: [AuthGuard]},
  {path: 'documentcode', component: DocumentcodeComponent, canActivate: [AuthGuard]},
  {path: 'chatcode', component: ChatcodeComponent, canActivate: [AuthGuard]},
  {path: 'tcgenerate', component:TcgenerateComponent,canActivate: [AuthGuard]}
]},

{path: 'fakechat', component: ChatComponent, canActivate: [AuthGuard]},

 // { path: 'Devflow', redirectTo: '/devflow', pathMatch: 'full' },
  { path: 'codeGenYLite', component: HomeComponent1, canActivate: [AuthGuard]},
  {
    path: 'codeGenYLite',
    component: HomeMainComponent,
    children: [
      // { path: '', redirectTo: 'products', pathMatch: 'full' }, // Set product page as the default page
      { path: 'products', component: ProductsComponent, canActivate: [AuthGuard],},
      { path: 'features', component: FeaturesComponent, canActivate: [AuthGuard],},
      { path: 'company', component: CompanyComponent, canActivate: [AuthGuard],},
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
