import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[appValidation]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidationDirective,
      multi: true
    }
  ]
})
export class ValidationDirective implements Validator {

  @Input('appValidation') validationConfig: any;

  validate(control: AbstractControl): ValidationErrors | null {
    if (!this.validationConfig) return null;

    const value = control.value;
    const errors: ValidationErrors = {};

    if (this.validationConfig.required && (value === null || value === '')) {
      errors['required'] = true;
    }

    if (this.validationConfig.min !== undefined && value < this.validationConfig.min) {
      errors['min'] = { min: this.validationConfig.min };
    }

    if (this.validationConfig.max !== undefined && value > this.validationConfig.max) {
      errors['max'] = { max: this.validationConfig.max };
    }

    return Object.keys(errors).length ? errors : null;
  }

}
