import { Component } from '@angular/core';

@Component({
  selector: 'app-sop-tool',
  templateUrl: './sop-tool.component.html',
  styleUrls: ['./sop-tool.component.scss']
  
})
export class SopToolComponent {

  isMenuOpen: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen; // Toggle the menu state
    
  }

}
