<div class="code-enhance">
  <div class="custom-container">
    <p class="code-enhance-header">Code documentation increases team efficiency by automating code documentation, facilitating quicker
      onboarding and collaboration. Reduce costs related to time-consuming manual documentation efforts.</p>
    <p class="code-enhance-desc"></p>
    <div class="file-btn-folder">
      <div class="file btn-upload">
        <img src="../../../../assets/images/uploadIcon.png" alt=""> Upload file
        <input type="file" name="file" (change)="onFileSelected($event)" />
      </div>

        <!-- Add error messages for file validation -->
        <small *ngIf="isFileEmpty" class="text-danger">Please select a file.</small>
        <small *ngIf="isFileSizeExceeded" class="text-danger">File size should not exceed 1MB.</small>
        <small *ngIf="isInvalidFileType" class="text-danger">Invalid file type. Allowed types: .py, .txt, .vb, .vba,
          .doc,
          .docx</small>
    </div>

    <!-- code box to enhance code -->
    <div class="text-box">
      <!--<app-text-area [value]="codeDocs.code" (enteredValue)="bindValue($event)"></app-text-area>-->
      <ngx-codemirror [(ngModel)]="codeDocs.code" [options]="codeMirrorOptionsreadonly"
        (ngModelChange)="setEditorContent($event)"></ngx-codemirror>
      <p *ngIf="isCodeInputError" class="text-danger">Code snippet cannot be empty</p>
      <p *ngIf="isCodeLengthInvalid" class="text-danger">Code length must be between 25 and 40,000 characters</p>
      <app-button [buttonClass]="'btn-enhance'" [buttonText]="'Create document'"
        (buttonClick)="onButtonClick()"></app-button>
    </div>

    <div *ngIf="isCodeEnhanced">
      <!-- enhanced code box  -->
      <div class="enhanced-code-box" #enhancedWindow>
        <div class="enhanced-header">
          <p>Code documentation</p>
          <div class="icons">

            <img class="img-1" src="../../../../assets/images/copy.png" alt="" (click)="copyText()"
              [matTooltip]="tooltipText" [matTooltipPosition]="'above'">
            <a  [href]="codeDocs.code_document_file" download>
            <img
            class="img-2"
            src="../../../../assets/images/download.png"
            alt=""></a>
            <!-- <i class="fa-regular fa-copy" (click)="copyText()"></i> -->
            <!--<i class="fa-solid fa-download" (click)="downloadFile()"></i>-->
            <!-- <a [href]="codeDocs.code_document_file" download><i class="fa-solid fa-download" ></i></a> -->
          </div>
        </div>
        <div class="enhanced-content" id="download">
          <!-- <pre class="output-field">{{codeDocs.code_document}}</pre> -->
          <div [innerHTML]="codeDocs.code_document" class="output-field"></div>
          <!--<ngx-editor-menu [editor]="editor" [toolbar]="toolbar">
  </ngx-editor-menu>-->
          <!--<ngx-editor [editor]="editor" [ngModel]="codeDocs.code_document" [disabled]="true">
                  </ngx-editor>-->
        </div>
      </div>
    </div>
  </div>
</div>
