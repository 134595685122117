import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { CompletionpopupComponent } from '../../../includes/popup/completionpopup/completionpopup.component';
import { BuildProjectService } from '../../../../Services/build-project.service';
import { SharedService } from '../../../../Services/shared.service';
import { ActivityAudit, FileModel } from '../../../../data/app-model';
import { ApiEndpoints, MessageTypeSnack, StatusCode } from '../../../../data/global-vars';
import { environment } from '../../../../../../environments/environment';
import { Subscription, finalize, interval, switchMap, takeUntil, takeWhile } from 'rxjs';
import { RoundLoaderService } from '../../../../Services/roundloader.service';
import { BaseHttpService } from '../../../../Services/base-http.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { TimeouterrorComponent } from '../../../includes/popup/timeouterror/timeouterror.component';

@Component({
  selector: 'app-sdd',
  templateUrl: './sdd.component.html',
  styleUrls: ['./sdd.component.scss']
})
export class SDDComponent implements OnInit, OnDestroy {
  @Output() switchTab = new EventEmitter<number>(); // Event emitter to notify the parent component

  showGoToGenerateCode:boolean = false;  //go to generate code btn hide here.........
  invalidSize: boolean = false;
  invalidType: boolean = false;
  selFiles: any;
  downloadLink: string = '';
  downloadLink2: string = '';
  docName: string = '';
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  docAvailable!: Subscription;
  isDocAvailable: boolean = false;
  isDocAvailableHasError: boolean = false;
  dialogRef!: MatDialogRef<CompletionpopupComponent, any>;
  isSddGenerated: boolean = false;
  constructor(public dialog: MatDialog, private buildPrjService: BuildProjectService, private sharedService: SharedService, private loaderService: RoundLoaderService, private baseHttp: BaseHttpService, private sopService : SopService) { }
  ngOnDestroy(): void {
    // this.docAvailable.unsubscribe();
    }

    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }
  onButtonSubmit() {
    this.isSddGenerated = true;
    this.storeLogs();
    if (this.selFiles && this.selFiles.length > 0) {
      this.buildPrjService.generateSdd(this.selFiles).subscribe({
        next: (res) => {
          if (res.status && res.status_code == StatusCode.Success) {
            //this.downloadLink = "../static/" + res.result.sdd_document_name;
            // this.downloadLink = environment.apiURL + "/static/" + res.result.sdd_document_name;
            if (res.result.sdd_document_name.toLowerCase().includes('devflow')) {
              this.downloadLink = environment.apiURL+ '/konv' + "/temp/" + res.result.sdd_document_name;
            }
            else {
              this.downloadLink = environment.apiURL +'/konv'+  "/static/" + res.result.sdd_document_name;
            }
            this.downloadLink = this.downloadLink.replace(/\\/g, "/");
            this.downloadLink2 = res.result.sdd_document_name;
            this.docName = res.result.sdd_document_name.split('/').pop()!;
            this.docName = this.docName.split('\\').pop()!;
            // console.log('sdd sub resp->', res);
            this.isDocAvailable = false;

            this.docAvailable = interval(environment.reqPoolingInterval)
              .pipe(
                takeWhile(() => !this.conditionMetO()),
                takeUntil(interval(environment.reqPoolingTimeout)),
                finalize(() => this.considerComplete())
            )
              .subscribe(() => this.checkDoc());

          } else {
            if (res.status_code == 500) {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
            } else if (res.status_code == 400) {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
            } else {
              this.loaderService.hideLoader();
              this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
            }
          }
          this.activityLogging();
        },
        error: (err) => {
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
          this.activityLogging();
        },
      });
    }
    else {
      this.sharedService.openSnackBar("Please upload a file", MessageTypeSnack.Error);
    }
  }

  checkDoc() {
    // console.log('check doc called');
    this.sharedService.checkDocumentStatus(this.downloadLink2).subscribe({
      next: (res) => {
        // console.log('res cond met->',res);
        if (res.status && res.status_code == StatusCode.Success) {
          this.isDocAvailable = res.result.is_document_available;
          if (this.isDocAvailable) {
            // console.log('res cond met true->');
            // this.docAvailable.unsubscribe();
            this.openSDDSuccessDialog();
            this.showGoToGenerateCode = true;
            this.loaderService.hideLoader();
          }
        } else {
          this.isDocAvailable = true;
          console.log('res cond met error->');
          // this.docAvailable.unsubscribe();
          this.isDocAvailableHasError = true;
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);
        }
      },
      error: (err) => {
        console.log('res cond met error->');
        // this.docAvailable.unsubscribe();
        this.isDocAvailable = true;
        this.isDocAvailableHasError = true;
        this.loaderService.hideLoader();
        this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
      },
    });
    //return response && response.someProperty === 'someValue';
  }

  conditionMet(response: any): boolean {
    // Check if the response meets your condition
    return response && response.result.is_document_available;
  }

  conditionMetO(): boolean {
    console.log('condition met called');
    return this.isDocAvailable;
  }

  considerComplete() {
    console.log('consider complete');
    this.loaderService.hideLoader();
    if (!this.isDocAvailable) {
      // this.sharedService.openSnackBar("Something went wrong. Please try again.", MessageTypeSnack.Error);
       this.dialog.open(TimeouterrorComponent, {
        width: '500px'
      })
    } 
  }

storeLogs(){
  const payload = {
    'event_type' : 'codeGenYLite',
    'event_data' : {
      'feature':'sdd',
    } 
  }
  this.sopService.logsStorage(payload).subscribe((res)=>{
    // console.log(res);
  })
}

  activityLogging() {
    this.activityAudit.module = ApiEndpoints.GetSDDCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit);
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {

        } else {
          if (res.status_code == 500) {

          } else if (res.status_code == 400) {

          } else {

          }
        }
      },
      error: (err) => {
      },
    });
  }

  selectedFile: File | null = null;

  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  //   const files: File[] = event.target.files;
  //   this.selFiles = [];
  //   let name = 'file';
  //   if (files) {
  //     let selLength = this.selFiles.length;
  //     for (let i = 0; i < files.length; i++) {
  //       let newName = name + selLength++;
  //       let fileData: FileModel = { name: newName, file_data: files[i] };
  //       this.selFiles.push(fileData);
  //     }
      
  //   }
  //   else {
  //     this.sharedService.openSnackBar("No file chosen.", MessageTypeSnack.Error);
  //   }
  // }



  // new onFileSelected event with validation fire here ...................... 

  onFileSelected(event: any) {
    const file = event.target.files[0];
  
    if (!file) {
      this.selectedFile = null; // Clear selectedFile if no file is chosen
      this.selFiles = [];
      this.sharedService.openSnackBar("No file chosen.", MessageTypeSnack.Error);
      return;
    }
  
    const fileSize = file.size / 1024 / 1024; // Convert bytes to MB
    const fileType = file.name.split('.').pop();
  
    if (fileSize > 1) {
      this.invalidSize = true;
      this.invalidType = false;
      return;
    }
  
    if (!['doc', 'docx'].includes(fileType)) {
      this.invalidSize = false;
      this.invalidType = true;
      return;
    }
  
    // If a valid file is selected, reset error flags and proceed
    this.invalidSize = false;
    this.invalidType = false;
    this.selectedFile = file;
    this.selFiles = []; // Clear any previous selected files
  
    let name = 'file';
    for (let i = 0; i < event.target.files.length; i++) {
      let newName = name + i;
      let fileData: FileModel = { name: newName, file_data: event.target.files[i] };
      this.selFiles.push(fileData);
    }
  }
  

  openSDDSuccessDialog() {
    const dialogRef = this.dialog.open(CompletionpopupComponent, {
      width: '440px', // Set the width of the modal
      data: {
        fileUrl: this.downloadLink,
        fileName: this.docName,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Switch to SDD tab after the dialog is closed
        this.goToGenerateCode();
      }
    });

    //dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //  if (confirmed) {

    //    //const link = document.createElement('a');
    //    //link.href = this.brdDownloadLink;
    //    //link.download = this.brdDocName;
    //    //document.body.appendChild(link);
    //    //link.click();
    //    //document.body.removeChild(link);
    //  }

    //}
    //});
  }

  removeSelectedFile() {
    this.selectedFile = null;
    this.selFiles = [];
    // this.invalidSize = false;
    // this.invalidType = false;
  
    // Reset the file input field
    const fileInput = document.getElementById('fileInput') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }


  refreshSelectedFile(){
    this.removeSelectedFile()
    
  }

  // function to navigate to generate code tab ---------- 
  goToGenerateCode() {
    this.switchTab.emit(2); // Emit the event to notify the parent component to switch to the SDD tab
  }
  

}
