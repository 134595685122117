<div class="chat-box-new ">
    <div class="row">
        <div class="col-12">
            <div class="row-x">
                <div class="conversation border" *ngIf="!methodSelected?.name">
                    <div class="col-12 bot-message">
                        Select listed function to start conversation.
                    </div>
                </div>

                <div class="conversation border" *ngIf="methodSelected?.name">

                    <!-- Chat block  start -->
                    
                        <ng-container *ngIf="!chatModeSwitch">
                            <ng-container *ngIf="meetingNotesData">
                                <div class="box messages smooth-scroll" id="chat-download-block" #scrollChat>
                                <div class="message">
                                    <div class="content">
                                        <!-- <button class="markdown-clipboard-button content-copy"
                                            (click)="copyToClipboard(meetingNotesData)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button> -->
                                        <div class="sticky-btn">
                                        <button class="download-btn" *ngIf="meetingNotesData" title="Export chat" (click)="downloadDocx()">
                                          <img src="../../../../assets/download.svg" /></button>
                                    </div>

                                    <div id="download">
                                        <!-- <textarea [value]="meetingNotesData" style="display: none;"></textarea> -->
                                        <div *ngIf="meetingNotesData" lineNumbers markdown [data]="meetingNotesData"
                                            [disableSanitizer]="true">
                                        </div>
                                      </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-block">
                                <div style="display: flex;justify-content: center; padding-left: 100px;">
                                <button class="btn btn-primary py-1 btn-sm" title="Back to function selection"
                                (click)="apiCall.next({back:true})" >
                                    Back to Home
                                    </button>
                                    
                                    <div style="padding-left: 100px;" (click)="openModal(like_modal)">
                                        <img src="../../../../assets/thumpsup.svg">
                                    </div>
                                    <div style="padding-left:15px" (click)="openModal(unlike_modal)">
                                        <img src="../../../../assets/thumpsdown.png">
                                    </div>
                                </div>    
                            </div>
                            <footer class="footer">
                              <div class="container">
                                <span style="font-size: 10px;">AI-generated response may not be fully accurate, you may copy the response and check with experts for critical information.</span>
                              </div>
                            </footer>
                            </ng-container>
                        </ng-container>
                    

                        <ng-container *ngIf="chatModeSwitch">
                            <div *ngIf="chatData.length === 0" style="text-align: center;" class="d-block">
                                <div class="content mt-3" >
                                    <div class="user-info text-dark" style="font-family: Georgia;"> Hello <b class="text-primary">{{username}}!</b> </div>
                                </div>
                            </div>

                            <div class="text-dark"  *ngIf="chatData.length === 0" style="text-align: center;">
                                <!-- <div *ngIf="methodSelected?.name !=='GKnowledge'" class="content">
                                    {{methodSelected?.description}}
                                </div> -->
                                <div *ngIf="methodSelected?.name =='GKnowledge'" class="content mt-1" style="color:grey" >
                                    Welcome to <b style="color:#073262">MeetingAssist!</b>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-12 text-dark mt-1" style="text-align: center; font-family: Arial;" >
                                    <p><sup style="color: red;">&#8727;</sup> Please select <b>Account Name</b> and <b>Buying Centre</b></p>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-md-3  card py-2" style="background-image: linear-gradient(to bottom, #F8F8F8, #F0F0F0)">
                                    <label style="text-align: center;"><img src="../../../../assets/title icon.svg" style="margin-right:10px;"/><b>Account Name</b></label>
                                    <br>
                                    <ng-multiselect-dropdown [placeholder]="'Select account name'" [settings]="dropdownSettings"
                                        [data]="accountList" [(ngModel)]="accountNameSelected" (onSelect)="onItemSelect($event)" style="background-color: white;"
                                        >
                                    </ng-multiselect-dropdown>
                                    <br>
                                </div>
                                <!-- <div class="col-md-2"></div><br> -->
                                <div class="col-md-3 mx-md-3 card py-2" style="background-image: linear-gradient(to bottom, #F8F8F8, #F0F0F0)">
                                    <label style="text-align: center;"><img src="../../../../assets/title icon.svg" style="margin-right:10px;"/><b>Buying Centre</b></label>
                                    <br>
                                    <ng-multiselect-dropdown [disabled]="buyingCenterList?.length == 0" [placeholder]="'Select buying centre'"
                                        [settings]="dropdownSettings" [data]="buyingCenterList" [(ngModel)]="centerSelected" style="background-color: white;">
                                    </ng-multiselect-dropdown>
                                    <br>
                                </div>
                                </div>
                                <div class="row justify-content-center">
                                <div class="col-md-6 offset-md-2 card py-3 mt-2" style="background-image: linear-gradient(to bottom, #F8F8F8, #F0F0F0);">
                                    <label style="text-align: center;"><img src="../../../../assets/title icon.svg" style="margin-right:10px;"/><b>Find Offerings & Case Studies by Client Challenges/Topics</b></label>
                                    <br>
                                <textarea autoResize [(ngModel)]="userInput" class="message-input" placeholder="Enter the client challenge or the topic (e.g., modernizing legacy infrastructure, cost-takeout, rewiring the supply chain, etc.)"
                                 #textArea></textarea>
                                 
                                </div>
                                <div class="col-md-2 mx-md-2 py-3" style="display: flex;align-items: flex-end; justify-content: flex-start; padding-left:10px ">
                                    <button class="btn btn-primary py-1 btn-sm " style="color:#073262; border-radius:none" (click)="getMeetingNotes()">
                                       <span>  Generate </span><img src="../../../../assets/enter icon.svg" /></button>
                                </div>
                            
                          </div>
                            <br>
                            
                          <footer class="footer">
                            <div class="container">
                              <span style="font-size: 11px;">AI-generated response may not be fully accurate, you may copy the response and check with experts for critical information.</span>
                            </div>
                          </footer>
                            
                        </ng-container>
                    
                    <!-- Chat block  End -->
                </div>

            </div>
        </div>
    </div>
</div>


<button type="button" class="btn btn-primary d-none" id="instructions_modal"(click)="openModal(instructions_modal)" >
</button>

<!-- instructions block -->
<ng-template #instructions_modal>
  <div class="modal-body" style="color:#073262; border-radius: 10px!important;">
    <div class="row">
      <div class="col-12 fw-bold">Important Instructions
      </div>
      <div class="row mt-2">
        <div class="col-12" style="font-size: 12px; font-family: Arial;">
        <ol style="list-style:disc;">
        <li>
            The AI-generated response will be limited to ensure concise and focused answers.
        </li>
        <li style="margin-top:5px">
          The tool may display inaccurate info. Therefore, double-check its responses for accuracy by referring to the source documents AI has referred to.
        </li>
        <li style="margin-top:5px">
        If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document and ChatGPT and Use of AI Programs Policy.
        </li>
        <li style="margin-top:5px; margin-bottom: -15px;">
        Kindly note that your inputs maybe subject to audit, and failure to comply with the guidelines may result in disciplinary action.
         </li>
        </ol></div>
      </div>


    <div class="">
      <div class="">
        <input id="instructions_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" style="background-color:#073262 !important"/>
        <label class="form-check-label fw-bold text-primary"
        style="
        margin: 0;
        font-weight: bold;
        font-size: 11px !important;
        color: #073262 !important;
        padding-left: 20px;"
          for="instructions_check_label">
          I acknowledge that I have read, understand and agree to above instructions
        </label>
      </div>
    </div>
  </div>
  <div class="col-12 mt-1" style="display: flex; justify-content:space-around;">
    <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
  </div>
</ng-template>


<ng-template #like_modal>
    <div class="modal-body">
        <div class="row">
    <div class="col-12 fw-bold text-primary text-center my-2">Provide Additional Feedback
        <button type="button" class="close" data-dismiss="modal" (click)="modalRef?.hide()"><img src="../../../../assets/crossicon.png"></button>
    </div>
            <div class="col-12 my-2" style="font-size: 12px;">
              <textarea class="form-control" type="textarea" placeholder="Please provide your feedback here..." style="height: 100px"></textarea>
            </div>
<div class="col-12" style="display: flex; justify-content:space-around;">
  <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
    (click)="modalRef?.hide()">
    Submit
  </button>
</div>
</div>
</div>
  </ng-template>

  <ng-template #unlike_modal>
    <div class="modal-body">
        <div class="row">
    <div class="col-12 fw-bold text-primary text-center my-2">Provide Additional Feedback
        <button type="button" class="close" data-dismiss="modal" (click)="modalRef?.hide()"><img src="../../../../assets/crossicon.png"></button>
    </div>
            <div class="col-12 my-2" style="font-size: 12px;">
              <textarea class="form-control" type="textarea" placeholder="Please provide your feedback here..." style="height: 60px"></textarea>
            </div>

            <div class="checkbox-container col-12" style="justify-content: space-between;">
                <label>
                  <input type="checkbox" name="checkbox1"> This is harmful
                </label>
                <br>
                <label>
                  <input type="checkbox" name="checkbox2"> This is incorrect
                </label>
                <br>
                <label>
                  <input type="checkbox" name="checkbox3"> This is not helpful
                </label>
              </div>
<div class="col-12" style="display: flex; justify-content:space-around;">
  <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
    (click)="modalRef?.hide()">
    Submit
  </button>
</div>
</div>
</div>
</ng-template>