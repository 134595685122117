import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { VoiceRecognitionService } from '../service/voice-recognition.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { InterAssistService } from '../service/inter-assist.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-qand-a',
  templateUrl: './qand-a.component.html',
  styleUrls: ['./qand-a.component.scss']
})
export class QandAComponent implements OnInit {
  qandas: any[] = [];
  liveReactionsVisible: boolean = false;
  startspeech: boolean = true;
  stopspeech: boolean = false;
  searchquery!: string;
  liveMessages:  any[] = [];
   uniqId:any;
   reqid:any;
  cvsumary:any;
  jdsumary:any;
  modalRef?: BsModalRef;
  responsibilities: string = '';
  preferredQualifications: string = '';

  extras: string = '';
  cvload: boolean = false;
  jdload: boolean = false;
  skillload: boolean = false;
  respectiveUserText?: string;


  name!: string;
  contactInfo!: string;
  objective!: string;
  education!: string;
  technicalSkills!: string;
  softSkills!: string;
  workExperience!: string;
  awardsAndInterests!: string;

  jobTitle!: string;
  jobPurpose!: string;
  qualifications!: string;
  skills = [];
  skill1: boolean = true;
  level: string[]=["Basic","Intermediate","Advanced"];
  qtype =[
    {id:'tech',name:'Technical'},
    {id:'behav',name:'Behavioural'},
    {id:'situ',name:'Situational'}
  ];
summary = [
  {id:1,name:'CV Summary'},
  {id:2,name:'JD Summary'}

];
  selectedskills: string[] = [];
  selectedlevel: any[] = [];
  selectedqtype: any[] = [];
  selectedsummary: any[] = [];


  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };

  dropdownSettings1: IDropdownSettings = {
    singleSelection: false,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };
  users: any;

  userid: any[] = [];

 // chatMessages: { question: string; response: string; isCode: boolean }[] = [];
  newQuestion = '';
  isLoadingResponse = false;


  chatVisible: boolean = false;
  userMessage: string = '';
  chatMessages: any[] = [];
 copied:boolean = false;


















  // uniqId: any[] = [];
  isLoading: boolean = false;
  cmessage!: string;
  qloader: boolean = false;
  constructor(private http: HttpClient,
              private speechservice: VoiceRecognitionService,
              private route: ActivatedRoute,
              private toastservice : ToastMessageService,
              private loaderservice : LoaderService,
              private panelservice: InterAssistService,
              private modalService: BsModalService,
              private router: Router
  ) {
    this.speechservice.init();
    this.route.params.subscribe((res: any) => {
      if (res?.id) {
        this.uniqId = res.id;
        this.reqid = res.rid;

      }
    })
  }

  startService(){
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;


  }

  stopService() {
    this.speechservice.stop();
    this.startspeech = true;
    this.stopspeech = false;

    // Assume your speech service returns the final consolidated text
    // and you send it to an API which returns a response to be displayed.
    this.sendToApi(this.liveMessages.filter(m => m.isUser).map(m => m.text).join(' '));
}

sendToApi(text: string) {
  this.http.post<any>('your-backend-api-url', { text: text }).subscribe(
      response => {
          // On successful API response, push response to liveMessages to display as a chat response
          this.liveMessages.push({ text: response.answer, user: "bot" });
      },
      error => {
          console.error('Error sending data to the API', error);
          // Define your sample error handling messages
          const errorMessages = [
              { text: 'Python is an interpreted, dynamically-typed, and object-oriented language.', user: "bot" },
              // { text: 'A shallow copy copies the reference pointers, whereas a deep copy copies the objects themselves.', isUser: false },
              // { text: 'A list is mutable, while a tuple is immutable.', isUser: false },
              // { text: 'A set is an unordered collection of unique elements, while a dictionary is a collection of key-value pairs.', isUser: false },
              // { text: 'A list is an ordered collection of elements, while a set is an unordered collection of unique elements.', isUser: false }
          ];
          // Push these sample messages to liveMessages to simulate a conversation
          errorMessages.forEach(msg => this.liveMessages.push(msg));
      }
  );
}




ngOnInit(): void {

  this.getlist();
  this.cvsummary();
  this.jdsummary();
  this.selectedsummary = [this.summary[0]];
    this.toastservice.showSuccess(this.uniqId);
  //this.cvsummary();
  // this.jdsummary();

  // if(this.cvsumary && this.jdsumary){
  // this.getQAData().subscribe(data => {
  //     this.processData(data);
  // });
  // }
  this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
      if (this.liveMessages.length > 0) {
          // Update the last message if it already exists
          this.liveMessages[this.liveMessages.length - 1] = { text: updatedText, user: "user" };
      } else {
          // If there are no messages yet, add the first one
          this.liveMessages.push({ text: updatedText, user: "user" });
      }
  });
}



  getQAData(): Observable<any> {
    this.isLoading = true;
      const formData = new FormData();
        formData.append('id', this.uniqId); // Append the unique id to the form data
        formData.append('type', this.selectedqtype[0].id); // Append the question type to the form data
        formData.append('skills', this.selectedskills.join(',')); // Convert the array to a string and append it to the form data
        formData.append('level', this.selectedlevel.join(',')); // Append the skill level to the form data

     const payload = {
      'cv_summary': this.cvsumary,
      'jd_summary': this.jdsumary

     }
    return this.panelservice.getquestions(formData).pipe(
      catchError(this.handleError)
    );
  }

  processData(data: any): void {
    // Assuming `data` is the object containing the array `data`
    if (data && data.data.data) {
      this.qandas = data.data.data.map((item: any) => {
        const questionKey = Object.keys(item).find(key => key.startsWith('question'));
        const answerKey = Object.keys(item).find(key => key.startsWith('answer'));
        return {
          question: questionKey ? item[questionKey] : '',
          answer: answerKey ? item[answerKey] : ''
        };
      });
    }
   this.isLoading = false;
  }

  handleError = (error: any): Observable<any> => {
    // Return dummy data in case of an error
    const dummyData = {status: 'success', data: [{'What are the key features of Python?': 'Python is an interpreted, dynamically-typed, and object-oriented language with a robust standard library and strong support for integration with other languages and tools.'}, {'Explain the difference between deep and shallow copy?': 'A shallow copy copies the reference pointers to the objects, whereas a deep copy creates copies of the objects themselves, ensuring that the original and the copy are completely independent.'}, {'What is the difference between a list and a tuple in Python?': 'A list is mutable, meaning you can change its elements, while a tuple is immutable, meaning you cannot change its elements.'}, {'What is the difference between a set and a dictionary in Python?': 'A set is an unordered collection of unique elements, while a dictionary is a collection of key-value pairs.'}, {'What is the difference between a list and a set in Python?': 'A list is an ordered collection of elements, while a set is an unordered collection of unique elements.'}]};
    this.processData(dummyData);
    return of(dummyData);
  }

  toggleAnswer(qanda: any): void {
    qanda.showAnswer = !qanda.showAnswer;
  }

  toggleLiveReactions(): void {
    this.liveReactionsVisible = !this.liveReactionsVisible;
  }

  updateUserAnswer(qanda: any, userAnswer: string): void {
    this.http.post<any>('your-backend-url', { question: qanda.question, answer: userAnswer }).subscribe(
      response => {
        //qanda.userAnswer = userAnswer;
      },
      error => {
        console.error('Error updating user answer', error);
      }
    );
  }
  cvsummary(): void {
   this.cvload = true ; // Show a loading indicator
    this.toastservice.showmessage('Generating CV Summary');
    const formData = new FormData();
    formData.append('id', this.uniqId); // Assuming 'uniqId' is already defined in your component

    this.panelservice.cvsummary(formData).subscribe(
        (response: any) => {
            console.log('Full Response:', response); // Log the full response for debugging
            if (response && response.status === 'success') {
                this.cvsumary = response.data['CV Summary :']; // Store the CV Summary text
                console.log('CV Summary Data:', response.data['CV Summary :']); // Log CV Summary part

                // Parsing the CV sections from the received data
                const cvSections = this.parseCVSections(response.data['CV Summary :']);
                console.log('Parsed Sections:', cvSections); // Log parsed sections to verify structure

                // Update the component state with parsed data
                this.name = cvSections['Name'];
                this.contactInfo = cvSections['Contact Information'];
                this.objective = cvSections['Objective'];
                this.education = cvSections['Education'];
                this.technicalSkills = cvSections['Technical Skills'];
                this.softSkills = cvSections['Soft Skills'];
                this.workExperience = cvSections['Work Experience'];
                this.awardsAndInterests = cvSections['Awards and Other Interests'];

                this.cvload = false; // Hide the loading indicator
                this.toastservice.showSuccess(response.message); // Show success message
            } else {


              this.cvload = false; // Hide the loading indicator
                this.toastservice.showError('Failed to fetch CV Summary'); // Show error message
            }
        },
        (error) => {
            this.cvload = false // Hide the loading indicator
            this.toastservice.showError('Error fetching CV Summary: ' + error.message); // Show error message
            console.error("Error fetching CV:", error);
        }
    );
}




private parseCVSections(cvText: string): { [key: string]: string } {
  let sectionMap: { [key: string]: string } = {};
  cvText = '\n' + cvText;
  // Using a regex that captures the section title more reliably by accounting for potential issues
  const sections = cvText.split(/\n\[([^[\]]+)\] :/);  // More robust regex
  console.log('Sections Split:', sections); // Logging the split results for debugging

  for (let i = 1; i < sections.length; i += 2) {
      if (i + 1 < sections.length) { // Ensure that there's a matching content section after the title
          const title = sections[i].trim();
          const content = sections[i + 1].trim();
          sectionMap[title] = content;
          console.log('Processed Section:', title, content); // Log each section processed
      }
  }
  return sectionMap;
}






jdsummary(): void {

  this.jdload = true; // Show a loading indicator
  this.toastservice.showmessage('Generating JD Summary');
  const formData = new FormData();
  formData.append('id', this.uniqId);
  this.panelservice.jdsummary(formData).subscribe(
      (response: any) => {
          if (response.status == 'success') {
              this.jdsumary = response.data['JD Summary :'];

              // Parsing and storing sections
              const sections = this.parseJDSections(this.jdsumary);
              console.log('Parsed Sections:', sections); // Debug output
              this.jobTitle = sections['Job Title'];
              this.jobPurpose = sections['Job Purpose/Objective'];
              this.responsibilities = sections['Key Responsibilities'];
              this.qualifications = sections['Qualifications'];
              this.technicalSkills = sections['Technical Skills'];


              this.jdload = false; // Hide the loading indicator
              this.toastservice.showSuccess(response.message);
          }
      },
      (error) => {

          this.jdload = false; // Hide the loading indicator
          this.toastservice.showError(error.message);
      }
  );
}


private parseJDSections(jdText: string): { [key: string]: string } {
    jdText = '\n' + jdText;  // Ensure consistent parsing by prepending a newline

    const sectionMap: { [key: string]: string } = {};  // Change sectionMap to an object
    const sections = jdText.split(/\n\[([^[\]]+)\]:/);  // More robust regex
    console.log('Sections Split:', sections); // Logging the split results for debugging

    for (let i = 1; i < sections.length; i += 2) {
      if (i + 1 < sections.length) { // Ensure that there's a matching content section after the title
        const title = sections[i].trim();
        const content = sections[i + 1].trim();
        sectionMap[title] = content;
        console.log('Processed Section:', title, content); // Log each section processed
      }
    }
  return sectionMap;
}








  openModal(template: TemplateRef<void>,size='modal-md') {
    this.modalRef = this.modalService.show(template, {class:size, ignoreBackdropClick: true });
  }


  genqa(): void {
    this.getQAData().subscribe(data => {
      this.processData(data);
  });
  }

  changestatus(){
//   this.isLoading = true;
//     const formData = new FormData();
//     formData.append('id', this.uniqId); // Append the unique id to the form data
//     formData.append('status', 'report analysis'); // Append the status to the form data
//  this.panelservice.changestatus(formData).subscribe(
//       (response:any) => {

//         if(response.status ==  'success'){
//      this.isLoading = false;
//       this.toastservice.showSuccess(response.message);
      this.router.navigate(['interview-assist/'+this.reqid+'/'+this.uniqId+'/Report-Gen']);
//         }
//         else{
//          this.isLoading = false;
//         }
//     },
//     (error) => {
//      this.isLoading = false;
//       this.toastservice.showError(error.message);
//     }
//  );


  }

getskills(){

  this.skillload = true;
   const formData = new FormData();
  formData.append('id', this.uniqId); // Append the unique id to the form data
  this.panelservice.getskills(formData).subscribe(
    (response:any) => {
      if(response.status ==  'success'){
        this.skills = response?.data;
        this.skillload = false;
        this.skill1 = false;
        this.toastservice.showSuccess(response.message);
      }
  },
  (error) => {
    this.skillload = false;
    this.toastservice.showError(error.message);
  }
);
}


getlist(){

  this.panelservice.userList().subscribe(data => {
    this.userid = data
      .filter((user: { status: string; }) => user.status === 'QnA')
      .map((user: { id: any; requisition_id: any; }) => ({
        id: user.id,
        name: `${user.requisition_id}_${user.id}`
      }));


  });
}



//chat
askQuestion() {
  if (this.newQuestion.trim()) {
    const question = this.newQuestion.trim();
    this.chatMessages.push({ question, response: '', isCode: false });
    this.newQuestion = '';
    this.isLoadingResponse = true;

    // API call to get response
    this.http.post<any>('YOUR_API_ENDPOINT', { question }).subscribe(
      (data) => {
        const response = data.response; // Adjust according to your API response structure
        const isCode = response.startsWith('```'); // Detect if the response is code
        const index = this.chatMessages.length - 1;
        this.chatMessages[index].response = response;
        this.chatMessages[index].isCode = isCode;
        this.isLoadingResponse = false;
      },
      (error) => {
        console.error('Error fetching response', error);
        this.isLoadingResponse = false;
      }
    );
  }
}

regenerateResponse(index: number) {
  this.isLoadingResponse = true;
  const question = this.chatMessages[index].question;

  // API call to regenerate response
  this.http.post<any>('YOUR_API_ENDPOINT', { question }).subscribe(
    (data) => {
      const newResponse = data.response; // Adjust according to your API response structure
      const isCode = newResponse.startsWith('```'); // Detect if the response is code
      this.chatMessages[index].response = newResponse;
      this.chatMessages[index].isCode = isCode;
      this.isLoadingResponse = false;
    },
    (error) => {
      console.error('Error fetching response', error);
      this.isLoadingResponse = false;
    }
  );
}

copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(() => {
    alert('Copied to clipboard');
  });
}


copyMessage(message: string) {
  navigator.clipboard.writeText(message).then(() => {
   this.toastservice.showSuccess('Copied to clipboard');
  });
}


regenerateAnswer(index: any) {

   // this.toastservice.showmessage(this.cmessage);
    const userMessage = index.trim();
    if (!userMessage) return;

    this.chatMessages.push({ user: 'user', text: userMessage });
    this.userMessage = '';

    // Prepare the data to be sent in the API request
    const requestData = {
        "id": this.uniqId,
        "query": userMessage,
    };

    // Make an API call to your bot here
    this.panelservice.chatbot(requestData)
      .pipe(
        catchError((error) => {
          console.error(error); // Log the error to the console
          return of({ message: "I can't understand. Please try again." }); // Provide a default error response
        })
      )
      .subscribe((response: any) => {
        const botMessage = response.data; // Assuming your bot API returns a 'message' field
        this.chatMessages.push({ user: 'bot', text: botMessage });
      });

}

getQuestion(index: number): string | null {
  if (index >= 0 && index < this.chatMessages.length) {
    const message = this.chatMessages[index];
    if (message.user === 'user') {
      // The question is from a user, so it's the previous message
      const previousMessage = this.chatMessages[index - 1];
      return previousMessage?.text ?? null;
    }
  }
  return null;
}

sendMessage(): void {
  // Handle user messages and bot responses
  this.isLoading = true;
  this.cmessage = this.userMessage;
  const userMessage = this.userMessage.trim();


  if (!userMessage) return;

  this.chatMessages.push({ user: 'user', text: userMessage });
  this.userMessage = '';

  // Prepare the data to be sent in the API request
  const requestData = {
      "id": this.uniqId,
      "query": userMessage,
  };

  // Make an API call to your bot here
  this.panelservice.chatbot(requestData)
    .pipe(
      catchError((error) => {
        console.error(error); // Log the error to the console
        return of({ message: "I can't understand. Please try again." }); // Provide a default error response
      })
    )
    .subscribe((response: any) => {
      const botMessage = response.data; // Assuming your bot API returns a 'message' field
      this.chatMessages.push({ user: 'bot', text: botMessage });
      this.isLoading=false;
    });
}











}
