<app-top-bar></app-top-bar>
<div class="fine-tune-model-container">
  <div class="chat-result-display-section">
    <div #messageContainer class="_react_scroll_to_bottom">
      <div *ngFor="let message of messages">
        <div class="message" [ngClass]="{ 'from': message.author === 'bot', 'to': message.author === 'user' }">
          <div [ngSwitch]="message.type">
            <div *ngSwitchCase="0">
              <p>{{message.content}}</p>
            </div>
            <div *ngSwitchCase="1">
              <strong>Customer Name: </strong>{{message.content.customer_name}}
              <br />
              <strong>Project Name: </strong>{{ message.content.project_name}}
              <br />
              <strong>Features: </strong>
              <ul>
                <li *ngFor="let item of message.content.features.toString().split(',')"> {{item}} </li>
              </ul>
            </div>
            <div *ngSwitchCase="2">
              <table class="table">
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">Section Heading</th>
                    <th scope="col">Section Content</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of message.content">
                    <td> {{item.section_heading}} </td>
                    <td> {{item.section_content}} </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div *ngSwitchCase="3">
              <iframe *ngIf="message.file" [src]="message.file.url?(message.file.url | safe):''" width="850" height="600"></iframe>
              <button title="Download File as Word Document" class="download-btn" (click)="generateAndDownloadDocx(message.file)">
                <fa-icon [icon]="faDownload"></fa-icon>
              </button>
            </div>
            <div *ngSwitchDefault></div>
          </div>
          <div class="timestamp">{{message.timestamp | date: 'hh:mm a'}}</div>
        </div>
      </div>
      <button *ngIf="isLoading" class="Loading-btn" type="button" disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Generating answer....
      </button>
    </div>
  </div>
  <div class="seacrh-bar-container">
    <div class="search-bar">
      <input [disabled]="isLoading" [(ngModel)]='textField' class="search-bar-input" type="text" (keyup.enter)="sendMessage(textField)"
        placeholder="Send a message ...">
    </div>
    <button [disabled]="isLoading" class="search-btn"
      (click)="sendMessage(textField)">
      <fa-icon [icon]="faPaperPlane"></fa-icon> Send
    </button>
    <!-- <button title="Download File" class="download-btn" (click)="generateAndDownloadLastPDF()">
      <fa-icon [icon]="faDownload"></fa-icon>
    </button> -->
  </div>
</div>
