import { Component, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
// import { FormControl, FormGroup } from '@angular/forms';
import { faRobot } from '@fortawesome/free-solid-svg-icons/faRobot';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons/faPaperPlane';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faSpinner } from '@fortawesome/free-solid-svg-icons/faSpinner';
//import { faDocker } from '@fortawesome/free-brands-svg-icons/faDocker';
import { faCheck, faClose, faEdit, faPlay, faCode, faCoffee, faTasks, faDownload, faL } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import { Constants } from 'src/app/shared/Utils/Constants';
// import { faL } from '@fortawesome/free-solid-svg-icons';
// import '../../../assets/css/style.css';
// import { Document, Paragraph, Packer, TextRun, File } from 'docx';
// import { saveAs } from 'file-saver';
// import { HtmlParser } from '@angular/compiler';
// import { asBlob } from 'html-docx-js-typescript';
// import { nextLine } from 'pdf-lib';
import Swal from 'sweetalert2';

import { DomSanitizer } from '@angular/platform-browser';
// import { text } from '@fortawesome/fontawesome-svg-core';
import * as FileSaver from 'file-saver';
import { interval, Subscription } from 'rxjs';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import  {SopService} from 'src/app/shared/service/sop.service';

import { Document, Packer, Paragraph } from "docx";
import * as saveAs from 'file-saver';

import { FormControl, FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
// import * as FileSaver from "file-saver";
@Component({
  selector: 'app-docgenerate',
  templateUrl: './docgenerate.component.html',
  styleUrls: ['./docgenerate.component.scss']
})
export class DocgenerateComponent implements OnInit, OnDestroy {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  private apiPollingTimer!: Subscription;
  redditIcon = faRobot;
  odnoklassniki = faUser;
  spinner = faSpinner;
  faPaperPlane = faPaperPlane;
  faCoffee = faCoffee;
  faCheck = faCheck;
  faClose = faClose;
  faEdit = faEdit;
  faPlay = faPlay;
  faCode = faCode;
  faTask = faTasks;
  // faDocker = faDocker;
  faDownload = faDownload;
  UserDiv: any;
  UserQuesDiv: any;
  questionsArray: any[] = [];
  quesTableData: any;
  DevReqText: any = '';
  // DevReqData: string = '';
  DevUserReqData: any;
  userReq: boolean = false;
  // quesTable: boolean = false;
  userQuesReq: boolean = false;
  // DevReq: boolean = false;
  QuesIsDisable: boolean = false;
  isLoading: boolean = false;
  DevReqDataEnable: boolean = false;
  workLocation: any;
  preReq: any;

  formData = {
    userPrompt: ''
  }
  userOHR: any;
  filepath1: any;
  processName: string= '';
  projDesc:  string= this.DevReqText;
  projScope:  string= '';
  codelang: string[] = []; // To hold the selected values
  codingLanguages: string[] = ['Angular', 'AngularJS', 'C#', 'CSS', 'Dax','Django', 'HTML', 'Java', 'JavaScript', 'MongoDB', 'MySQL', 'Node.js','Oracle', 'PHP', 'PostgreSQL', 'Python', 'React', 'Ruby', 'SQL', 'TypeScript', 'Vue.js'];
  automationObj:  string= '';
  presentProcess:  string= '';
  proposedProcess: string= '';
  brdDocName: any;
  downloadLink: any;
  fileName: any;
isspinner: boolean = false;
downloadbtn: boolean = false;
generatebtn: boolean = true;


constructor(public commonService: CommonService, public _sanitizer: DomSanitizer,
  private toastMessage: ToastMessageService,
  private loaderService: LoaderService, private toastService: ToastMessageService,
  private sopService: SopService) {
}

  ngOnInit(): void {
    this.getOHR();
   // this.startApiPolling();
   this.processName = localStorage.getItem('process_name') || '';
   // this.projDesc = localStorage.getItem('project_description') || '';
    this.projScope = localStorage.getItem('project_scope') || '';
    this.codelang = (localStorage.getItem('coding_language') || '').split(',');
    this.automationObj = localStorage.getItem('automation_objective') || '';
    this.presentProcess = localStorage.getItem('present_process') || '';
    this.proposedProcess = localStorage.getItem('proposed_process') || '';

  }

  ngOnDestroy(): void {
  }


  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  promptSearchHidden: boolean = false;
  promptError: boolean = false;
  SubmitClick() {
    this.formData.userPrompt = "Project name & Background:"+ '\n'+this.processName+ '\n'+" projectScope:"+'\n'+this.projScope+ '\n'+" codelanguage:"+'\n'+this.codelang+ '\n'+" projectObjective:"+'\n'+this.automationObj+ '\n'+" presentProcess:"+'\n'+this.presentProcess+ '\n'+" proposedProcess:"+'\n'+this.proposedProcess+ '\n';
    if (this.formData.userPrompt == '') {
      this.promptError = true;
    } else {
      localStorage.setItem('userPrompt', this.formData.userPrompt);
      this.promptError = false;
      this.promptSearchHidden = true;
      this.userReq = true;
      this.UserDiv = this.formData.userPrompt;
      this.formData.userPrompt = '';
      // this.quesTable = true;
      this.isLoading = true;
      this.getUserPromptResponse(this.UserDiv);
      setTimeout(() => { this.scrollToBottom() }, 500);
    }
  }

  getUserPromptResponse(userPrompt: any) {
    let url = Constants.config.UserPromptUrl;
    let body = {
      userPrompt: userPrompt
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);
      this.questionsArray = res.data;
      if (this.questionsArray.length > 0) {
        this.isLoading = false;
      }
      setTimeout(() => { this.scrollToBottom() }, 500);

    }).catch((err: any) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);
    })

  }

  QuesSubmit() {

    console.log(this.questionsArray);
    this.isLoading = true;
    this.QuesIsDisable = true;
    this.UserQuesDiv = this.questionsArray;
    this.userQuesReq = true;
    // this.DevReq = true;
    let url = Constants.config.DevReqUrl;
    let body = {
      userPrompt: this.UserDiv,
      detailedrequirements: this.questionsArray
    }

    this.commonService.postMethodAxios(url, body).then((res: any) => {
      console.log(res);

      // this.DevReqText.Prerequisites = (typeof res.data.development_requirements_detail["Prerequisites"] === "object") ? JSON.stringify(res.data.development_requirements_detail["Prerequisites"]) : res.data.development_requirements_detail["Prerequisites"];

      // this.DevReqText.detailedOperationSteps = (typeof res.data.development_requirements_detail["Detailed Operation Steps"] === "object") ? JSON.stringify(res.data.development_requirements_detail["Detailed Operation Steps"]) : res.data.development_requirements_detail["Detailed Operation Steps"];

      this.DevReqText = `Developement Requirement Overview:\n${res.data.development_requirements_overview}\n\nDevelopement Requirement Details-\nPrerequisites:\n${res.data.development_requirements_detail.Prerequisites}\n\nDetailed Operation Steps:\n${res.data.development_requirements_detail["Detailed Operation Steps"]}\n\nExpected Result:\n${res.data.development_requirements_detail["Expected Results"]}\n\nNotes:\n${res.data.development_requirements_detail["Other Explanatory Notes"]}`;

      // this.DevReqData = 'Prerequisites:<br/>' + res.data.development_requirements_detail.Prerequisites + '<br/>Detailed Operation Steps:<br/>' + res.data.development_requirements_detail["Detailed Operation Steps"];
      // this.DevReqData = res.data.development_requirements_detail.replace(/\n/g, '<br/>');
      if (this.DevReqText != '')
        this.isLoading = false;
        localStorage.setItem('userreq', this.DevReqText);
      setTimeout(() => { this.scrollToBottom() }, 500);
    }).catch((err: any) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);
    })
    setTimeout(() => { this.scrollToBottom() }, 500);
  }

  quesAnsEdit(data: any, index: any) {
    console.log(data, index);
    this.questionsArray.filter((val: any, i: any) => {
      if (i == index)
        val.answer_sample = data;
    })

    console.log(this.questionsArray);

  }

  // reqType: string = 'DirectSubmit';
  preReqData: any = '';
  DevReqTextEdit: boolean = false;
  DevReqEdit() {
    // this.subType = 'DevReqSubmit';
    // this.reqType = 'Edited';
    this.DevReqTextEdit = true;
    this.downloadbtn = false;
    this.generatebtn = true;

    // this.formData.userPrompt = this.DevReqText;
  }

  devReqbtn: boolean = false;
  DevReqSubmit() {
    this.devReqbtn = true;
    this.isLoading = true;
    this.DevReqTextEdit = false;
    this.DevReqDataEnable = true;

    this.DevReqText = document.getElementById('devReqTxt')?.innerText;
    this.DevUserReqData = this.DevReqText.replaceAll('\n', '<br/>');

    // if (this.reqType == 'DirectSubmit') {
    //   this.DevUserReqData = this.DevReqData;
    // }
    // else {
    //   this.DevUserReqData = this.DevReqText;
    // }
    // this.formData.userPrompt = '';
    // this.DevUserReqData.replaceAll('<br/>', '\n');


    let req = `{"development_requirements_overview":'${this.UserDiv}',"development_requirements_detail":${this.DevReqText}}`;

    let obj = {
      development_requirements_detail: req
    }
    let url = Constants.config.clarifyApiUrl;
    console.log(obj);

    this.commonService.postMethodAxios(url, obj).then((res: any) => {
      console.log(res);
      this.preReqData = JSON.stringify(res.data, undefined, 4);
      console.log(this.preReqData);

      // this.preReq = `
      // <h5><b>The interface documentation has been generated, and once confirmed, the analysis of how to modify the program code will begin: </b></h5>
      // <br/>
      // <pre>${this.preReqData}</pre>
      // `;
      this.isLoading = false;
      setTimeout(() => { this.scrollToBottom() }, 500);

    }).catch((err: any) => {
      console.log(err);
      setTimeout(() => { this.scrollToBottom() }, 500);

    })
    setTimeout(() => { this.scrollToBottom() }, 500);
  }

  userDocCnfrm: boolean = false;
  gptCode: any[] = [];
  preReqTxtEdit: boolean = false;
  preReqBtn: boolean = false;


  async DownloadUserRequirement() {

    console.log(this.DevReqText);
    this.DevReqText = document.getElementById('devReqTxt')?.innerText;

    const filename = "User_Requirement.docx"; // Change the filename extension to .docx
    this.saveAsDocx(this.DevReqText, filename);

}

async saveAsDocx(text: string, filename: string) {
    // Create a new document
    const doc = new Document({
        sections: [
            {
                properties: {},
                children: [
                    new Paragraph({
                        text: text
                    })
                ],
            },
        ],
    });

    // Convert the document to a blob
    const blob = await Packer.toBlob(doc);

    // Save the blob as a file
    saveAs(blob, filename);
}


generatebrd(){
  let payload = {
    "process_name": this.processName,
    "project_description": this.DevReqText,
    "project_scope": this.projScope,
    "coding_language": this.codelang.join(','), // Convert the array to a comma-separated string
    "automation_objective": this.automationObj,
    "present_process": this.presentProcess,
    "proposed_process": this.proposedProcess
  }
  this.sopService.generatebrd(payload).subscribe(

    (response: any) => {
      this.isspinner=true
      if(response.message === 'BRD generation is in progress and will be generated shortly'){
        this.brdDocName = 'BRD';
        this.generatebtn = false;
        this.downloadbtn = true;
        this.checkBRDStatus();
      }
    },
    (error) => {
      this.isspinner=false
      console.error('Error downloading file:', error);
      // Handle error as needed
    }
  );
}


// Define a function to check BRD status recursively
checkBRDStatus() {
  this.sopService.brdstatus(this.brdDocName).subscribe(
    (response: any | Blob ) => {
        if(response.message === 'BRD document is not ready') {
          this.isspinner=true;
                  // Document not available yet, continue checking after 15 seconds
                  setTimeout(() => {
                    this.toastService.showmessage('BRD is being generated, please wait for a moment.')
                    this.checkBRDStatus();
                  }, 15000); // 15 seconds in milliseconds
                }

        //if response type is Blob
        else if(response.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          this.isspinner=false;
          document.getElementById('btnbrd')?.click();
        }
        else  { // Document is ready
          this.isspinner=false;
          document.getElementById('btnbrd')?.click();
      }

    },
    (error) => {
      this.isspinner=false
     // this.toastService.showmessage('Error downloading file:');
      console.error('Error downloading file:', error);
      // Handle error as needed
    }
  );
}


download() {
  this.isspinner=true;
this.sopService.downloadbrd(this.brdDocName).subscribe(
  (response: Blob) => {
    this.loaderService.hide();
    const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    // Assuming process_name is a variable containing the process name
    const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });

    // Generate the filename string
    const filename = `BRD_${this.processName}_${timestamp}.docx`;

    // Now, set the filename for the download
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    this.toastMessage.showSuccess('File Downloaded Successfully');
    this.isspinner=false;
    // Cleanup
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);

  }
);
}

storeinput(){
  localStorage.setItem('process_name', this.processName);
  //localStorage.setItem('project_description', this.DevReqText);
  localStorage.setItem('project_scope', this.projScope);
  localStorage.setItem('coding_language', this.codelang.join(','));
  localStorage.setItem('automation_objective', this.automationObj);
  localStorage.setItem('present_process', this.presentProcess);
  localStorage.setItem('proposed_process', this.proposedProcess);
}


resetinput(){
  this.processName = '';
  this.projScope = '';
  this.codelang = [];
  this.automationObj = '';
  this.presentProcess = '';
  this.proposedProcess = '';
 localStorage.removeItem('process_name');
  //localStorage.removeItem('project_description');
  localStorage.removeItem('project_scope');
  localStorage.removeItem('coding_language');
  localStorage.removeItem('automation_objective');
  localStorage.removeItem('present_process');
  localStorage.removeItem('proposed_process');
}











  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  featureBranch: any;

}


