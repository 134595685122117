import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../shared/service/loader.service';
import { SopService } from '../shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import  {TcgService} from '../shared/service/tcg.service';

@Component({
  selector: 'app-testcase-generator',
  templateUrl: './testcase-generator.component.html',
  styleUrls: ['./testcase-generator.component.scss']
})

export class TestcaseGeneratorComponent implements  OnInit {
  @Input() placeholder1 = 'Develop an intuitive and user-friendly UI for an enterprise dashboard that allows users to customize and personalize the displayed data based on their roles and responsibilities.';
  [x: string]: any;

    downloadStatus = false;

    processStatus: boolean = false;


    targetSASUrl: any;
    fileUploadedStatus = false;
    fileData: any;
    userOHR: any;
   fupload:boolean=false;

    intervalId: any; // Variable to store the interval ID

    functionList: any = [];
    functionSelected: any;
    subscription!: Subscription;
    functionname: any;
    dupload: boolean=false;
    doclist: any;
    dfileselected: any;
    filestatus:boolean=false;
    testcase: any;
    filestatus1: boolean=true;
    testanswer: any;
    rasheet:boolean=true;
    saverasheet:boolean=false;
    //
    // selectedLink: any;
    // maxLink: any;
    // i: any;
    constructor(

      private loaderService: LoaderService,
      private toastMessage:ToastMessageService,
      private sopService: SopService,
      private tcgService:TcgService
    ) {

    }

    ngOnInit() {


    }

edit(){
  this.rasheet = false;
  this.saverasheet=true;
}
save(){
  this.toastMessage.showmessage('Changes saved successfully');
  this.saverasheet=false;
  this.rasheet=true;
}

    // Upload file for translation
    postUpload(event: any) {
      this.loaderService.loading.next(true);

      if (event?.name) {
        this.fileData = event;
        console.log(this.fileData?.file, this.fileData?.name)

      }
      this.getOHR();

      const formData = new FormData();
      formData.append('ra_sheet', this.fileData?.file);
      //formData.append('user_id', this.userOHR);
    //  formData.append('app-name', this.functionSelected);
      this.tcgService.fileupload(formData).subscribe(
        (response: any) => {

          this.loaderService.loading.next(false);
          console.log(response)
          if( response?.status == 'success'){
            this.generatetestcase();
            this.testcase=response.data;
            this.fileUploadedStatus = true;
            this.filestatus1=false;
            this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
           }else{
            this.toastMessage.showError(response?.message);
           }
        },
        (error:any) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );

    }
  //
  fileupload(){
    this.fupload=!this.fupload;
    this.dupload=false;
   }
  filelist(){
    this.fupload= false;
    this.dupload=true;

  }
  downloadList() {
    const httpOptions = {
      responseType: 'blob' as 'json', // Specify the response type as blob
    };
const payload = {
  summary: this.testanswer
}
    this.loaderService.loading.next(true);
    this.toastMessage.showmessage('File Downloading...');

    this.tcgService.fileDownload(payload).subscribe(
      (response: Blob) => {
        this.loaderService.loading.next(false);

        // Create a blob object from the response
        const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Create a object URL for the blob
        const url = window.URL.createObjectURL(blob);

        // Create a link element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'Testcase.xlsx'; // Set the desired file name
        document.body.appendChild(a);
        a.click();
this.toastMessage.showSuccess('File Downloaded Successfully');
        // Cleanup
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
        this.resetFile();


      },
      (error) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error.message);
      }
    );
  }

  generatetestcase(){
    this.loaderService.loading.next(true);
    this.toastMessage.showmessage('Generating summary for the Testcase(s)...');
    this.tcgService.testcase().subscribe(
      (response: any) => {
        this.loaderService.loading.next(false);
        console.log(response)
        if( response?.status == 'success'){
         this.testanswer = response.data;
          this.toastMessage.showSuccess(response?.message);
        }else{
          this.toastMessage.showError(response?.message);
        }
      },
      (error:any) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );
  }





    // reset upload file

    resetFile() {
      this.fileUploadedStatus = false;
      this.fileData = '';
      this.downloadStatus = false;
      this.targetSASUrl = '';
      this.filestatus1=true;
      localStorage.removeItem('f_tarnslate');
    }


    // get user OHR id
    getOHR() {
      let userData = localStorage.getItem('okta-token-storage');
      if (userData) {
        let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
        if (ohr_id) {
          this.userOHR = ohr_id;
        }
      }
    }

  //   // checking status of file per INterver till successed

  //   startInterval(): void {
  //     if(localStorage.getItem('f_tarnslate')){
  //    // this.getTranslateStatus();

  //     this.intervalId = setInterval(() => {
  //       // Your function to be called every 15 seconds
  //       console.log('interval call')
  //    //   this.getTranslateStatus();

  //     }, 15000); // 15 seconds in milliseconds

  //   }
  // }

  //   stopInterval() {
  //     this.processStatus = false;
  //     if (this.intervalId) {
  //       clearInterval(this.intervalId);
  //       this.intervalId = null;
  //     }
  //   }

  //   resetForm(event: any) {
  //     localStorage.removeItem('f_tarnslate');

  //     this.stopInterval();
  //     this.loaderService.loading.next(false);
  //   }

  //   // getUserRole() {
  //   //   this.subscription = this.sopService.activeUserRole.subscribe(
  //   //     (res: any) => {
  //   //       if (res?.data) {
  //   //         this.functionList = res['data'];
  //   //         this.functionSelected = res['data'][0]['value'];
  //   //         this.functionname = res['data'][0]['name'];
  //   //       }
  //   //     });
  //   // }
  //   getUserRole() {
  //     this.subscription = this.sopService.activeUserRole.subscribe(
  //       (res: any) => {
  //         console.log(res)
  //         if (res?.all_roles) {
  //           this.functionList = res['all_roles'];
  //           this.functionSelected = res['all_roles'][0]['value'];
  //         //  if(res['all_roles']?.length) this.functionSelected = res['all_roles'][0]['value'];
  //         //   this['chatPayload'] = null;
  //         //   this['chatData'] = [];
  //         }
  //       });
  //   }

  //   ngOnDestroy(): void {
  //      // Clear the interval when the component is destroyed
  //      this.stopInterval();
  //      this.subscription?.unsubscribe();
  //   }
  }

