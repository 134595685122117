import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) {
  }

  post(url: string, body: any) {
    return this.download(url, JSON.stringify(body))
      .subscribe((response) => {

        console.log(response);

        let file = new Blob([response.body], { type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"});
        const fileUrl = URL.createObjectURL(file);
        saveAs(file, `${body.customer_name}_${body.project_name.toString().replace(' ','_')}.docx`)
        // const child = window.open(fileUrl);
      });
  }

  download(url: string, formData:any): Observable<any> {
    const requestOptions : any = {
        observe: "response",
        responseType: "blob",
        headers: new HttpHeaders({
          "Accept": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "Content-Type":"application/json"
        })
    };
    const request = new Request(requestOptions);
    return this.http.post(url, formData, requestOptions);
  }
}
