<div class="popup">
  <div class="popup-head" (click)="onCloseClick()">
          <i class="fa-solid fa-xmark"></i>
  </div>
  <div class="popup-content">
      <div class="popup-icon">
         <img src="../../../../../../assets/contactus.png" alt="">
      </div>
      <div class="popup-text">
          <p class="heading">Contact the dev team</p>
          <p class="content">Please drop a mail to<br> <a href="mailto:aiplay-dev@genpactonline.onmicrosoft.com">'aiplay-dev@genpactonline.onmicrosoft.com'</a><br> to contact the development team.</p>
      </div>
  </div>
  <div class="popup-btn">
      <button class="btn btn-custom btn-first" (click)="onCloseClick()">Close</button>
  </div>
</div>
