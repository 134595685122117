import { Component } from '@angular/core';
import { SharepointService } from '../shared/service/sharepoint.service';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-sharepoint2vector',
  templateUrl: './sharepoint2vector.component.html',
  styleUrls: ['./sharepoint2vector.component.scss']
})
export class Sharepoint2vectorComponent {
  windowRef = window;
  sharepointURL: string = '';
  collectionName: string = '';
  sitesList: any = [];
  siteId: string = '';
  drivesList: any = [
    {name: 'all', id: 'all'}
  ];
  driveId: string = '';
  failedList: any = [];
  successList: any = [];
  uploadStatus: string = '';
  sToken: string = '';

  constructor(private sharepointService: SharepointService,
    private loaderService: LoaderService,
    private toastMessage: ToastMessageService) { }

  getSitefromURL(): void{
    const formData = new FormData();
    formData.append('path', this.sharepointURL);
    this.loaderService.loading.next(true);
    this.sharepointService.getSites(formData).subscribe((res: any) => {
      this.loaderService.loading.next(false);
      const mappedData = res?.sites.map((ele: any) => ({
        "name": ele?.name,
        "id"  : ele?.id
      }));
      this.sitesList = mappedData || [];
      this.sitesList.push({name: 'all', id: 'all'});
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showError("Please try again.");
    }
    );

  }

  getDrivesfromSite(): void{
    const formData = new FormData();
    if(this.siteId === 'all'){
      this.driveId = 'all';
    }
    formData.append('site_id', this.siteId);
    this.loaderService.loading.next(true);
    this.sharepointService.getDrives(formData).subscribe((res: any) => {
      this.loaderService.loading.next(false);
      const mappedData = res?.drives.map((ele: any) => ({
        "name": ele?.name,
        "id"  : ele?.id
      }));
      this.drivesList = mappedData || [];
      this.drivesList.push({name: 'all', id: 'all'});
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showError("Please try again.");
    }
    );
  }
  scrollToBottom() {
    setTimeout(() => {
      window.scrollTo(0, document.body.scrollHeight);
    }, 0);
  }
  getStatusfromDrives(): void{
    const formData = new FormData();
    formData.append('collection_name', this.collectionName);
    this.loaderService.loading.next(true);
    this.sharepointService.getStatus(formData).subscribe((res: any) => {
      this.loaderService.loading.next(false);
       console.log(res); 
      this.failedList = res?.failed || [];
      this.successList = res?.success || [];   
      this.uploadStatus = res?.upload_status || ''; 
      //   if(res?.upload_status == 'File upload in progress!!!') 
      //   {
      //   setTimeout(() => {
      //     this.getStatusfromDrives();
      //   }, 30000); 
      // }
  },
    (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showError("Please try again.");
    }
    );
    this.scrollToBottom();
  }

  getSharepointUpload(): void{
    const formData = new FormData();
    formData.append('path', this.sharepointURL);
    formData.append('collection_name', this.collectionName);
    formData.append('site_id', this.siteId);
    formData.append('drive_id', this.driveId);
    formData.append('s_token', this.sToken);
    this.loaderService.loading.next(true);
    this.sharepointService.sharepointUpload(formData).subscribe((res: any) => {
      this.loaderService.loading.next(false);
      console.log(res);
      this.toastMessage.showSuccess(res.message);
    },
    (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showError("Please try again.");
    }
    );
  }

}
