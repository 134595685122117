import { LoaderService } from 'src/app/shared/service/loader.service';
import { Subscription,finalize, interval, switchMap, takeUntil, takeWhile } from 'rxjs';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; // Import necessary form-related modules
import { BRDcompleteComponent } from '../../../includes/popup/brdcomplete/brdcomplete.component'; // Import the BRD completion dialog component
import { BRD } from '../../../../data/add-project-model';
import { BuildProjectService } from '../../../../Services/build-project.service';
import { ApiEndpoints, MessageTypeSnack, StatusCode } from '../../../../data/global-vars';
import { SharedService } from '../../../../Services/shared.service';
import { Router } from '@angular/router';
import { ActivityAudit } from '../../../../data/app-model';
import { environment } from '../../../../../../environments/environment';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { SopService } from 'src/app/shared/service/sop.service';
import { TimeouterrorComponent } from '../../../includes/popup/timeouterror/timeouterror.component';


@Component({
  selector: 'app-brd',
  templateUrl: './brd.component.html',
  styleUrls: ['./brd.component.scss'],
})
export class BRDComponent implements OnInit {
  @Output() switchTab = new EventEmitter<number>(); // Event emitter to notify the parent component
  brdForm: FormGroup;
  showGoToSDD: boolean = false;   //go to SDD code btn hide here.........
  activityAudit: ActivityAudit = {
    ohr_id: '',
    time_stamp: '',
    module: '',
  }
  docAvailable!: Subscription;
  isDocAvailable: boolean = false;
  isDocAvailableHasError: boolean = false;
  brdDownloadLink2: string = '';
  brdDownloadLink: string = '';
  brdDocName: string = '';
  isBrdGenerated: boolean = false;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private buildPrjService: BuildProjectService, private sharedService: SharedService, private router: Router, private loaderService: RoundLoaderService, private sopService: SopService) {
    // Initialize the form group in the constructor
    this.brdForm = this.formBuilder.group({
      process_name: ['', [Validators.required,Validators.minLength(5),  Validators.maxLength(1000)]],
      coding_language: ['VBA', [Validators.required]],
      project_description: ['', [Validators.required, Validators.minLength(25), Validators.maxLength(7500)]],
      automation_objective: ['', [Validators.required, Validators.minLength(25), Validators.maxLength(7500)]],
      project_scope: ['', [Validators.required, Validators.maxLength(5000)]],
      present_process: ['', [Validators.required,Validators.minLength(2), Validators.maxLength(12500)]],
      proposed_process: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(12500)]],
    });
  }



    ngOnInit(): void {
        //throw new Error('Method not implemented.');
    }

    ngOnDestroy(): void {
      // this.docAvailable.unsubscribe();
    }

  onButtonSubmit() {
    this.isBrdGenerated = true;
    this.storeLogs();
    if (this.brdForm.valid) {
      let formData: BRD = this.brdForm.value;
      this.buildPrjService.generateBrd(formData).subscribe({
        next: (res) => {
          if (res.status && res.status_code == StatusCode.Success) {
            //this.brdDownloadLink =   "../static/" + res.result.brd_document_name;
            this.brdDownloadLink = environment.apiURL+ '/konv' + +"/static/"+ res.result.brd_document_name;
            // this.brdDocName = res.result.brd_document_name;
            // this.openBRDSuccessDialog(res.result.prog_lang_suggestion);
            // this.showGoToSDD = true;
                        // this.brdDocName = res.result.brd_document_name;
            // console.log('brd sub resp->', res);

            this.brdDownloadLink2 = res.result.brd_document_name;
            this.isDocAvailable = false;
            this.docAvailable = interval(environment.reqPoolingInterval)
              .pipe(
                takeWhile(() => !this.conditionMetO()),
                takeUntil(interval(environment.reqPoolingTimeout)),
                finalize(() => this.considerComplete())
            )
              .subscribe(() => this.checkDoc());

            // this.openBRDSuccessDialog(res.result.prog_lang_suggestion);
            // this.showGoToSDD = true;


          } else {
            this.loaderService.hideLoader();
            this.sharedService.openSnackBar(
              res.message,
              MessageTypeSnack.Error
            );
          }
          this.activityLogging();
        },
      });

      
    }
    else {
      this.loaderService.hideLoader();
      this.sharedService.openSnackBar(
        "Enter all details",
        MessageTypeSnack.Error
      );
      this.brdForm.markAllAsTouched();
    }
  }

  checkDoc() {
    // console.log('check doc called->', this.brdDownloadLink2);
    this.sharedService.checkDocumentStatus(this.brdDownloadLink2).subscribe({
      next: (res) => {
        // console.log('res cond met->',res);
        if (res.status && res.status_code == StatusCode.Success) {
          this.isDocAvailable = res.result.is_document_available;
          if (this.isDocAvailable) {
            // console.log('res cond met true->');
            // this.docAvailable.unsubscribe();
            if (this.brdDownloadLink2.toLowerCase().includes('devflow')) {
              this.brdDownloadLink = environment.apiURL + '/konv' + "/temp/" + this.brdDownloadLink2;
            }
            else {
              this.brdDownloadLink = environment.apiURL + '/konv' + "/static/" + this.brdDownloadLink2;
            }
            // this.openBRDSuccessDialog(res.result.prog_lang_suggestion);
            // this.brdDownloadLink = environment.apiURL + "/static/" + this.brdDownloadLink2;
            //this.brdDownloadLink = environment.apiUrl + "/static/" + res.result.data_response.brd_document_name;
            if (res.result.data_response.brd_document_name) {
              this.brdDocName = res.result.data_response.brd_document_name.split('/').pop()!;
              this.brdDocName = this.brdDocName.split('\\').pop()!;
            }
            this.openBRDSuccessDialog(res.result.data_response.prog_lang_suggestion);
            this.showGoToSDD = true;
            this.loaderService.hideLoader();

          }
        } else {
          this.isDocAvailable = true;
          console.log('res cond met error->');
          // this.docAvailable.unsubscribe();
          this.isDocAvailableHasError = true;
          this.loaderService.hideLoader();
          this.sharedService.openSnackBar(res.message, MessageTypeSnack.Error);


        }
      },
      error: (err) => {
        console.log('res cond met error->');
        // this.docAvailable.unsubscribe();
        this.isDocAvailable = true;
        this.isDocAvailableHasError = true;
        this.loaderService.hideLoader();
        this.sharedService.openSnackBar(err, MessageTypeSnack.Error);
      },
    });
    //return response && response.someProperty === 'someValue';
  }

  conditionMet(response: any): boolean {
    // Check if the response meets your condition
    return response && response.result.is_document_available;
  }

  conditionMetO(): boolean {
    console.log('condition met called');
    return this.isDocAvailable;
  }

  considerComplete() {
    console.log('consider complete');
    this.loaderService.hideLoader();
    if (!this.isDocAvailable) {
      // this.sharedService.openSnackBar("Something went wrong. Please try again.", MessageTypeSnack.Error);
       this.dialog.open(TimeouterrorComponent, {
        width: '500px'
      })
      

    }

  }


  storeLogs(){
    const payload = {
      'event_type' : 'codeGenYLite',
      'event_data' : {
        'feature' : 'brd',
        'automation_objective' : this.brdForm.value.automation_objective,
      } 
    }
    this.sopService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }

  activityLogging() {
    this.activityAudit.module = ApiEndpoints.GenerateBrdCustom;
    this.activityAudit.time_stamp = String(new Date());
    let userName = localStorage.getItem('okta-username');
    this.activityAudit.ohr_id = userName ? String(userName) : '';
    //this.sharedService.postActivityLog(this.activityAudit);
    this.sharedService.postActivityLog(this.activityAudit).subscribe({
      next: (res) => {
        if (res.status && res.status_code == StatusCode.Success) {

        } else {
          if (res.status_code == 500) {

          } else if (res.status_code == 400) {

          } else {

          }
        }
      },
      error: (err) => {

      },
    });
  }

  openBRDSuccessDialog(msg:string) {
    const dialogRef = this.dialog.open(BRDcompleteComponent, {
      width: '400px', // Set the width of the modal
      data: {
        fileUrl: this.brdDownloadLink,
        fileName: this.brdDocName,
        msg: msg,
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        // Switch to SDD tab after the dialog is closed
        this.goToSDD();
      }
    });

    //dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    //  if (confirmed) {

    //    //const link = document.createElement('a');
    //    //link.href = this.brdDownloadLink;
    //    //link.download = this.brdDocName;
    //    //document.body.appendChild(link);
    //    //link.click();
    //    //document.body.removeChild(link);
    //  }

      //}
    //});
  }

  // Function to navigate to the SDD tab
  goToSDD() {
    this.switchTab.emit(1); // Emit the event to notify the parent component to switch to the SDD tab
  }

}
