<app-sop-sidenav></app-sop-sidenav>
<div class="wrapper">
  <!-- <app-sop-sidenav></app-sop-sidenav> -->
  <!-- Main content -->
  <section class="content">
      <div class="container-fluid responsive">
          <div class="container">

              <div class="row flex-nowrap">
                  <main id="main">
                      <div class="row mt-5 mb-4">
                          <div class="col-12" style="text-align: center; color:#073262">
                              <h4>
                                  <link rel="stylesheet"
                                      href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
                                      integrity="sha384-..." crossorigin="anonymous">

                                  Test Case Generator
                              </h4>
                          </div>
                      </div>

                      <div class="row py-4">
                          <div class="col-md-12">
                              <div class="selectlang" align="center">
                                  <ng-container >

                                     <!-- Select Function -->
                                      <!-- <select class="i" tooltip="Select the function type" id="converse_function_dropdown" name="functionname" title="Select Function" [(ngModel)]="functionSelected">
                                          <option *ngFor="let function of functionList" [value]="function?.value">
                                              {{function?.name}}
                                          </option>
                                      </select> -->
                                      <!-- [disabled]="!filestatus" -->
                                      <!-- <button class="bg-primary text-light p-2 px-4 border border-r1"
                                     title="File List"

                                       (click)="filelist()" >
                                      Generate test case
                                      </button>

                                   <button class="bg-primary text-light p-2 px-4 border border-r1"
                                      title="File Upload"
                                        (click)="fileupload()">
                                      Upload your file
                                      </button> -->

                                      <!-- <button class="bg-primary text-light p-2 px-4 border border-r1"
                                          (click)="translateContent()"> Translate </button> -->

                                  </ng-container>
                                  <!-- <button *ngIf="downloadStatus"
                                      class="bg-success text-light p-2 px-4 border border-r1"
                                      (click)="downloadFile()"> Download </button> -->

                              </div>

                          </div>
                      </div>
                      <!-- <div id="text-d" class="row" *ngIf="dupload">
                        <div class="container mt-4">
                          <div class="overflow-auto">
                            <div style="height: 400px;">
                              <div class="Summary" lineNumbers clipboard markdown  [disableSanitizer]="true">

                                <textarea title="tc"


                                > {{this.placeholder1}} </textarea><br>
                                </div>

</div>
</div>
</div>
                      </div> -->

                      <div class="row" *ngIf="filestatus1">

                        <app-upload-file  placeholder="Only .docx files are allowed (max Size 16 MB)"
                  inputAccept=".docx"
                  (fileuploaded)="postUpload($event)"
                  maxFileSize=16>
               </app-upload-file>





                      </div>

                      <div class="row px-5 " *ngIf="fileUploadedStatus || downloadStatus">

                          <div class="col-12 file-list">
                              File Name:
                              <h6 style="display: contents; text-decoration: underline;">
                                  {{fileData?.name}}
                                  <span (click)="resetFile()">
                                      <i class="fa fa-close" style="color:red;"></i>
                                  </span>
                              </h6>
                          </div>

                      </div>
                      <div id="text-d" class="row" *ngIf="fileUploadedStatus || downloadStatus">
                        <div class="container mt-4">
                          <div class="overflow-auto text-center">
                            <h5>Summary of RA document</h5>
                            <textarea [disabled]="rasheet" style="height:350px;overflow-y: scroll; resize: both; width:100%;" title="tc" [(ngModel)]="testanswer">
                            </textarea>
                            </div>
                        </div>
                        </div>


                      <div id="text-d" class="row" *ngIf="fileUploadedStatus || downloadStatus">
                        <div class="container mt-4">
                          <div class="overflow-auto text-center">
                      <button tooltip="Edit Summary" *ngIf="!saverasheet" class="bg-primary text-light p-2 px-4 border border-r1" (click)="edit()">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
                          <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
                        </svg> -->
                        Edit Summary
                      </button>
                      <button tooltip="Save Summary" *ngIf="saverasheet" class="bg-primary text-light p-2 px-4 border border-r1" (click)="save()">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy2-fill" viewBox="0 0 16 16">
                          <path d="M12 2h-2v3h2z"/>
                          <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v13A1.5 1.5 0 0 0 1.5 16h13a1.5 1.5 0 0 0 1.5-1.5V2.914a1.5 1.5 0 0 0-.44-1.06L14.147.439A1.5 1.5 0 0 0 13.086 0zM4 6a1 1 0 0 1-1-1V1h10v4a1 1 0 0 1-1 1zM3 9h10a1 1 0 0 1 1 1v5H2v-5a1 1 0 0 1 1-1"/>
                        </svg> -->
                        Save Summary
                      </button>
                      <button tooltip="Download Testcase" class="bg-primary text-light p-2 px-4 border border-r1"
                      (click)="downloadList()">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                      </svg> -->
                        Download Testcase
                      </button>
                      </div>
                        </div>
                        </div>
<br><br><br>
                  </main>
              </div>
          </div>
      </div>
  </section>


  <!-- <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process> -->

