import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';

import { LoaderService } from 'src/app/shared/service/loader.service';
import { OktaAuthService } from 'src/app/shared/okta/okta.service';

@Component({
  selector: 'app-sidenav1',
  templateUrl: './sidenav1.component.html',
  styleUrls: ['./sidenav1.component.scss']
})
export class Sidenav1Component implements OnInit{
  username!: string;

  constructor(
    private oktaService: OktaAuthService,
    private router: Router,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.getUserName();
  }






  logout() {
    // this.navToggle = false;
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.oktaService.logout();
    this.router.navigate(['logout']);
  }





  getUserName() {
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
  }

}
