<app-header></app-header>

<!-- Main navigation links -->
<div class="navigation">
  <!-- "Build Your Project" link with Angular click event -->
  <a  class="flex-item" [class.active-link]="isBuildProjectActive()" (click)="toggleBuildProject(); $event.preventDefault()">Build Your Project</a>
  <div class="divider-vertical"></div>
  <!-- Other main navigation links -->

  <a routerLink="/codeGenYLiteAI/documentcode" routerLinkActive="active-link">Code Documentation</a>
  <div class="divider-vertical"></div>
  <a routerLink="/codeGenYLiteAI/enhancecode" routerLinkActive="active-link">Code Enhancement</a>
  <div class="divider-vertical"></div>
  <a routerLink="/codeGenYLiteAI/chatcode" routerLinkActive="active-link">Code Assistant</a>
  <!-- <div class="divider-vertical"></div>
  <a routerLink="/codeGenYLiteAI/tcgenerate" routerLinkActive="active-link">Test Case Generation</a> -->
</div>

<!-- Sub-tabs for "Build Your Project" -->
<div *ngIf="showBuildProject" >
  <div class="navigation" style="background-color:#ececec;margin-bottom: 0px;">
    <div class="custom-tabs">
      <!-- Custom tab for "BRD" -->
      <div class="tab" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/brd') }" routerLinkActive="active-link" routerLink="/codeGenYLiteAI/brd">BRD</div>
      <!-- Custom tab for "SDD" -->
      <div class="tab" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/sdd') }" routerLinkActive="active-link" routerLink="/codeGenYLiteAI/sdd">SDD</div>
      <!-- Custom tab for "API Interface" -->
      <div class="tab" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/apigenerate') }" routerLinkActive="active-link" routerLink="/codeGenYLiteAI/apigenerate">API Interface</div>
      <!-- Custom tab for "Code Generation" -->
      <div class="tab" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/codegenerate') }" routerLinkActive="active-link" routerLink="/codeGenYLiteAI/codegenerate">Code Generation</div>
    <!-- custom tab for "Testcase generation" -->
    <div class="tab" [ngClass]="{ 'active': isTabActive('/codeGenYLiteAI/tcgenerate') }" routerLinkActive="active-link" routerLink="/codeGenYLiteAI/tcgenerate">Testcase Generation</div>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
<br><br>
<app-footer style="z-index: 1; bottom:0;" *ngIf="title"></app-footer>
