import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RoundLoaderService } from 'src/app/codeGenYLite/Services/roundloader.service';
import { SharedService } from 'src/app/codeGenYLite/Services/shared.service';
import { ContactusComponent } from 'src/app/codeGenYLite/components/includes/popup/contactus/contactus.component';
import { DisclaimerComponent } from 'src/app/codeGenYLite/components/includes/popup/disclaimer/disclaimer.component';
import { SettingsComponent } from 'src/app/codeGenYLite/components/includes/popup/settings/settings.component';

@Component({
  selector: 'app-header1',
  templateUrl: './header1.component.html',
  styleUrls: ['./header1.component.scss']
})
export class Header1Component implements OnInit, OnChanges {

  username: any;
  isPageVisited: boolean = true;
  constructor(public dialog: MatDialog, private router: Router,
    private sharedService: SharedService,
    private loaderService: RoundLoaderService) { }

    ngOnInit(): void {
      this.getUserName();
      // this.getSubscriptionStatus();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['isSubscribed']) {
      // this.getSubscriptionStatus();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DisclaimerComponent);
}

opencontactus(){
  this.dialog.open(ContactusComponent, {
    width: '500px', // Set the width of the modal
  });
}

  getUserName() {
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
  }

  openSetting(){
     this.dialog.open(SettingsComponent, {
      width: '800px', // Set the width of the modal
    });
  }

  productPage()
  {
    this.router.navigate(['codeGenYLite/products']);
    localStorage.setItem('tab', '0');
  }

  exitApp() {//closeApplication
    localStorage.removeItem('okta-username');
    this.router.navigate(['logout']);
  }

  // open subscription popup ---------------------

  // openSubscriptionPopup() {
  //   if (!this.subsService.isAuthenticated) {
  //     const dialogRef = this.dialog.open(SubscriptionComponent, {
  //       width: '500px'
  //     })
  //     dialogRef.afterClosed().subscribe((data: any) => {
  //       if (data) {
  //         this.getSubscriptionStatus();
  //       }
  //     });
  //   }


  // }

  goToHome() {
    this.router.navigate(['codeGenYLite']);

  }
  // scrollToSection(): void {
  //   console.log('Scrolling to section...');
  //   this.router.navigate(['/']);
  //   const element = document.getElementById('features-section');
  // console.log('Element:', element);

  //   this.loaderService.scrollToElement('features-section'); // Replace with the actual ID of your section
  // }
  isHomePage(): boolean {
    return this.router.url === '/codeGenYLite';
  }

  scrollToSection(section: string) {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  navigateTo(section: string) {
    this.router.navigate(['/codeGenYLite']).then(() => {
      setTimeout(()=>{
        this.scrollToSection(section);
      }, 300);
    });
  }
}
