import { Component } from '@angular/core';

@Component({
  selector: 'app-systran-translate',
  templateUrl: './systran-translate.component.html',
  styleUrls: ['./systran-translate.component.scss']
})
export class SystranTranslateComponent {
  sourceText = '';
  targetText = '';
  sourceLanguage = 'en';
  targetLanguage = 'es';

  constructor() {}

  translate() {

  }
}
