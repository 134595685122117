import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AccesscheckService {
  accesspoint = environment.nodeEndpoint;
  constructor(private http: HttpClient) { }

  useraccesscheck(): Observable<any> {
    //http post request with payload
    return this.http.post(`${this.accesspoint}validate-role`, {
     'process_value':"CodeGenYLite",
    });

   }
}