<app-sidenav1></app-sidenav1>

<br>
<div class="container" style="padding-top: 45px;" *ngIf="!isIntelliQARoute && !isreport && !ishome">
  <div class="flex-item">
    <div class="navigation">
      <a routerLink="/interview-assist/Assisthome" routerLinkActive="active-link">Home</a>
      <div class="divider-vertical"></div>
      <ng-container *ngIf="!isIntelliQARoute">
        <a routerLink="/interview-assist/TalentAdmin" routerLinkActive="active-link">TalentAdmin</a>
        <div class="divider-vertical"></div>
        <a routerLink="/interview-assist/feedback" routerLinkActive="active-link">Feedback</a>
        <!-- <div class="divider-vertical"></div> -->
      </ng-container>
      <!-- <ng-container >
        <a *ngIf="isIntelliQARoute " [routerLink]="['/interview-assist', reqid, uniqId, 'IntelliQ&A']" routerLinkActive="active-link">IntelliQ&A</a>
        <div class="divider-vertical"></div>
        <a *ngIf="isIntelliQARoute" [routerLink]="['/interview-assist', reqid, uniqId, 'Report-Gen']" routerLinkActive="active-link">AI ReportGen Pro</a>
      </ng-container> -->
    </div>
  </div>
</div>
<div style="padding-top: 45px;">
  <router-outlet></router-outlet>
</div>
