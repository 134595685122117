import { Component, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { MatDialog } from '@angular/material/dialog';
import { PreviewOrAskQuestionComponent } from '../../popups/preview-or-ask-question/preview-or-ask-question.component';
import { Constants } from 'src/app/shared/Utils/Constants';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import { LocalService } from 'src/app/shared/service/local.service';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-chatbot-section',
  templateUrl: './chatbot-section.component.html',
  styleUrls: ['./chatbot-section.component.css']
})
export class ChatbotSectionComponent implements OnInit {
 //For icon
 faRefresh=faRefresh;

  searchQuery: string = '';
  selectedCollection: string = '';
  collectionNames: string[] = [];
  showSummarizedResponse: boolean = false;
  showFiles: boolean = false;
  summarizedResponse: string = '';
  documentLists: string[] = [];
  fileData: {
    fname: string,
    content: string,
    video: boolean,
    timestamp: any,
    source: string,
    sharepointurl: string,
    industry: string,
    language: string,
    region: string,
    file_type: string
  }[] = [];
  isLoading: boolean = false;
  returnedDbRecords: any;
  returnedPreviouslyUsedQueryRecords: any;
  LocalStorageArrayData: any[] = [];


  dropdownSettings: IDropdownSettings = {
    singleSelection: false,
    enableCheckAll: false,
    itemsShowLimit: 3,
    allowSearchFilter: false
  };
  // for filters
  filters_list: any = {
    industry: [],
    language: [],
    region: [],
    file_type: []
  };
  selected_filters_list: any = {
    industry: [],
    language: [],
    region: [],
    file_type: []
  };

  refreshFilters(){
    this.selected_filters_list={
      industry: [],
      language: [],
      region: [],
      file_type: []
    };
    this.filteredFileData = this.fileData;
    this.createFiltersList(this.filteredFileData)
  }
  createFiltersList(files: any) {
    console.log(files);
    this.filters_list={
      industry: [],
      language: [],
      region: [],
      file_type: []
    };
    files.forEach((file: any) => {
      if (this.filters_list.industry.indexOf(file.filters?.Industry?.Value) == -1 && file.filters?.Industry?.Value) { this.filters_list.industry.push(file.filters?.Industry?.Value) }
      if (this.filters_list.language.indexOf(file.filters?.Language?.Value) == -1 && file.filters?.Language?.Value) { this.filters_list.language.push(file.filters?.Language?.Value) }
      if (this.filters_list.region.indexOf(file.filters?.Region?.Value) == -1 && file.filters?.Region?.Value) { this.filters_list.region.push(file.filters?.Region?.Value) }
      if (this.filters_list.file_type.indexOf(file.fname?.split('.').slice(-1).toString()) == -1 && file.fname) { this.filters_list.file_type.push(file.fname?.split('.').slice(-1).toString()) }
    });
  }
  filteredFileData: any;
  filter(e: string) {
    let temp = this.filters_list;
    this.filteredFileData = this.fileData;
    // Data Filter Part

    // for industry
    if (this.selected_filters_list.industry.length > 0) {
      this.filteredFileData.forEach((file: any) => {
        if (this.selected_filters_list.industry.indexOf(file.filters.Industry.Value) == -1) {
          this.filteredFileData = this.filteredFileData.filter((e: any) => { return e != file })
        }
      })
    }
    // for language
    if (this.selected_filters_list.language.length > 0) {
      this.filteredFileData.forEach((file: any) => {
        if (this.selected_filters_list.language.indexOf(file.filters.Language?.Value) == -1 || file.filters.Language == null) {
          this.filteredFileData = this.filteredFileData.filter((e: any) => { return e != file })
        }
      })
    }
    // for region
    if (this.selected_filters_list.region.length > 0) {
      this.filteredFileData.forEach((file: any) => {
        if (this.selected_filters_list.region.indexOf(file.filters.Region.Value) == -1) {
          this.filteredFileData = this.filteredFileData.filter((e: any) => { return e != file })
        }
      })
    }
    // for file type
    if (this.selected_filters_list.file_type.length > 0) {
      this.filteredFileData.forEach((file: any) => {
        if (this.selected_filters_list.file_type.indexOf(file.fname?.split('.').slice(-1).toString()) == -1) {
          this.filteredFileData = this.filteredFileData.filter((e: any) => { return e != file })
        }
      })
    }

    //Filters Filter Part
    this.createFiltersList(this.filteredFileData);
    if(e=='industry'){
      this.filters_list.industry = this.filters_list.industry.length < temp.industry.length ? temp.industry : this.filters_list.industry;
    }else if(e=='language'){
      this.filters_list.language = this.filters_list.language.length < temp.language.length ? temp.language: this.filters_list.language;
    }else if(e == 'region'){
      this.filters_list.region = this.filters_list.region.length < temp.region.length ? temp.region : this.filters_list.region;
    }else if(e == 'file_type'){
      this.filters_list.file_type = this.filters_list.file_type.length < temp.file_type.length ? temp.file_type : this.filters_list.file_type;
    }
  }

  constructor(private dialog: MatDialog, private localservice: LocalService, private commonServiceObj: CommonService) {
  }
  ngOnInit(): void {
    this.GetCollections();
    this.FetchDbRecords();
  }

  // get collections from DB
  GetCollections() {
    let url = Constants.config.collections;
    var body = {
      "server_name": Constants.config.server_name,
      "admin_username": Constants.config.admin_username,
      "password": Constants.config.password,
      "database_name": Constants.config.database_name
    };
    try {
      this.commonServiceObj.postMethodAxios(url, body).then((response: any) => {
        this.collectionNames = response.data.collections;
      })
    } catch (error) {

    }
  }

  inputerror: boolean = false;
  //get results on click or on keup enter
  GetSearchResultData(searchquery: any) {
    if (this.searchQuery.trim() != '' && this.selectedCollection != '') {
      this.inputerror = false;
      try {
        this.showSummarizedResponse = false;
        this.showFiles = false;
        this.isLoading = true;
        if ((searchquery !== '' || searchquery !== null) && (this.selectedCollection !== '' || this.selectedCollection !== null)) {
          this.commonServiceObj.getMethodAxios(Constants.config.query + searchquery + '?&collection_name=' + this.selectedCollection.replaceAll(' ', '%20').replaceAll('&', '%26')).then(
            (response: any) => {
              this.showSummarizedResponse = true;
              this.summarizedResponse = response.data.gpt_response.split("Documents Used:")[0]?.trim();
              this.documentLists = response.data.gpt_response.split("Documents Used:")[1]?.trim().split(',').filter((text: string) => text?.trim().length > 0 && text !== ', ').map((text: string) => text?.trim());
              this.ShowRetrievedFiles(response.data.retrieved_files);
              this.showFiles = true;
              this.isLoading = false;
              this.SaveGptResponse(searchquery, JSON.stringify(response.data));
              this.FetchDbRecords();
            });
        }
      } catch (error) {
      }
    }
    else {
      this.inputerror = true;
    }
  }


  // show files
  ShowRetrievedFiles(retrievedFiles: any) {
    this.filters_list = {
      industry: [],
      language: [],
      region: [],
      file_type: []
    };
    this.selected_filters_list = {
      industry: [],
      language: [],
      region: [],
      file_type: []
    };
    try {
      const uniqueFilenames = new Set<string>();
      this.fileData = retrievedFiles.reduce((uniqueFiles: any, file: any) => {
        if (!uniqueFilenames.has(file.fname)) {
          uniqueFilenames.add(file.fname);
          uniqueFiles.push({
            fname: file.fname,
            content: file.content,
            video: file?.video,
            timestamp: file?.timestamp,
            filters: file?.filters,
            source: file?.source,
            sharepointurl: file?.sharepointurl
          });
        }
        return uniqueFiles;
      }, []);
      this.filteredFileData = this.fileData;
      this.createFiltersList(this.fileData)
    } catch (error) {

    }
  }

  // open preview or ask question popup
  OpenDialogPreviewOrAskQuestion(i: any) {
    let seconds: number = 0;
    try {
      if (this.fileData[i]?.video == true) {
        seconds = this.TimestampToSeconds(this.fileData[i].timestamp);
      }
      const dialogRef = this.dialog.open(PreviewOrAskQuestionComponent, {
        data: {
          sessionURL: this.fileData[i]?.sharepointurl,
          video: this.fileData[i]?.video,
          source: this.fileData[i]?.source + '&t=' + seconds,
          fileNAME: this.fileData[i]?.fname,
          collectionName: this.selectedCollection,
        },
        height: '600px',
        width: '1800px'
      });
      dialogRef.afterClosed().subscribe(result => { });
    } catch (error) {

    }
  }

  // making timestamp in seconds
  TimestampToSeconds(timestamp: string): number {
    try {
      const parts = timestamp.split(':');
      if (parts.length <= 1) { return 0; }
      const hours = parseInt(parts[0], 10) || 0;
      const minutes = parseInt(parts[1], 10) || 0;
      const secondsParts = parts[2].split('.');
      const seconds = parseInt(secondsParts[0], 10) || 0;
      const milliseconds = parseInt(secondsParts[1], 10) || 0;
      const totalSeconds = hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;
      return totalSeconds;
    } catch (error) {

    }
    return 0;
  }

  // Save gpt response in DB
  SaveGptResponse(query: string, data: string) {
    let url = Constants.config.historyLog;
    var body = {
      "action": "create",
      "user": Constants.user,
      "question": query,
      "answer": data,
      "collection_name": this.selectedCollection,
      "id": "null"
    }
    try {
      this.commonServiceObj.postMethodAxios(url, body).then((response: any) => { })
        .catch((reason) => {
          console.log('API Call Failed because:', reason);
        })
    } catch (error) {

    }
  }

  // Fetch all records from DB
  FetchDbRecords() {
    let url: any;
    url = Constants.config.historyLog;
    var body = {
      "action": "get_by_user",
      "user": Constants.user,
      "question": "",
      "answer": "",
      "collection_name": this.selectedCollection.replaceAll(' ', '%20') + '&id=null',
      "id": "null"
    }
    try {
      this.commonServiceObj.postMethodAxios(url, body).then((response: any) => {
        this.returnedDbRecords = response.data.result;

        // console.log("fetchData", this.returnedDbRecords);
        this.SaveDataInLocalStorage(this.returnedDbRecords);
      })
        .catch((reason) => {
          // console.log('API Call Failed because:', reason);
        })
    } catch (error) {

    }

  }

  // Save records fetched from DB into Local storage
  SaveDataInLocalStorage(DBdata: any) {
    try {
      this.localservice.saveSummerizedData1(JSON.stringify(DBdata));
      this.FetchDataFromLocalStorage();
    } catch (error) {

    }
  }


  // Fetch data from Local Storage
  FetchDataFromLocalStorage() {
    try {
      var SummarizedData: any = this.localservice.getSummerizedData('History')
      var data = JSON.parse(SummarizedData);
      this.LocalStorageArrayData = []
      data.forEach((element: any) => {
        let userQuery = {
          question: element.question,
          id: element.id
        };
        this.LocalStorageArrayData.push(userQuery);
      });
      // console.log(this.LocalStorageArrayData);
    } catch (error) {

    }
  }

  // Search previously used query
  SearchPreviouslyUsedQuery(query: string, id: string) {
    let url: any;
    url = Constants.config.historyLog;
    var body = {
      "action": "get_by_id",
      "user": Constants.user,
      "question": "",
      "answer": "",
      "collection_name": "",
      "id": id
    }
    try {
      this.showSummarizedResponse = false;
      this.showFiles = false;
      this.isLoading = true;
      this.commonServiceObj.postMethodAxios(url, body).then((response: any) => {
        this.returnedPreviouslyUsedQueryRecords = response.data.result;
        this.isLoading = false;
        // console.log("fetchData", this.returnedPreviouslyUsedQueryRecords);
        this.selectedCollection = this.returnedPreviouslyUsedQueryRecords[0].collection_name;
        // console.log(JSON.parse(this.returnedPreviouslyUsedQueryRecords[0].answer));
        var response_data = JSON.parse(this.returnedPreviouslyUsedQueryRecords[0].answer);
        this.summarizedResponse = response_data.gpt_response.split("Documents Used:")[0]?.trim();
        this.documentLists = response_data.gpt_response.split("Documents Used:")[1]?.trim().split(',').filter((text: string) => text?.trim().length > 0 && text !== ', ').map((text: string) => text?.trim());
        this.ShowRetrievedFiles(response_data.retrieved_files);
        this.isLoading = false;
        this.showSummarizedResponse = true;
        this.showFiles = true;
        // this.SaveDataInLocalStorage(this.returnedDbRecords);
      })
    } catch (error) {

    }
  }

  // fetch data to show from local storage


  // Check if user question and collection is available in local storage
  // checkUserQueryAndCollectionInLocalStorage(){
  //   try {

  //   } catch (error) {

  //   }
  // }
}

