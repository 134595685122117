<div class="popup">
  <div class="popup-head" (click)="onCloseClick()">
          <i class="fa-solid fa-xmark"></i>
  </div>
  <div class="popup-content">
      <div class="popup-icon">
         <img src="../../../../../assets/images/timeouterror.png" alt="">
      </div>
      <div class="popup-text">
          <p>Timeout error</p>
          <p>Please try again.</p>
      </div>
  </div>
  <div class="popup-btn">
      <!-- <button class="btn btn-custom btn-first" (click)="onCloseClick()">{{ data.firstBtnName }}</button> -->
      <button class="btn btn-custom btn-second" (click)="onOpenClick()">Close</button>
  </div>
</div>
