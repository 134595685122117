<mat-toolbar class="top-bar-container">
    <div class="col-sm-10">
      <img class="logo-img" src="assets/imgs/G_BIG.png" title="Genpact" alt="Genpact">
      <!-- <img class="logo-img" src="../../../../assets/images/devflowLogo.png" title="DevFlow" alt="DevFlow"> -->
    </div>
    <!-- <div class="left-sub-header" [ngStyle]="{'background-color': isHomePage() ? 'white' : 'transparent'}">
      <img src="../../../../assets/images/devflowLogo.png" alt="logo">
    </div> -->
    <!-- <div class="col-sm-2">
      <div class=" login-btn w-100 text-end">
        <div class="row loginbtn" title="Log In">
         <span style="padding-right: 17px;">LOGIN</span><img src="assets/imgs/arrow-right-icon-midnight-blue-85d31791.svg">
        </div>
      </div>
    </div> -->
    <div class="nav-bar">
      <a class="nav-link" routerLink="/sop-tool" routerLinkActive="active">Home</a>
      <a class="nav-link" routerLink="/codeGenYLite" routerLinkActive="active">codeGenYLite</a>
      <!-- <a class="nav-link" routerLink="/sop-upload" routerLinkActive="active">SOP Creator</a> -->
    </div>
</mat-toolbar>
