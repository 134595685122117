<div mat-dialog-content>
  <!-- Your modal content goes here -->
  <div class="close-box" (click)="onClose()">
    <i class="fa-solid fa-xmark"></i>
  </div>
  <div class="popup-content">
    <div class="success">
  <div class="img-header">
    <img src="../../../../../assets/images/done.png" alt="">
  </div>
  <div class="content">
  <p class="awesome">Awesome !</p>
  <p class="text-content">
    The BRD document is created and now ready for download
  </p>

</div>
</div>
</div>
<p class="awesomemsg">{{msg}}</p>
</div>
  <div class="action-btn">
    <!-- <button mat-button (click)="onClose()">Close</button> -->
    <!-- <button mat-dialog-close class="btn btn-custom-open">Close</button> -->
    <!--<button mat-dialog-close class="btn btn-custom-open">Close</button>-->
    <a mat-dialog-close [href]="fileUrl" class="btn btn-custom-Download" [download]="fileName">Download document</a>
    <!--<a [href]="fileUrl" class="btn btn-custom-Download" [download]="fileName" (click)="downloadDoc()">Download Document</a>-->
  </div>
