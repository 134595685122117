import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InterAssistService {
  endpoint = 'https://api.playground-dev.genpact.com/hackathon';
  //  endpoint = 'https://gapiw.playground-dev.genpact.com/bgcheck';
    //endpoint ='https://gapiw.playground-dev.viren.in/bgc';
    endpoint1 = 'https://m3po2fpqgj.execute-api.ap-south-1.amazonaws.com/dev';
    accesspoint = environment.nodeEndpoint;


  constructor(private http: HttpClient) { }

  userList(): Observable<any> {
  return this.http.get(`${this.endpoint}/get-details`);
}

createuser(payload:any){
  return this.http.post(`${this.endpoint}/create`,payload)
}

uploadcvjd(payload:any){
  return this.http.post(`${this.endpoint}/upload_cv_jd`,payload)
}
 changestatus(payload:any){
  return this.http.post(`${this.endpoint}/change-status`,payload)
 }
qanda(payload:any){
  return this.http.post(`${this.endpoint}/generate-questions`,payload)
}
cvsummary(payload:any){
  return this.http.post(`${this.endpoint}/generate-cv-summary`,payload)
}
jdsummary(payload:any){
  return this.http.post(`${this.endpoint}/generate-jd-summary`,payload)
}

getskills(payload:any){
  return this.http.post(`${this.endpoint}/get-skills`,payload)
}
getquestions(payload:any){
  return this.http.post(`${this.endpoint}/get-questions`,payload)
}
gettranscript(payload:any){
  return this.http.post(`${this.endpoint}/get-transcript`,payload)
}
downloadTranscript(payload:any){
  return this.http.post(`${this.endpoint}/download-transcript-file`,payload)
}
generateReport(payload:any){
  return this.http.post(`${this.endpoint}/generate-report`,payload)
}
downloadReport(payload:any){
  return this.http.post(`${this.endpoint}/download-report-file`,payload)
}

// downloadbrd(payload: any): Observable<any> {
//   return this.http.post(`${this.apiURL}/devops/brd_document_status`, payload,
//   {
//       responseType: 'blob'
//     });
// }
downloadTranscript1(payload: any): Observable<any> {
  return this.http.post(`${this.endpoint}/download-transcript-file`, payload,
  {
      responseType: 'blob'
    });
}

savereport(payload:any){
  return this.http.post(`${this.endpoint}/save-report`,payload);
}
getreport(payload:any){
  return this.http.post(`${this.endpoint}/get-report`,payload,
  { responseType: 'blob'

  });
}

chatbot(payload:any){
  return this.http.post(`${this.endpoint}/chatbot`,payload)
}

}
