import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from '../shared/service/message.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SopService } from '../shared/service/sop.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-sop-upload',
  templateUrl: './sop-upload.component.html',
  styleUrls: ['./sop-upload.component.scss']
})
export class SopUploadComponent {
  selectedFile: File | any;
  fileContent: string | undefined;
  sopData: any ;
  sopDataString: string = '';
  error: string | undefined;
  showLoading: boolean = false;
  isMenuOpen: boolean = false;
  isdownload: boolean = false;
  submitted: boolean = false;
  fileUploadSuccess: boolean = false;


  constructor(private http: HttpClient, private sanitizer: DomSanitizer,private sopservice:SopService) {}

  onFileSelected(event: any) {
    const files = event.target.files;
    if (files && files.length > 0) {
      this.selectedFile = files[0];

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        this.fileContent = fileReader.result as string;
      };

      // Read the selectedFile as text
      fileReader.readAsText(this.selectedFile);
    }
  }



  // ...
  // generateSOP() {
  //   if (!this.selectedFile) {
  //     return;
  //   }
  // this.error = '';
  //   this.toggleLoading(); // Show loading spinner

  //   // Create a URL with the fileId as a query parameter
  //   const apiUrl = `http://127.0.0.1:8010/submitting?fileId=${this.selectedFile.name}`;
  // this.http.get<any>(apiUrl)
  //     .pipe(
  //       catchError((error: HttpErrorResponse) => {
  //         this.toggleLoading(); // Hide loading spinner on error
  //         if (error.status === 400) {
  //           this.error = 'Bad Request: Please check your input.';
  //         } else if (error.status === 500) {
  //           this.error = 'Internal Server Error: Something went wrong on the server.';
  //         } else {
  //           this.error = 'An unexpected error occurred.';
  //           this.sopData = 'No data ';
  //         }
  //         return throwError(error);
  //       })
  //     )
  // .subscribe((response) => {
  //       console.log(response);
  //       this.toggleLoading();
  // this.isdownload=true;
  // if (response) {
  //         this.sopData = response;
  // }
  // else {
  //         this.sopData = 'response'; // Handle the case when no data is received
  //       }
  // });
  // }

// ...

generateSOP() {
  if (!this.selectedFile) {
    return;
  }

  this.error = '';
  this.toggleLoading(); // Show loading spinner

  // Create a URL with the fileId as a query parameter
  //const apiUrl = `http://127.0.0.1:8010/submitting?fileId=${this.selectedFile.name}`;

  //?fileId=${this.selectedFile.name}
  // Make a GET request to the submit API
  this.sopservice.downloadSop(this.selectedFile)
    .subscribe((responseArrayBuffer: ArrayBuffer) => {
      console.log(responseArrayBuffer);
      this.toggleLoading();
      this.isdownload=true;// Hide loading spinner after receiving the response
      if (responseArrayBuffer) {
        const blob = new Blob([responseArrayBuffer], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        // Create a URL for the Blob
        const blobUrl = URL.createObjectURL(blob);

        // Assign the URL to sopData
        this.sopData = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl); // Set SOP data from the received data
        //this.convertDocxToText(response);
      } else {
        this.sopData = 'response'; // Handle the case when no data is received
      }
    });
}

// ...
// convertDocxToText(docxBlob: Blob) {
//   const reader = new FileReader();
//   reader.onload = (event) => {
//     const content = event.target?.result as string;
//     this.sopData = content;
//   };

//   // Read the Blob as text (assuming it's a text-readable DOCX)
//   reader.readAsText(docxBlob);
// }

// ...



  downloadSOP() {
    if (!this.sopData) {
      return;
    }

    // const blob = new Blob([this.sopData], { type: 'application/msword' });application/vnd.openxmlformats-officedocument.wordprocessingml.document
    const jsonSopData = JSON.stringify(this.sopData);
const blob = new Blob([jsonSopData], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = 'generated_sop.docx';

    link.click();
    window.URL.revokeObjectURL(url);
  }

  toggleLoading() {
    this.showLoading = !this.showLoading;
  }
    toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen; // Toggle the menu state

  }
  // ...

clearSelection() {
  let inputFile:any = document.getElementById('file_upload');
  if(inputFile) inputFile.value = "";
  this.selectedFile = undefined; // Clear the selected file
  this.fileContent = undefined; // Clear the file content
  this.sopData = undefined; // Clear the SOP data
}

// ...
// ...

// uploadFile() {
//   if (!this.selectedFile) {
//     return;
//   }

//   this.error = '';
//   this.toggleLoading(); // Show loading spinner
//   this.submitted = true;
//   // Create a FormData object and append the selected file to it
//   const formData = new FormData();
//   formData.append('file_new', this.selectedFile, this.selectedFile.name);

//   // Make an API call to handle the uploaded file with a POST method
//   this.http.post<any>('http://127.0.0.1:8010/uploading', formData)
//     .pipe(
//       catchError((error: HttpErrorResponse) => {
//         this.toggleLoading(); // Hide loading spinner on error
//         if (error.status === 400) {
//           this.error = 'Bad Request: Please check your input.';
//         } else if (error.status === 500) {
//           this.error = 'Internal Server Error: Something went wrong on the server.';
//         } else {
//           this.error = 'An unexpected error occurred.';
//         }
//         return throwError(error);
//       })
//     )
//     .subscribe((response) => {
//       this.toggleLoading(); // Hide loading spinner after receiving the response
//       if (response || response.success) {
//         // Handle a successful response here
//         console.log('File uploaded successfully.');
//         alert('File uploaded successfully.');
//       } else {
//         // Handle an error response here, if needed
//         console.error('Error uploading the file:', response);
//       }
//     });
// }

// ...
uploadFile() {
  if (!this.selectedFile) {
    return;
  }

  this.error = '';
  this.toggleLoading(); // Show loading spinner
  this.submitted = true;
  // Create a FormData object and append the selected file to it
  const formData = new FormData();
  formData.append('file_new', this.selectedFile, this.selectedFile.name);

  // Make an API call to handle the uploaded file with a POST method
  this.sopservice.uploadSop(this.selectedFile)
 // this.http.post<any>('http://127.0.0.1:8010/uploading', formData)
    .pipe(
      catchError((error: HttpErrorResponse) => {
        this.toggleLoading(); // Hide loading spinner on error
        if (error.status === 400) {
          this.error = 'Bad Request: Please check your input.';
        } else if (error.status === 500) {
          this.error = 'Internal Server Error: Something went wrong on the server.';
        } else {
          this.error = 'An unexpected error occurred.';
        }
        return throwError(error);
      })
    )
    .subscribe((response) => {
      this.toggleLoading(); // Hide loading spinner after receiving the response
      if (response ) {
        // Handle a successful response here
        console.log('File uploaded successfully.');
        this.fileUploadSuccess = true;

      } else {
        // Handle an error response here, if needed
        console.error('Error uploading the file:', response);
      }
    });
}


}
