<!-- <p>sop-upload works!</p>
 -->




<div class="background-container">
  <div class="mt-5">
    <div class="item px-3 px-md-5 ">

      <form (ngSubmit)="uploadFile()" data-validation="true" enctype="multipart/form-data">
        <div class="item-inner">
          <div class="item-content p-0  px-md-5">
            <div class="image-upload">
              <label style="cursor: pointer;" for="file_upload">
                <img src="" alt="" class="uploaded-image">
                <div class="h-100">
                  <div class="dplay-tbl">
                    <div class="dplay-tbl-cell">
                      <h5><b><br><span class="css-nwtri eqdbnj013" style="size:25px;">
                            <svg viewBox="0 0 24 24" style="height:35px" aria-hidden="true" focusable="false"
                              fill="currentColor" xmlns="http://www.w3.org/2000/svg" color="inherit"
                              class="e1ugi8lo1 css-noeb3a ex0cdmw0">
                              <path fill="none" d="M0 0h24v24H0V0z"></path>
                              <path
                                d="M19.35 10.04A7.49 7.49 0 0012 4C9.11 4 6.6 5.64 5.35 8.04A5.994 5.994 0 000 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM19 18H6c-2.21 0-4-1.79-4-4 0-2.05 1.53-3.76 3.56-3.97l1.07-.11.50-.95A5.469 5.469 0 0112 6c2.62 0 4.88 1.86 5.39 4.43l.30 1.5 1.53.11A2.98 2.98 0 0122 15c0 1.65-1.35 3-3 3zM8 13h2.55v3h2.9v-3H16l-4-4z">
                              </path>
                            </svg>
                          </span>Choose Your File to Upload</b></h5>
                      <p>Only txt files are allowed</p>
                    </div>
                  </div>
                </div>
                <!-- Upload content -->
                <input accept=".txt,.TXT" data-required="image" type="file" name="image_name" id="file_upload"
                  class="image-input" data-traget-resolution="image_resolution" (change)="onFileSelected($event)">
              </label>
            </div>
            <br>
          </div><!-- item-content -->
        </div>
        <!-- Add a submit button to trigger the form submission -->
        <div class="file-log" *ngIf="selectedFile">
          <p>Selected File: {{ selectedFile.name }} <button class="clear-button"
              (click)="clearSelection()">&#10006;</button></p>
          <div *ngIf="fileUploadSuccess">
            <p>File uploaded successfully.</p>
          </div>
        </div>

        <div class="col-12 text-center">
          <button type="submit" class="submit-button">Submit</button>
        </div>
      </form>

    </div>



    <div class="col-12 p-0 px-md-4">
      <!-- Displayed file content -->
      <div class="file-content" *ngIf="submitted&&fileContent">
        <h3>File Content:</h3>
        <textarea rows="6" class="sop-textarea border">{{ fileContent }}</textarea>
      </div>

      <!-- Generate SOP button -->
      <button class="generate-sop-button" (click)="generateSOP()" [disabled]="!selectedFile || showLoading"
        *ngIf="submitted">Download SOP</button>

      <!-- Loading indicator -->
      <div *ngIf="showLoading" class="loading-indicator">
        <i class="fas fa-spinner fa-spin"></i> Downloading SOP...
      </div>

      <!-- Error message -->
      <div class="error-message" *ngIf="error">
        {{ error }}
      </div>


      <iframe *ngIf="sopData" [src]="sopData" width="" height="" frameborder="0"></iframe>
      <div *ngIf="error">{{ error }}</div>
    </div>

  </div>
</div>