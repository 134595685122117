import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-interview-assistant',
  templateUrl: './interview-assistant.component.html',
  styleUrls: ['./interview-assistant.component.scss']
})
export class InterviewAssistantComponent implements OnInit {

  isIntelliQARoute: boolean = false;
  reqid: any;
  uniqId: any;
  isreport: boolean = false;
  ishome: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.updateRouteParams();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateRouteParams();
      }
    });
  }

  updateRouteParams(): void {
    const params = this.route.snapshot.params;
    this.reqid = params['rid'];
    this.uniqId = params['id'];
    this.isIntelliQARoute = this.router.url.includes('IntelliQ&A');
    this.isreport = this.router.url.includes('Report-Gen');
    this.ishome = this.router.url.includes('Assisthome');
  }
}
