import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class SopService {
  activeUserRole = new BehaviorSubject({});
  pageTitle = new BehaviorSubject({'title':'Konverse AI'});

  uploadBYOD(currentFile: any, arg1: string, isImageExits: any) {
    throw new Error('Method not implemented.');
  }
  endpoint = environment.nodeEndpoint;
  apiURL = environment.apiURL;
  //endpoint1=environment.apiURL;

  constructor(private http: HttpClient, private router: Router) { }

  sendBYODRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery,
      "filename": localStorage.getItem('fileName')
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }


  sendFormRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }

  uploaddocx(file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${this.apiURL}/devops/extracttext`, formData).pipe(catchError(this.handleError))
  }

  checkFileStatus() {

    return this.http.get(`${this.endpoint}file-status`).pipe(catchError(this.handleError))
  }

  downloadSop(file: File): Observable<ArrayBuffer> {
    const url = `${this.apiURL}/submitting?fileId=${file.name}`;
    return this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return throwError('Bad Request: Please check your input.');
          } else if (error.status === 500) {
            return throwError('Internal Server Error: Something went wrong on the server.');
          } else {
            return throwError('An unexpected error occurred.');
          }
        })
      );
  }


  uploadSop(file: File) {
    console.log('api call ')
    const formData = new FormData();
    formData.append('file_new', file, file.name);
    //formData.append('with_image', imageExists ? 'true' : 'false');
    //with_image
    //if(imageExists) formData.append('filename file_image', file.name);
    //http://127.0.0.1:8010/submitting
    const req = new HttpRequest('POST', `${this.apiURL}/uploading`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  //file anageent sop-Admin
  filelist(payload: any) {

    const url = `${this.apiURL}/konverse/doc-list`;
    return this.http.post<any>(url, payload);
  }
  delfilelist(payload: any) {

    const url = `${this.apiURL}/konverse/del-doc-list`;
    return this.http.post<any>(url, payload);
  }
  uploadfilelist(payload: any) {

    const url = `${this.apiURL}/konverse/add-doc`;
    return this.http.post<any>(url, payload);
  }
  // complance services

  complanceChat(app: any, messages: any, source = '') {
    let payload: any = { message: messages };
    if (source) payload['source'] = source;

    return this.http.post(`${this.endpoint}pdf-search?app=${app}`, payload).pipe(catchError(this.handleError))
  }

  complanceChatStats() {
    const url = `${this.apiURL}/queryVS-status`;
    return this.http.get<any>(url);
  }




  createUserHistory(payload: any): Observable<any> {
    const url = `${this.endpoint}create-rag-user-history`;
    return this.http.post<any>(url, payload);
  }

  getBotHistory(payload: any): Observable<any> {
    const url = `${this.endpoint}functional_bot`;
    return this.http.post<any>(url, payload);
  }


  getUserHistory(): Observable<any> {
    const url = `${this.endpoint}rag-user-history`;
    return this.http.get<any>(url);
  }



  private handleError(error: HttpErrorResponse) {
    let errMessage = '';
    console.log('error', error);
    if (error?.status === 0) {
      console.error('An error occurred:', error.error);
    } else {

      if (error?.status === 409 || error?.status === 401) {
        errMessage = error.status.toString();
        return throwError(() => new Error(errMessage));
      }
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    errMessage = 'Please try again later';
    return throwError(() => new Error(errMessage));
  }

  getRagUserRoles(): Observable<string[]> {
    return this.http.get<any[]>(`${this.endpoint}get-raguser-role`);
  }

  getRagUserBots(): Observable<string[]> {
    return this.http.get<any[]>(`${this.endpoint}get-raguser-bots`);
  }
  //update user
  postUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}update-raguser`, payload);

  }
  //create user
  createUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}create-raguser`, payload);


  }
  userlist(Id: any,search=''): Observable<string[]> {
    let query = `${this.endpoint}get-raguser?process_id=${Id}`;
    if(search) query = query+'&search='+search;

    return this.http.get<any[]>(query);
  }

  deleteUser(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}delete-raguser-role`, payload);

  }

  getQuestion(payload: any): Observable<any> {
    return this.http.post<any>(`${this.endpoint}function-type-question`, payload);
  }
downloadzip(payload: any): Observable<any> {
  let token: any = localStorage.getItem('token');
  const zipurl = `${this.apiURL}/devops/workspace-zip`;
    // return this.http.post<any>(`${this.endpoint}/devops/workspace-zip`, payload);

    return this.http.post(zipurl, payload, { responseType: 'blob' ,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }});
  }

generatebrd(payload: any): Observable<any> {
    return this.http.post<any>(`${this.apiURL}/devops/generate_geny_brd`, payload);
  }

brdstatus(payload: any): Observable<any> {
  return this.http.post(`${this.apiURL}/devops/brd_document_status`, payload);
}
downloadbrd(payload: any): Observable<any> {
  return this.http.post(`${this.apiURL}/devops/brd_document_status`, payload,
  {
      responseType: 'blob'
    });
}

generatesdd(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/generate_geny_sdd`, payload);
}

sddstatus(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/sdd_document_status`, payload);
}

downloadsdd(payload: any): Observable<any> {
  return this.http.post(`${this.apiURL}/devops/sdd_document_status`, payload, {
      responseType: 'blob'
    });
}

clarifyapistatus(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/clarify_api_status`, payload);
}
generateinitialcodestatus(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/generate_initial_code_status`, payload);
}

logsStorage(payload: any) : Observable<any> {
  return this.http.post<any>(`${this.apiURL}/store_logs`, payload);
}
chatcode (payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/get_gpt_answer`, payload);
}
getdocumentation(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/get_documentation`, payload);
}
getdocumentationstatus(): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/get_documentation_status`, {});
}
downloadcodedocumentation(): Observable<any> {
  return this.http.post(`${this.apiURL}/devops/get_documentation_download`, {}, {
      responseType: 'blob'
    });
}
getenhancement(payload: any): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/get_enhancement`, payload);
}
getenhancementstatus(): Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/get_enhancement_status`, {});
}
getdomain():Observable<any> {
  return this.http.post<any>(`${this.apiURL}/devops/check_domain`,{});
}
}
