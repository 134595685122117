<div class="container mt-4">





    <!-- //dynamic table style="justify-content: flex-end;align-items: flex-end;"-->
    <div class="d-flex py-0" style="    align-content: center;flex-wrap: wrap;justify-content: flex-end;align-items: baseline;">
       <div class=" my-3 text-center" style="padding-left: 6px;">
        <button type="button" class="btn btn-primary"   style="font-family: georgia;height: 38px;margin-top: -5px;"
        data-bs-toggle="modal" data-bs-target="#Newuser">Add New Candidate
        <!-- <i class="fas fa-plus"></i> -->
       </button>
      </div>

      <div class="text-right" style="padding-left: 5px;">
        <button id="refreshbtn" class="bg-primary text-light p-2 px-4 border border-r1" style="background-color: #021D44!important;
        color: #ffffff!important;
        border: none!important;" (click)="getlist()">
          <!-- <i class="fa fa-refresh"></i> -->
          Refresh
        </button>
      </div>

      <div class="d-flex py-0" style="    align-content: center;flex-wrap: wrap;justify-content: flex-end;align-items: baseline;">
        <div class=" my-3 text-center" style="padding-left: 6px;">
          <button  [disabled]="true" type="button" class="btn btn-primary"   style="font-family: georgia;height: 38px;margin-top: -5px;">

        Fetch
      </button>
      </div>
      </div>

    </div>











  <div class="mat-elevation-z8">
    <!-- Search Box -->

    <div class="mb-3">
      <input type="text" placeholder="Search users..." matInput (keyup)="applyFilter($event)" class="form-control">
    </div>

    <!-- Table with sorting and pagination -->
    <div class="table-responsive">
      <table mat-table [dataSource]="dataSource" matSort class="table">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- For Requisition ID -->
<ng-container matColumnDef="requisition_id">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Requisition ID</th>
  <td mat-cell *matCellDef="let element"> {{element.requisition_id}} </td>
</ng-container>

<!-- For Candidate -->
<ng-container matColumnDef="candidate_name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Candidate </th>
  <td mat-cell *matCellDef="let element"> {{element.candidate_name}} </td>
</ng-container>

<!-- For Position -->
<ng-container matColumnDef="position">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Position </th>
  <td mat-cell *matCellDef="let element"> {{element.position}} </td>
</ng-container>

<!-- For Interviewer -->
<ng-container matColumnDef="interviewer_name">
  <th mat-header-cell *matHeaderCellDef mat-sort-header> Interviewer </th>
  <td mat-cell *matCellDef="let element"> {{element.interviewer_name}} </td>
</ng-container>

<!-- For Status -->
<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef mat-sort-header>Current Status </th>
  <td mat-cell *matCellDef="let element"> {{element.status =='completed' ? 'completed' : 'In progress' }} </td>
</ng-container>


        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef> Action </th>
          <td mat-cell *matCellDef="let element" style="display: flex; gap:10px;">
            <button class="btn btn-info btn-sm"  (click)="Navigate(element.id,element.requisition_id)">Go</button>
            <button class="btn btn-info btn-sm" style="background-color:#073262;color:#ffffff;" [disabled]="element.status !== 'completed'" (click)="downloadreport(element.requisition_id,element.id)">Final Report
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
              </svg>
            </button>
            <!-- <button class="btn btn-danger btn-sm">Delete</button> -->
          </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="pt-2"></mat-paginator>
  </div>
</div>

<!-- add user modal -->
<!-- Modal -->
<div class="modal fade" id="Newuser" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Enter the Details</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="mb-3 row d-flex align-items-center">
          <label for="staticEmail" class="col-sm-2 col-form-label">ID</label>
          <div class="col-sm-10">
            {{tabid}}
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Requisition ID</label>
          <div class="col-sm-10">
            <input [(ngModel)]="reqid" type="text" class="form-control" id="ID" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPassword" class="col-sm-2 col-form-label">Position Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="position" type="text" class="form-control" id="position">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Candidate Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="candidatename" type="text"  class="form-control" id="Candidate Name" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">Interviewer Name</label>
          <div class="col-sm-10">
            <input [(ngModel)]="interviewername" type="text"  class="form-control" id="Interviewer Name" >
          </div>
        </div>
        <div class="mb-3 row">
          <label for="staticEmail" class="col-sm-2 col-form-label">upload CV/JD</label>
          <div class="col-sm-10">
            <div class="col-md-6">
              <label for="cv-upload" class="btn btn-primary btn-block cvjd">Add CV</label>
              <input id="cv-upload" type="file" class="d-none" (change)="onFileSelected($event, 'CV')">
            </div>

            <!-- Upload JD Button -->
            <div class="col-md-6">
              <label for="jd-upload" class="btn btn-primary btn-block cvjd">Add JD</label>
              <input id="jd-upload" type="file" class="d-none" (change)="onFileSelected($event, 'JD')">
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let fileDetail of displayedFiles(); let i = index">
                  <td>{{ fileDetail.file.name }}</td>
                  <td>{{ fileDetail.type }}</td>

                  <td>
                    <button class="btn btn-danger btn-sm" (click)="removeFile(i)">
                      <i class="fas fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>





      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" (click)="createuser()">Save changes</button>
      </div>
    </div>
  </div>
</div>

<!-- cv jd modal -->
<!-- Modal -->
<!-- Button trigger modal -->
<button type="button" id="cvjd" style="display: none;" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
  Launch demo modal
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content" style="width:550px">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">CV JD Upload</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <app-cv-jd [uniqid]="existingId"></app-cv-jd>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="getlist()">Save</button>
        <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
      </div>
    </div>
  </div>
</div>
