import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalService {
  key = "123";
  constructor() { }

  public saveSummerizedData(key: string, value: string) {
    localStorage.setItem(key, value);
  }

  // save summarized data
  public saveSummerizedData1( value: string) {
    localStorage.setItem('History',value);
  }

  public getSummerizedData(key: string) {
    return localStorage.getItem(key);
  }

  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }
}
