import { Document } from 'docx';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, finalize } from 'rxjs/operators';
import { of } from 'rxjs';
import { InterAssistService } from '../service/inter-assist.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';
import {  Packer, Paragraph, TextRun } from 'docx';
import * as FileSaver from 'file-saver';
import * as HtmlDocx from 'html-docx-js-typescript';








interface FileDetail {
  file: File;
  type: 'CV' | 'JD';
  format: string;
}
@Component({
  selector: 'app-ialogin',
  templateUrl: './ialogin.component.html',
  styleUrls: ['./ialogin.component.scss']
})
export class IaloginComponent implements OnInit {
  displayedColumns: string[] = ['id', 'requisition_id', 'candidate_name', 'position', 'interviewer_name', 'status', 'actions'];
  dataSource!: MatTableDataSource<any>;
  reqid: any;
  position: any;
  interviewername: any;
  candidatename: any;
  existingId:any;
  tabid: any;

  files: FileDetail[] = [];
  displayType: 'CV' | 'JD' | 'All' = 'All';


  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  reportdata: any;

  constructor(private interAssistService: InterAssistService,
              private toastservice : ToastMessageService,
              private loaderservice : LoaderService,
              private router: Router,
  ) {}

  ngOnInit() {
    this.getlist()
  }

  getlist() {
    this.loaderservice.show();
    this.interAssistService.userList()
      .pipe(
        catchError(() => of(this.getDummyData())),
        finalize(() => this.loaderservice.hide())  // Ensures loader is hidden on both error and success
      )
      .subscribe(data => {
        this.loaderservice.hide();
        this.dataSource = new MatTableDataSource(data);
        this.tabid = this.dataSource.data.length + 1;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.dataSource.filterPredicate = (data, filter) => {
          const transformedFilter = filter.trim().toLowerCase();
          return data.id.toLowerCase().includes(transformedFilter) ||
                 data.requisition_id.toLowerCase().includes(transformedFilter) ||
                 data.candidate_name.toLowerCase().includes(transformedFilter) ||
                 data.position.toLowerCase().includes(transformedFilter) ||
                  data.interviewer_name.toLowerCase().includes(transformedFilter) ||
                  data.status.toLowerCase().includes(transformedFilter);
        };
        this.toastservice.showSuccess('User List Loaded Successfully');
      });
  }


  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  private getDummyData() {
    return [
      { id: 1, name: 'John Doe', email: 'john.doe@example.com', role: 'Admin', status: 'Active', actions: null },
      { id: 2, name: 'Jane Smith', email: 'jane.smith@example.com', role: 'User', status: 'Inactive', actions: null },
      { id: 3, name: 'Alice Johnson', email: 'alice.johnson@example.com', role: 'Moderator', status: 'Active', actions: null }
    ];
  }


createuser(){
  this.loaderservice.show();
this.toastservice.showmessage('Creating Record for'+ this.candidatename+' for the position of '+this.position+' under requisition id '+this.reqid+' by '+this.interviewername);
  const payload = {
    "requisition_id": this.reqid,
    "position": this.position,
    "interviewer_name": this.interviewername,
    "candidate_name": this.candidatename

  };
  this.interAssistService.createuser(payload).subscribe(
    (response:any) => {

      if(response){
    this.loaderservice.hide();
    this.toastservice.showSuccess(response?.message);
    this.uploadAllCVs()
    this.getlist();
      }
  },
  (error) => {
    this.loaderservice.hide();
    this.toastservice.showError('User Creation Failed');
  });
}

Navigate(id:any,status:any){

// if(status === 'Upload CV/JD'){
//   this.existingId = id;
//   document.getElementById('cvjd')?.click();
// }
// if(status === 'QnA'){
//   this.existingId = id;
//   this.router.navigate(['interview-assist/'+id+'/evaluate']);
// //(['bgc/' + id, 'upload']);
// }
// if(status === 'report analysis'){
//   this.existingId = id;
//   this.router.navigate(['interview-assist/'+id+'/transcript-manager']);

// }
// else{
  this.router.navigate(['interview-assist/'+status+'/'+id+'/IntelliQ&A']);
// }

}


downloadreport(reqid:any,id:any) {
this.loaderservice.show();
const formData = new FormData();
formData.append('id', id);

  this.interAssistService.getreport(formData).subscribe(
    (response: Blob) => {
     this.loaderservice.hide();
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      // Assuming process_name is a variable containing the process name
      const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' });
      // const fileNameParts = this.fileData?.name.split('_');
      const processName = reqid+'_'+id; // Assuming process name is the second part

      const filename = `Report_${processName}_${timestamp}.docx`;

      // Set the filename for the download
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      this.toastservice.showSuccess('File Downloaded Successfully');
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    }
  );
  }







generateAndDownloadReport(reportData: any) {
    // Construct HTML content for the report
     // Function to replace newlines with HTML line breaks

     const htmlContent = `
         <html>
             <head>
                 <style>
                     body { font-family: Arial, sans-serif; }
                     h2 { color: #00aecf; margin-top: 12px; }
                     p { margin: 4px; white-space: pre-wrap; } // Ensure spaces and formatting are retained
                 </style>
             </head>
             <body>
                 <h1 style="color: #00aecf; text-align: center;">Interview Evaluation Report</h1>
                 <h2>Candidate Score Card:</h2>
                 <p>${this.formatText(reportData["Candidate’s Score Card:"])}</p>
                 <h2>Feedback of the Candidate:</h2>
                 <p>${this.formatText(reportData["Feedback of the Candidate:"])}</p>
                 <h2>Feedback of the Interviewer:</h2>
                 <p>${this.formatText(reportData["Feedback of the Interviewer:"])}</p>
                 <h2>Overall Summary:</h2>
                 <p>${this.formatText(reportData["Overall Summary:"])}</p>
                 <h2>Review As Per the Job Profile or Requirement:</h2>
                 <p>${this.formatText(reportData["Review As Per the Job Profile or Requirement:"])}</p>
             </body>
         </html>
     `;
const content = htmlContent;
if (content) {
  HtmlDocx.asBlob(content).then((converted: Blob | Buffer) => { // Accept both Blob and Buffer
    if (converted instanceof Blob) {
      FileSaver.saveAs(converted, 'report.docx');
    } else {
      // Handle the case where converted is a Buffer
      // For example, you might need to convert it to a Blob first
      const blob = new Blob([converted], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
      FileSaver.saveAs(blob, 'report.docx');
    }
  });
}
}

formatText(text: string): string {
  // First, normalize newlines and replace them with <br> for initial split
  text = text.replace(/\n/g, '<br>');

  // Split the text into lines
  const lines = text.split('<br>');
  const transformedLines = [];
  let isListActive = false; // Track whether we're currently in a list

  lines.forEach(line => {
      // Check if the line should be a bullet point
      if (line.trim().startsWith('- ')) {
          if (!isListActive) {
              transformedLines.push('<ul>'); // Start a new list
              isListActive = true;
          }
          // Add the line as a list item, removing the "- " marker
          transformedLines.push(`<li>${line.trim().substring(2)}</li>`);
      } else {
          if (isListActive) {
              transformedLines.push('</ul>'); // Close the list
              isListActive = false;
          }
          // Add normal line
          transformedLines.push(`<p>${line}</p>`);
      }
  });

  // If the list was not closed, close it
  if (isListActive) {
      transformedLines.push('</ul>');
  }

  return transformedLines.join(''); // Join all transformed lines back into a single string
}





onFileSelected(event: any, fileType: 'CV' | 'JD'): void {
  const file = event.target.files[0];
  if (file) {
    this.files.push({
      file: file,
      type: fileType,
      format: file.name.split('.').pop()
    });
  }
}

uploadAllCVs(): void {
  this.loaderservice.show();
  const formData = new FormData();
  this.files.filter((f: { type: string; }) => f.type === 'CV').forEach((cv: { file: Blob; }) => {
    formData.append('cv_file', cv.file);
  });

  this.files.filter((f: { type: string; }) => f.type === 'JD').forEach((jd: { file: Blob; }) => {
    formData.append('jd_file', jd.file);
  });

  formData.append('id', this.tabid); // Append the unique id to the form data

  this.interAssistService.uploadcvjd(formData).subscribe
  (
    (response:any) => {

      if(response.status ==  'success'){
    this.loaderservice.hide();
    this.toastservice.showSuccess(response.message);
      }
  },
  (error: { message: string; }) => {
    this.loaderservice.hide();
    this.toastservice.showError(error.message);
  }


  );
}

removeFile(index: number): void {
  this.files.splice(index, 1);
}

displayedFiles(): FileDetail[] {
  return this.displayType === 'All' ? this.files : this.files.filter(file => file.type === this.displayType);
}










}
