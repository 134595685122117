<!-- <app-sop-sidenav></app-sop-sidenav> -->


<div class="background-container" >
  <ng-container *ngIf="!chatPayload">
    <div class="dropdown-container" *ngIf="functionList.length > 0">

      <div class="dropdown">
        <label for="converse_function_dropdown">Select Function</label><br>
        <select id="converse_function_dropdown" [(ngModel)]="functionSelected">
          <option *ngFor="let function of functionList" [value]="function?.value">{{function?.name}}</option>
        </select>
      </div>

      <div class="dropdown">
        <label for="converse_language_dropdown">Select Languague</label><br>
        <select id="converse_language_dropdown" [(ngModel)]="languageSelected">
          <option *ngFor="let language of languageArray" [value]="language">{{language}}</option>
        </select>
      </div>
      <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="getFunctionParameters()">Submit</button>
    </div>

  </ng-container>


  <div class="" *ngIf="chatPayload && functionSelected === 'GKnowledge'" >
    <!-- <app-chat-layout [functionList]="functionList" [methodSelected]="chatPayload" [chatData]="chatData" [isReset]="resetChatStatus" (apiCall)="getResponse($event)"></app-chat-layout> -->
    <app-newchat-layout [functionList]="functionList" [methodSelected]="chatPayload" [chatData]="chatData" [isReset]="resetChatStatus" (apiCall)="getResponse($event)"></app-newchat-layout>
  </div>

  <div class="" *ngIf="chatPayload && functionSelected != 'GKnowledge'" >
    <app-chat-layout [functionList]="functionList" [methodSelected]="chatPayload" [chatData]="chatData" [isReset]="resetChatStatus" (apiCall)="getResponse($event)"></app-chat-layout>
    <!-- <app-newchat-layout [functionList]="functionList" [methodSelected]="chatPayload" [chatData]="chatData" [isReset]="resetChatStatus" (apiCall)="getResponse($event)"></app-newchat-layout> -->
  </div>

  <div class="container" *ngIf="!chatPayload && functionList.length > 0">
    <div class="p-5 text-muted text-center">
      <h5>Welcome to the AI Assistant! I'm here to provide quick
        and accurate answers to your selected function questions.
         How can I assist you today?
        </h5>
    </div>
  </div>

  <div class="container" *ngIf="functionList.length == 0">
    <div class="p-5 text-muted text-center">
      <h5>
        Sorry, you are not authorized to access  AI Assistant!.
        </h5>
    </div>
  </div>

</div>

<button type="button" class="btn btn-primary d-none" id="soc_report_policy_model"(click)="openModal(soc_report_policy_model)" >
</button>

<!-- policy & condition block -->
<ng-template #soc_report_policy_model>
  <div class="modal-body">
    <div class="row">
      <div class="col-12 fw-bold text-primary">Disclaimer:
      </div>
      <div class="row">
        <div class="col-12" style="font-size: 12px;">
        <ol style="list-style: decimal;">
        <li>
          The responses generated by GenAI, are based on patterns and data it has been trained on. While efforts have been made to provide accurate information, there is a possibility of inaccuracy.
        </li>
        <li>
          The responses should not be considered as professional advice. Cross-check the information with reliable sources or consult a human expert.
        </li>
        <li>
         Use the information at your own discretion and avoid uploading unnecessary or sensitive information for privacy and security reasons.
        </li>
        </ol></div>
      </div>


    <div class="">
      <div class="">
        <input id="soc_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
        <label class="form-check-label text-decoration-underline" style="font-size: 9px!important;
        margin: 0;
        font-family: georgia;
        color: #0D6EFD;
        padding-left: 20px;"
          for="soc_check_label">
          I acknowledge that I have read, understand and agree to above instructions.
        </label>
      </div>

    </div>

  </div>
  <div class="modal-footer">
    <button type="button" style="border-radius:0px" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
  </div>
</ng-template>
