

<div class="row flex-nowrap " id="toside">
  <div class="col-1 d-flex justify-content-center">
    <div id="mySidenav" class="sidenav" style="background-color: rgb(4, 16, 31) ">

      <div class="close-button" (click)="clos()">
        <span class="close-icon">&times;</span>
      </div>

      <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start p-2" id="menu">



        <!-- <li class="w-100">
          <a [routerLink]="['/konverse']" (click)="clos()" routerLinkActive="active" class="nav-link px-0 other-text" title="Konverse"
            style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text" style="font-size: 14px;"><i class="imgi2"
                class="icon bi bi-cpu"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px; margin-right: 5px;"></i>
                digital assistants </span>
          </a>
        </li> -->

        <!-- <li class="w-100">
          <a [routerLink]="['/sop-previousresponses']" (click)="clos()"  routerLinkActive="active" class="nav-link px-0 other-text"
            title="history" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-database-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px;margin-right: 5px;"></i> Previous
              responses</span>
          </a>
        </li> -->

        <li class="w-100" *ngIf="selectedRole == 'Admin'">
          <a [routerLink]="['/admin/user-role']" (click)="clos()" routerLinkActive="active" class="nav-link px-0 other-text"
            title="history" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-database-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px;margin-right: 5px;"></i> User Management</span>
          </a>
        </li>

        <li class="w-100" *ngIf="selectedRole == 'Admin'">
          <a [routerLink]="['/sop-file-upload']" (click)="clos()" routerLinkActive="active" class="nav-link px-0 other-text"
            title="history" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-database-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px;margin-right: 5px;"></i> File Management</span>
          </a>
        </li>
        <!-- <li class="w-100">
          <a [routerLink]="['/codeGenYLiteAI']" (click)="clos()" routerLinkActive="active" class="nav-link px-0 other-text"
            title="history" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-database-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px;margin-right: 5px;"></i>codeGenYLiteAI</span>
          </a>
        </li> -->

        <li class="w-100">
          <a [routerLink]="['/codeGenYLite']" (click)="clos()" routerLinkActive="active" class="nav-link px-0 other-text"
            title="history" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-database-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px;margin-right: 5px;"></i>codeGenYLite</span>
          </a>
        </li>

        <li class="w-100" *ngIf="selectedRole == 'Admin'">
          <a [routerLink]="['/sop-upload']" (click)="clos()"  routerLinkActive="active" class="nav-link px-0 other-text"
            title="sop creator" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text" style="font-size: 14px;"><i class="imgi2"
                class="icon bi-file-text-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px; margin-right: 5px;"></i> SOP
              creator</span>
          </a>
        </li>
        <!-- <li class="w-100">
          <a [routerLink]="['/tcg']" (click)="clos()"  routerLinkActive="active" class="nav-link px-0 other-text" title="home"
            style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-info-circle"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 18px; margin-right: 5px;"></i>
              Test case generator</span>
          </a>
        </li> -->

        <!-- <li class="w-100">
          <a [routerLink]="['/soc-report']" (click)="clos()"  routerLinkActive="active" class="nav-link px-0 other-text"
            title="soc report" style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text" style="font-size: 14px;"><i class="imgi2"
                class="icon bi-file-text-fill"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 15px; margin-right: 5px;"></i> SOC
              Report</span>
          </a>
        </li> -->
<!--
        <li class="w-100">
          <a href="assets/table.xlsx" routerLinkActive="active" class="nav-link px-0 other-text" title="faq"
            style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text" style="font-size: 14px;"><i class="imgi"
                class="bi bi-question-circle"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 16px; margin-right: 8px;"></i>
              FAQ</span>
          </a>
        </li> -->
        <!-- <li class="w-100">
          <a [routerLink]="['/sop-tool']" (click)="clos()"  routerLinkActive="active" class="nav-link px-0 other-text" title="home"
            style="cursor: pointer">
            <span class="d-none d-sm-inline genpact-text " style="font-size: 14px;"><i class="imgi2"
                class="icon bi-info-circle"
                style="color: rgb(245, 235, 235); font-size: 20px; margin-left: 18px; margin-right: 5px;"></i>
              About</span>
          </a>
        </li> -->
      </ul>

    </div>
  </div>
</div>
<!--Navbar-->
<div class="col-12 genpact-color ">
  <div class="row" style="padding: .76rem 0;">
    <div class="col-5">
      <div class="nav-elements">
        <button class="navbar-toggler hamburger-button" type="button" data-toggle="collapse" aria-expanded="false"
          aria-label="Toggle navigation" (click)="Navi()" style="z-index: 2">
          <div class="animated-icon" style="height: 20px;">
            <span></span><span></span><span></span>
          </div>
        </button>
        <div class="collapsed-menu">
          <!-- Close icon within the menu -->
          <span class="close-icon"></span>
        </div>
        <img [routerLink]="['/konverse']" style="height: 32px; cursor: pointer;"
          src="assets/images/glogo.png" />
      </div>
    </div>
    <div class="col-7">
      <table style="width: 100%; float: right;">
        <tr>
          <td colspan="8">
            <h5 class="title" style="margin-left: 10px;">{{title}}</h5>

          </td>
        </tr>
      </table>
      <div class="" *ngIf="!testcase">
         <span class="text-light username-label">{{username}}</span>
        <app-profile-dropdown></app-profile-dropdown>
      </div>

    </div>
  </div>
  <!-- Collapse button -->
  <!-- Navbar brand -->
</div>
