<div class="header">
  <div class="container" style="width: 80vw;">
      <div class="inner-header">
          <div class="left-sub-header" [ngStyle]="{'background-color': isHomePage() ? 'white' : 'transparent'}">
              <img src="../../../../assets/images/devflowLogo.png" alt="logo" (click)="goToHome()">
            </div>
            <div class="middle-sub-header">
               <!-- <ul class="menu">
                <li class="sub-menu" [routerLink]="['/codeGenYLite/products']" routerLinkActive="active" (click) = "productPage()" >Products</li>
                <li class="sub-menu" (click)="navigateTo('feature-container')">Features</li> -->
                <!-- <li class="sub-menu" [routerLink]="['/home-main/features']" routerLinkActive="active">Features</li> -->
                <!-- <li class="sub-menu"  (click)="opencontactus()">Contact us</li>
               </ul> -->
            </div>
            <div class="right-sub-header">
              <!-- <div class="get-started">
                  <button class="btn get-started-btn">Get started</button>
              </div> -->
              <div class="user-setting">
                  <button class="user-setting-btn" [matMenuTriggerFor]="menu"><img class="userimage" src="../../../../assets/images/userProfile.png"> <p class="user-name">Hi {{username}}</p></button>
                  <mat-menu #menu="matMenu" class="background-color">
                      <!-- <li class="list-item item-first" (click)="openSetting()"><i class="fa-solid fa-gear"></i> Settings</li> -->
                      <li class="list-item item-first"><a class="nav-link" routerLink="/codeGenYLite" routerLinkActive="active">codeGenYLite</a></li>
                      <li class="list-item item-first" (click)="openDialog()"><img class="img-icon" src="../../../../../assets/privacyicon.png">Privacy Policy</li>
                      <li class="list-item" (click)="exitApp()"><i class="fa-solid fa-right-from-bracket"></i>Exit application</li>
                  </mat-menu>
              </div>
          </div>
      </div>
  </div>
</div>
