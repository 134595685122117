import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit, OnChanges {
  ngOnInit(): void {
      //throw new Error('Method not implemented.');
  }

  @Input() value!: string;
  @Output() enteredValue = new EventEmitter<string>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.value = this.value;
    }
  }

  onChange() {
    this.enteredValue.emit(this.value);
  }

}
