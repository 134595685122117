import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class SharepointService {
  sharepointApi = environment.apiURL ;

  constructor(private http: HttpClient) { }

  getSites(formData: FormData): Observable<any> {
    return this.http.post(`${this.sharepointApi}/sharepoint/get-available-sites`, formData);
  }

  getDrives(formData: FormData): Observable<any> {
    return this.http.post(`${this.sharepointApi}/sharepoint/get-available-drives`, formData);
  }

  getStatus(formData: FormData): Observable<any> {
    return this.http.post(`${this.sharepointApi}/sharepoint/get-status`, formData);
  }

  sharepointUpload(formData: FormData): Observable<any> {
    return this.http.post(`${this.sharepointApi}/sharepoint/sharepoint-upload`, formData);
  }


}
