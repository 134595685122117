import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { SopService } from '../shared/service/sop.service';
import { DownloadPdfService } from '../shared/service/download-pdf.service';
import { Subscription } from 'rxjs';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-sop-previousresponses',
  templateUrl: './sop-previousresponses.component.html',
  styleUrls: ['./sop-previousresponses.component.scss']
})
export class SopPreviousresponsesComponent {
  @Input() chatMessages: any[] = [];
  @Input() chatVisible: boolean = false;
  // isrecord: boolean = true;
  showLoading: boolean = false;
  functionList: any = [];
  functionSelected: any;
  chatPayload: any;
  subscription!: Subscription;
  chatSubscriber!: Subscription;
  constructor(

    private sopService: SopService,
    private downloadPdfService : DownloadPdfService,
    private toastMessage: ToastMessageService,
  ) { 
    // this.gethistory(); 
    
  }

  toggleLoading() {
    this.showLoading = !this.showLoading;
  }
  
  ngOnInit(): void {
    this.getUserRole();
    this.getHistory();
  }

  getFunctionParameters() {
    this.chatPayload = null;
    setTimeout(() => {
      this.chatPayload = this.functionList.find((ele: any) => ele?.value == this.functionSelected);
    }, 0);
  }

  getUserRole() {
    this.subscription = this.sopService.activeUserRole.subscribe(
      (res: any) => {
        // console.log(res)
        if (res?.all_roles) {
          this.functionList = res['all_roles'] || [];

         if(res['all_roles']?.length) this.functionSelected = res['all_roles'][0]['value'];
          this.chatPayload = null;
        }
      });
  }
  getOHR() {

    let userData = localStorage.getItem('okta-token-storage');

    if (userData) {

      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;

      if (ohr_id) {

        return ohr_id;

      }
      else {
        return null;
      }

    }

  }


  // gethistory() {
  //   this.toggleLoading();
  //   this.sopService.getUserHistory().
  //     subscribe(
  //       (response: any) => {
  //         // this.isrecord = false;
  //         this.toggleLoading();
  //         // console.log(this.functionSelected);
  //         // console.log(response);
  //         if (response?.response?.status == 'success') {
  //           // Filter chatMessages based on functionSelected
  //           this.chatMessages = response.response.data.filter((res: any) => {
  //             return this.functionSelected == res.function_type;
  //           });
  //         }
  //         console.log(this.chatMessages);
  //       });
  //     }

  getHistory() {
    this.toggleLoading();
    // console.log(this.functionSelected);
    let data = {
      "function_type": this.functionSelected,
      limit:99999
    }
    this.sopService.getBotHistory(data).subscribe(
      (res: any) => {
        this.toggleLoading();
         console.log(this.functionSelected);
        if (res?.response?.status == "success") {
          const mappedData =  res?.response?.data;
          this.chatMessages = mappedData || [];

        } else {
          this.toastMessage.showError(res?.response?.message);
        }


      },
      (error) => {
        this.toastMessage.showError(error?.message);

      }
    )
  }
      onFunctionSelected()
      {
        this.getHistory();
      }


  
  //download
  downloadAsPdf() {
    this.downloadPdfService.downloadAsPdf("text-d",'downloaded-file.pdf');
}

}
