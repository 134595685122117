import { Component, Inject,ViewChild,ElementRef  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import { Constants } from 'src/app/shared/Utils/Constants';
import { ChatService, Message } from 'src/app/shared/service/chat.service';
var commonServiceObj = new CommonService();

@Component({
  selector: 'app-preview-or-ask-question',
  templateUrl: './preview-or-ask-question.component.html',
  styleUrls: ['./preview-or-ask-question.component.css']
})
export class PreviewOrAskQuestionComponent {
  @ViewChild('messageContainer') messageContainer!: ElementRef;

  flgDisabled:boolean=true;
  filelink:any;
  textField:any;
  Constants: any;
  messages: Message[] = [];
  // questionClass:boolean = false;
  // answerClass:boolean = false;
  showGeneratingAnswer: boolean = false;

  constructor( public dialogRef: MatDialogRef<PreviewOrAskQuestionComponent>,public chatService: ChatService,
    @Inject(MAT_DIALOG_DATA) public data: {
      sessionURL : string;
      video: boolean;
      timestamp:any,
      source: string,
      fileNAME : string;
      collectionName: string;
    }){
    this.Constants = Constants;
  }
  ngOnInit() {
    this.filelink = this.data.sessionURL;

    this.chatService.conversation.subscribe((val) => {
    this.messages = this.messages.concat(val);
  });

}
  closePopup(): void {
    this.dialogRef.close();
  }
  changeInTextField(fieldtext:any){
    if(fieldtext.trim() !== ''){
      this.flgDisabled = false;
    }
    else{this.flgDisabled = true;}
  }
  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }
  async sendMessage(event: any){
    if(this.textField.trim() !== ''){
      this.showGeneratingAnswer = true;
      setTimeout(() => {this.textField = '';}, 100);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
      let consts = await this.chatService.getBotAnswer(this.textField,this.data.collectionName ,this.data.fileNAME);
      setTimeout(() => {
        this.scrollToBottom();
      }, 100);
      this.showGeneratingAnswer = false;
      // this.textField = '';
      this.flgDisabled = true;
    }
  }
}
