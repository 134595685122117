<div class="mobile-demo">
  <div class="mobile-screen">
    <!-- Chat header with profile picture, user name, and online status -->
    <div class="chat-header">
      <img class="profile-icon-header" src="assets/glogo.png" alt="User Profile">
      <div class="user-info">
        <div class="user-name" style="color:#ffffff;">Genpact</div>
        <!-- <div class="user-status">Online</div> -->
      </div>
    </div>
    <div class="chat-container">
      <div *ngFor="let message of messages" class="message-wrapper">
        <img class="profile-icon" [src]="getProfilePicture(message.sender)" alt="{{ message.sender }}">
        <div class="message" [ngClass]="getMessageClass(message.sender)">
          <div class="message-content">
            {{ message.text }}
          </div>
        </div>
      </div>
    </div>
    <!-- Fake input box -->
    <div class="fake-input-box">
      <input type="text" placeholder="Type a message..." disabled>
      <button disabled>Send</button>
    </div>
  </div>
</div>
