<!-- feedback.component.html -->

<div class="container">
  <h1>Interview Assist Feedback</h1>
  <form (ngSubmit)="submitFeedback()" #feedbackForm="ngForm">
    <div>
      <label for="name">Name:</label>
      <input type="text" id="name" name="name" ngModel required>
    </div>
    <div>
      <label for="email">Email:</label>
      <input type="email" id="email" name="email" ngModel required>
    </div>
    <div>
      <label for="feedback">Feedback:</label>
      <textarea id="feedback" name="feedback" ngModel required></textarea>
    </div>
    <div class="satisfaction-icons">
      <span class="satisfaction-icon" (click)="selectSatisfaction('😡')" [class.selected]="selectedSatisfaction === '😡'" title="Very Dissatisfied">😡</span>
      <span class="satisfaction-icon" (click)="selectSatisfaction('😕')" [class.selected]="selectedSatisfaction === '😕'" title="Dissatisfied">😕</span>
      <span class="satisfaction-icon" (click)="selectSatisfaction('😐')" [class.selected]="selectedSatisfaction === '😐'" title="Neutral">😐</span>
      <span class="satisfaction-icon" (click)="selectSatisfaction('😊')" [class.selected]="selectedSatisfaction === '😊'" title="Satisfied">😊</span>
      <span class="satisfaction-icon" (click)="selectSatisfaction('😄')" [class.selected]="selectedSatisfaction === '😄'" title="Very Satisfied">😄</span>
    </div>
    <div class="satisfaction-text">
      <span *ngIf="selectedSatisfaction === '😡'" class="very-dissatisfied">Very Dissatisfied</span>
      <span *ngIf="selectedSatisfaction === '😕'" class="dissatisfied">Dissatisfied</span>
      <span *ngIf="selectedSatisfaction === '😐'" class="neutral">Neutral</span>
      <span *ngIf="selectedSatisfaction === '😊'" class="satisfied">Satisfied</span>
      <span *ngIf="selectedSatisfaction === '😄'" class="very-satisfied">Very Satisfied</span>
    </div>
    <button type="submit" [disabled]="!feedbackForm.valid">Submit Feedback</button>
  </form>
  <br>
</div>
