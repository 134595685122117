<section class="content">
  <div class="container-fluid responsive">
    <div class="container">

      <div class="row flex-nowrap">
        <main id="main">
          <div class="row my-3">
            <div class="col-12" style="text-align: center;">
              <h4>
                <link rel="stylesheet"
                  href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
                  integrity="sha384-..." crossorigin="anonymous">

                Manage users
              </h4>
            </div>
          </div>

          <div class="row py-3">
            <div class="col-md-12">
              <div class="selectlang" align="center">
                <ng-container *ngIf="functionList.length > 0">

                  <!-- Select Function -->
                  <!-- Select Function -->
                  <select class="i" tooltip="Select the function type" id="converse_function_dropdown"
                    name="functionname" title="Select Function" [(ngModel)]="functionSelected">
                    <option *ngFor="let function of functionList" [value]="function?.value">
                      {{function?.name}}
                    </option>
                  </select>

                  <button class="bg-primary text-light p-2 px-4 border border-r1" tooltip="select to get list of users"
                    title="User List" (click)="getuserlist(functionSelected)"> <svg xmlns="http://www.w3.org/2000/svg"
                      width="21" height="21" fill="currentColor" class="bi bi-person-down" viewBox="0 0 16 16">
                      <path
                        d="M12.5 9a3.5 3.5 0 1 1 0 7 3.5 3.5 0 0 1 0-7m.354 5.854 1.5-1.5a.5.5 0 0 0-.708-.708l-.646.647V10.5a.5.5 0 0 0-1 0v2.793l-.646-.647a.5.5 0 0 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0ZM11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
                      <path
                        d="M8.256 14a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1z" />
                    </svg> </button>



                  <!-- <button class="bg-primary text-light p-2 px-4 border border-r1"
                                          (click)="translateContent()"> Translate </button> -->

                </ng-container>

              </div>

            </div>
          </div>
          <div class="row">
            <div class="col-md-6 m-auto">
              <div class="input-group mb-3">
                <input type="text" class="form-control rounded-0" [(ngModel)]="userohr" placeholder="User OHR_ID"
                  aria-label="User OHR-ID" aria-describedby="basic-addon2">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary rounded-0 border" title="Add user" data-toggle="modal"
                    data-target="#updatemodal" type="button"><svg xmlns="http://www.w3.org/2000/svg" width="20"
                      height="20" fill="currentColor" class="bi bi-person-fill-add" viewBox="0 0 16 16">
                      <path
                        d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                      <path
                        d="M2 13c0 1 1 1 1 1h5.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.544-3.393C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4" />
                    </svg></button>
                </div>
              </div>
            </div>
          </div>

          <div id="text-d" class="row" *ngIf="dupload">
            <div class="container mt-4">
              <div class="overflow-auto">
                <div style="height: 400px;">
                  <table class="table table-striped table-hover">
                    <!-- <thead>
          <tr>
            <input type="text" placeholder="Search ID" name="search-id"/>

          </tr>
        </thead> -->
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>OHR-ID</th>
                        <!-- <th>Assign-ID</th> -->
                        <th>Function</th>
                        <th>Role</th>
                        <!-- <th>change Role</th> -->
                        <th>Action</th>
                        <th>Active</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr *ngFor="let fileName of doclist;let i = index">
                        <td>{{ i + 1 }}</td>
                        <td>{{fileName?.user_id }}</td>
                        <!-- <td>{{fileName?.id}}</td> -->
                        <td>{{functionSelected}}</td>
                        <td>{{getRoleName(fileName?.role_id)}}</td>


                        <!-- <td></td> -->
                        <td>
                          <button title="update" (click)="updateuser(fileName)" class="btn btn-dark rounded-0"
                            type="button"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                              fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
                              <path
                                d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                              <path fill-rule="evenodd"
                                d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z" />
                            </svg></button>
                          <button #hiddenButton style="display: none;" data-toggle="modal"
                            data-target="#Modalrole"></button>                          
                        </td>
                        <td>
                          <div class="custom-control custom-switch">
                            <input title="switch" [(ngModel)]="!fileName.isdeleted" type="checkbox"
                              class="custom-control-input" id="toggleSwitch_{{i}}"
                              (change)="toggleSwitchChange(fileName)" (change)="onSubmit2(fileName?.id,fileName?.isdeleted,fileName?.user_id)">
                            <label class="custom-control-label" for="toggleSwitch_{{i}}"></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="row pt-3" *ngIf="!dupload">
            <div class="col-12  py-5 text-center border" style="    background: whitesmoke;">
              Select the Functional bot and click submit button to get user list!
            </div>
          </div>

          <!-- Button trigger modal -->
          <!-- <button type="button" title="remove" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
    </svg>
</button> -->

          <!-- Modal -->
          <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Are you sure?</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Remove the user from the list?
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary">Save changes</button>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal role -->
          <div #modal class="modal fade" id="Modalrole" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
            aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ModalroleLabel">Update User</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label for="roleDropdown">Role: &nbsp; </label>
                  <select id="roleDropdown1" [(ngModel)]="selectedRole">
                    <option value=1>User</option>
                    <option value=0>Admin</option>
                  </select>
                </div>
                <div class="modal-body">
                  <h6>OHR:</h6>
                  <div> <input class="rounded-0" style="display: none;" [(ngModel)]="ohr" [title]="ohr" [name]="ohr">
                    {{selectedOhrId}}
                  </div>
                </div>
                <div class="modal-body">
                  <h6>Function Type:</h6>
                  <div>
                    <input class="rounded-0" style="display: none;" [(ngModel)]="fnname" [title]="fnname"
                      [name]="fnname">
                    {{ functionSelected }}
                  </div>

                  <!-- <div style="display: none;">
  <input style="display: none;" [(ngModel)]="isDelete" [title]="isDelete" >
  {{ isDeleted }}</div> -->
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary rounded-0" id="close-button"
                    data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary rounded-0"
                    (click)="onSubmit(selectedOhrId)">Submit</button>
                </div>
              </div>
            </div>
          </div>

          <!-- Modal role -->
          <!-- update modal -->
          <div #modal class="modal fade" id="updatemodal" tabindex="-1" role="dialog"
            aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="ModalroleLabel">Add User</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <label for="roleDropdown">
                    <h6>Role:</h6>
                  </label><br>
                  <select id="roleDropdown" [(ngModel)]="selectedRole">
                    <option value=1>User</option>
                    <option value=0>Admin</option>
                  </select>
                </div>
                <div class="modal-body">
                  <h6>OHR:</h6>
                  <p>
                    <input type="text" class="form-control rounded-0" [(ngModel)]="userohr" placeholder="User OHR_ID"
                      aria-label="User OHR-ID" aria-describedby="basic-addon2">
                  </p>
                </div>
                <div class="modal-body">
                  <h6>Function value:</h6>
                  <p>{{functionSelected}}</p>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary rounded-0" id="close-button1"
                    data-dismiss="modal">Close</button>
                  <button type="button" class="btn btn-primary rounded-0" (click)="createuser()">Submit</button>
                </div>
              </div>
            </div>
          </div>
          <!-- update modal -->

        </main>
      </div>
    </div>
  </div>
</section>
