

<!-- <app-sop-sidenav></app-sop-sidenav> -->


<div class="background-container">

  <img src="assets/background.png" alt="Background Image" class="background-image">
  <!-- <div class="dropdown-container">
    <div class="dropdown">
        <label for="dropdown1">Select Country</label><br>
        <select id="dropdown1" [(ngModel)]="selectedOption1">
            <option value="option1">NTT_Australia</option>
            <option value="option2">NTT_Europe_Germany</option>

        </select>
    </div>

    <div class="dropdown">
        <label for="dropdown2">Select Process</label><br>
        <select id="dropdown2" [(ngModel)]="selectedOption2">
            <option value="option1">S2P</option>

        </select>
    </div>

    <div class="dropdown">
        <label for="dropdown3">Select Language</label><br>
        <select id="dropdown3" [(ngModel)]="selectedOption3">
            <option value="option1">English</option>
            <option value="option2">Non-English</option>

        </select>
    </div>
    <button [disabled]="!areOptionsSelected()" [class.disabled]="!areOptionsSelected()" (click)="submitOptions()">Submit</button> -->
<!-- </div> -->
<div class="container"  >
  <div class="header">
    <div class="title" style="text-align: center; background-color:rgba(128, 128, 128, 0);"><b>SOP Chat</b></div>

<div class="chat-container" >

            <!-- <div class="close" (click)="closeChat()">X</div> -->
        <div class="message" *ngFor="let message of chatMessages">
            <div class="user" *ngIf="message.user === 'user'">{{ message.text }}</div>
            <div class="bot" *ngIf="message.user === 'bot'">{{ message.text }}</div>
        </div>
</div>
        <div class="input-box">
            <textarea [(ngModel)]="userMessage" (keyup.enter)="sendMessage()" placeholder="Type your message..." ></textarea>
            <button class="inp" (click)="sendMessage()"><svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
            </svg></button>
        </div>
        </div></div>
</div>
