<div class="chat-box-new ">
    <div class="row">
        <div class="col-12">
            <div class="row-x">

                <div class="col function-menu">
                    <!-- *ngIf="methodSelected?.name=='GKnowledge'" -->
                    <div class="row menu-box">
                        <div class="col-12 p-0 d-grid">
                            <div class="listed-list">

                                <div class="listed-list">
                                    <h5 class="function-heading">
                                        Your Recent Questions
                                        <i class="fa fa-info-circle" title="View your last 3 questions for this skill"
                                            aria-hidden="true"></i>
                                    </h5>

                                    <div class="col-12 menu-item "
                                        [ngClass]="{'active': function?.name == methodSelected?.name}"
                                        [title]="function?.question" *ngFor="let function of recentQuestion.slice(0, 3)"
                                        (click)="activateChatWindow(function,'Sharepoint')">
                                        {{function?.question }}
                                    </div>

                                    <div class="col-12 menu-item " *ngIf="recentQuestion?.length === 0">
                                        No Questions available
                                    </div>
                                </div>

                                <h5 class="function-heading">
                                    Most Popular Questions
                                    <i class="fa fa-info-circle"
                                        title="Access previously answered questions by experts on Sharepoint"
                                        aria-hidden="true"></i>
                                </h5>

                                <div class="menu-item " [ngClass]="{'active': function?.name == methodSelected?.name}"
                                    [title]="function?.question" *ngFor="let function of popularQuestion"
                                    (click)="activateChatWindow(function,'Sharepoint')">
                                    {{function?.question }}
                                </div>

                                <div class="col-12 menu-item " *ngIf="popularQuestion?.length === 0">
                                    No Questions available
                                </div>
                            </div>

                            <div class="listed-list" *ngIf="methodSelected?.name=='GKnowledge'">
                                <h5 class="function-heading">
                                    Account Related Questions
                                    <i class="fa fa-info-circle"
                                        title="Access previously answered questions by experts on SFDC"
                                        aria-hidden="true"></i>
                                </h5>

                                <div class="menu-item " [ngClass]="{'active': function?.name == methodSelected?.name}"
                                    [title]="function?.question" *ngFor="let function of sfdcQuestion"
                                    (click)="activateChatWindow(function,'SFDC')">
                                    {{function?.question }}
                                </div>

                                <div class="col-12 menu-item " *ngIf="sfdcQuestion?.length === 0">
                                    No Questions available
                                </div>
                            </div>

                            <div class="listed-list" *ngIf="methodSelected?.name=='GKnowledge'">
                                <h5 class="function-heading">
                                    Research Assistant
                                    <i class="fa fa-info-circle"
                                        title="Access previously answered questions by experts on Research Assistant"
                                        aria-hidden="true"></i>
                                </h5>

                                <div class="menu-item " [ngClass]="{'active': function?.name == methodSelected?.name}"
                                    [title]="function?.question" *ngFor="let function of researchQuestion"
                                    (click)="activateChatWindow(function,'internet')">
                                    {{function?.question }}
                                </div>

                                <div class="col-12 menu-item " *ngIf="researchQuestion?.length === 0">
                                    No Questions available
                                </div>
                            </div>
                        </div>

                    </div>
                </div>


                <div class="conversation border" *ngIf="!methodSelected?.name">
                    <div class="col-12 bot-message">
                        Select listed function to start conversation.
                    </div>
                </div>

                <div class="conversation" *ngIf="methodSelected?.name">

                    <!-- Page Heading block  Start -->
                    <div class="chat-box-header genpact-color py-2 mb-n3 cht">
                        <div class="d-block">
                            <div class="back-btn mr-2 text-dark " title="Back to function selection"
                                (click)="apiCall.next({back:true})">
                                <i class="fa fa-chevron-left btn-back-icon" aria-hidden="true"></i> Back
                            </div>

                            <div class="back-btn border p-1 " title="Export chat" (click)="downloadAsPdf()"
                                *ngIf="chatData?.length > 0 &&  (chatModeSwitch) ">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </div>

                            <div class="back-btn border p-1 " title="Export chat" (click)="downloadDocx()"
                                *ngIf="(!chatModeSwitch && meetingNotesData) ">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </div>



                        </div>
                        <div class="block-header">
                            <h4 *ngIf="methodSelected?.name !=='GKnowledge'" class="text-dark chat-head-title"
                                [(ngModel)]="method1"> {{methodSelected?.name}} </h4>
                            <ng-container *ngIf="methodSelected?.name =='GKnowledge'">
                                <h4 class="text-dark chat-head-title" [(ngModel)]="method1"> Sales Assist</h4>
                                <button type="button"
                                    *ngIf="!meetingLoading && (accountList?.length === 0 || buyingCenterList?.length === 0)"
                                    (click)="geAccountList()" title="Meeting Notes"
                                    class="btn btn-primary meeting-btn py-1  btn-sm">
                                    <!-- <i class="fa fa-file-text-o" aria-hidden="true"></i> -->
                                    Prep Meeting
                                </button>
                                <button type="button"
                                    *ngIf="!meetingLoading && (accountList?.length > 0 && buyingCenterList?.length > 0)"
                                    (click)="chatModeSwitch = !chatModeSwitch" title="View Meeting Notes"
                                    class="btn  btn-primary meeting-btn py-1 btn-sm">
                                    {{ chatModeSwitch ? 'View Meeting Notes' : 'Back to Chat' }}
                                    <!-- <i class="fa fa-list-ul" aria-hidden="true"></i> -->
                                </button>
                                <button type="button" *ngIf="meetingLoading" title="Meeting Notes"
                                    class="btn  btn-primary meeting-btn py-1 btn-sm">
                                    Loading <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                </button>
                            </ng-container>

                        </div>
                        <!-- dropdown -->

                    </div>
                    <!-- Page Heading block  End -->

                    <!-- Stop Generation & Regenerate button  start -->
                    <!-- <div class="res_generating" *ngIf="generateResponse">
                        <button (click)="unsubscribeChat()">
                            <span>Stop Generating</span>
                            <i class="fa fa-stop" aria-hidden="true"></i>
                        </button>
                    </div> -->

                    <div class="res_generating" *ngIf="!generateResponse && (chatData?.length > 0)">
                        <button (click)="userChat(true)">
                            <span>Regenerate</span>
                            <i class="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                    </div>
                    <!-- Stop Generation & Regenerate button  end -->

                    <!-- Chat block  start -->
                    <div class="box messages smooth-scroll" id="chat-download-block" #scrollChat>

                        <ng-container *ngIf="!chatModeSwitch">
                            <!-- <ng-container *ngIf="!meetingNotesData">
                                <div class="message-user w-100 account-list" (click)="getMeetingNotes(account)"
                                    *ngFor="let account of accountList; let i = index">
                                    <div class="content">
                                        <div class="user-info">{{(i+1)+' ) '}} {{account}} </div>
                                    </div>
                                </div>
                            </ng-container> -->

                            <ng-container *ngIf="meetingNotesData">
                                <div class="message">
                                    <div class="content">
                                        <button class="markdown-clipboard-button content-copy"
                                            (click)="copyToClipboard(meetingNotesData)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button>

                                        <textarea [value]="meetingNotesData" style="display: none;"></textarea>

                                        <div *ngIf="meetingNotesData" lineNumbers markdown [data]="meetingNotesData"
                                            [disableSanitizer]="true">
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-container *ngIf="chatModeSwitch">
                            <div class="message-user" *ngIf="chatData.length === 0">
                                <div class="content">
                                    <div class="user-info"> Hello {{username}}! 👋 </div>
                                </div>
                            </div>

                            <div class="message-user" *ngIf="chatData.length === 0">
                                <div *ngIf="methodSelected?.name !=='GKnowledge'" class="content">
                                    {{methodSelected?.description}}
                                </div>
                                <div *ngIf="methodSelected?.name =='GKnowledge'" class="content">
                                    Welcome to SalesAssist! I'm here to provide information to help you prepare for your
                                    meeting. How can I assist you today?
                                </div>
                            </div>


                            <div class="message" *ngFor="let chat of chatData;let index = index"
                                [ngClass]="{'message-bot':chat?.role !='user'}">

                                <div class="user" *ngIf="chat?.role =='user';else botTemplate">
                                    <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
                                    <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
                                </div>
                                <ng-template #botTemplate>
                                    <div class="user">
                                        <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
                                        <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                                    </div>
                                </ng-template>


                                <div class="content">
                                    <button *ngIf="chat?.role !='user'" class="markdown-clipboard-button content-copy"
                                        (click)="copyToClipboard(chat?.content)">
                                        <i class="fa fa-clone" aria-hidden="true"></i>
                                    </button>

                                    <textarea [value]="chat?.content" style="display: none;"></textarea>

                                    <div *ngIf="chat?.content &&  chat?.role !='user'" lineNumbers markdown
                                        [data]="chat?.content" [disableSanitizer]="true">
                                    </div>

                                    <div *ngIf="chat?.content && chat?.role =='user'">
                                        {{chat?.content}}
                                    </div>
                                </div>

                            </div>
                            <!--- Processing block-->
                            <div class="message" *ngIf="generateResponse">
                                <div class="user">
                                    <img src="assets/bot.png" alt="GPT Avatar">
                                    <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                                </div>
                                <div class="loading-text">Processing...... </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- Chat block  End -->

                    <!-- Input block  Start -->
                    <div class="user-input col-12" *ngIf="!generateResponse && chatModeSwitch">
                        <div class="box input-box">
                            <textarea autoResize [(ngModel)]="prompt" class="message-input" placeholder="Ask a question"
                                rows="1" #textArea (keyup.enter)="userChat()"></textarea>
                                <button class="btn" id="send-button" (click)="userChat()" [disabled]="prompt.trim() === ''">
                                  <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                      <path fill="#096ad9" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                                  </svg>
                              </button>

                        </div>
                        <div class="col-12 text-center text-muted mb-1" style="font-size: .7rem;">
                            Chatbot are fallable. Examine any pertinent facts.
                        </div>

                      <div class="d-md-flex" style="gap: 1rem;">
                        <div class="form-check" *ngIf="methodSelected?.name=='GKnowledge'">
                            <input [checked]="selectedSource=='internet'" class="form-check-input"
                                (change)="onCheckboxChange($event,'internet')" type="checkbox" value="" id="checkInternet">
                            <label class="form-check-label text-dark" for="checkInternet">
                                Get response from Internet.
                            </label>
                        </div>
                         <div class="form-check" *ngIf="methodSelected?.name=='GKnowledge'">
                            <input [checked]="selectedSource=='SFDC'" class="form-check-input"
                                (change)="onCheckboxChange($event, 'SFDC')" type="checkbox" value="" id="checkSFDC">
                            <label class="form-check-label text-dark" for="checkSFDC">
                                Get response from SFDC.
                            </label>
                        </div>
                      </div>

                    </div>
                    <!-- Input block  End -->

                </div>

            </div>
        </div>
    </div>
</div>



<button type="button" class="d-none" id="prepMeetingtemplate" (click)="openModal(prepMeetingtemplate)"></button>

<ng-template #prepMeetingtemplate>
    <div class="modal-header py-2">
        <h5 class="modal-title text-center col-12 text-muted">Prep Meeting</h5>
        <!-- <button type="button" class="btn-close close pull-right h6" aria-label="Close" (click)="modalRef?.hide()">
            <span aria-hidden="true" class="visually-hidden">&times;</span>
        </button> -->
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-12">
                <p> Please select <b>Account</b> and <b>Buying Center</b></p>
            </div>
            <div class="col-sm-6">
                <label>Account Name </label>
                <ng-multiselect-dropdown [placeholder]="'Select Account'" [settings]="dropdownSettings"
                    [data]="accountList" [(ngModel)]="accountNameSelected" (onSelect)="onItemSelect($event)">
                </ng-multiselect-dropdown>
            </div>
            <div class="col-sm-6">
                <label>Buying Center

                    <i *ngIf="meetingLoading" class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                </label>
                <ng-multiselect-dropdown [disabled]="buyingCenterList?.length == 0" [placeholder]="'Select Center'"
                    [settings]="dropdownSettings" [data]="buyingCenterList" [(ngModel)]="centerSelected">
                </ng-multiselect-dropdown>
            </div>


        </div>
    </div>
    <div class="modal-footer footer-block">
        <div class="col-12  text-right ">
            <button class="btn btn-light border-0 py-1 btn-sm mr-2" (click)="modalRef?.hide()">Cancel</button>
            <button class="btn btn-primary meeting-btn py-1 btn-sm" (click)="getMeetingNotes()">Submit</button>
        </div>
    </div>
</ng-template>
