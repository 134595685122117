import { Injectable } from '@angular/core';
import { SharedService } from './shared.service';
import { BaseHttpService } from './base-http.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiEndpoints } from '../data/global-vars';
import { DevTalkReq } from '../data/app-model';

@Injectable({
  providedIn: 'root'
})
export class DevTalkService {

  constructor(private http: HttpClient,
    private baseHttp: BaseHttpService,
    private sharedService: SharedService) { }

  postUserPrompt(data: DevTalkReq): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.post<any>(ApiEndpoints.GetGptResponse, data, false);
  }

  resetChat(data: string): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.get<any>(ApiEndpoints.ResetChat);
  }
}
