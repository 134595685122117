import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeetingNotesService {
  socApi = environment.apiURL+'/konverse_sfdc';

  constructor(private http: HttpClient) { }

  getAccount() {
    return this.http.get(`${this.socApi}/accounts`);
  }

  getBuyingCenters(payload:any) {
    return this.http.post(`${this.socApi}/buying-centers`,payload);
  }

  getMeetingNotes(paylaod: any): Observable<any> {
    return this.http.post(`${this.socApi}/meeting-notes`, paylaod);
  }

  meetingNotesStatus(): Observable<any> {
    return this.http.get(`${this.socApi}/meeting-notes-status`);
  }


}
