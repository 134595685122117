import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SopService } from '../shared/service/sop.service';



export const AdminRoleGuard: CanActivateFn = async (route, state) => {
  const oauthService: SopService = inject(SopService);
  const router: Router = inject(Router);
  let receivedValue: any;

  // get user role
  await oauthService.activeUserRole.subscribe((value: any) => {
    // Assign the received value to the variable
    receivedValue = value?.name || '';
  });

  if (receivedValue === 'Admin') {
    return true;
  } else {
    router.navigate(['/']);
    return false;
  }

 



};


