<div class="home">
  <div *ngIf="title">
    <app-header></app-header>
  </div>

  <div class="banner-container">
    <p class="tagline">
      Your
      <span style="color: var(--genpact-aqua)">AI enabled</span> Interview Assistant is here for help
    </p>
    <p class="subtagline">
      Transforming Recruitment with an AI-Enabled Interview Assistant.
    </p>
  </div>

  <div class="tab-container">
    <div class="tab-main">
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="setTab(0)">
          <a class="nav-link" [ngClass]="activatedTab === 0 ? 'active' : ''">
            <div class="tab-content">
              <div class="img-section">
                <img src="../../../assets/admin.png" alt="Image 0" class="tab-image" style="width: 26px">
              </div>
              <span class="tab-text">Talent Admin</span>
            </div>
          </a>
        </li>

        <li class="nav-item" (click)="setTab(1)">
          <a class="nav-link" [ngClass]="activatedTab === 1 ? 'active' : ''">
            <div class="tab-content">
              <div class="img-section">
                <img src="../../../assets/answer.png" alt="Image 1" class="tab-image">
              </div>
              <span class="tab-text">Intelli Q&A</span>
            </div>
          </a>
        </li>

        <li class="nav-item" (click)="setTab(2)">
          <a class="nav-link" [ngClass]="activatedTab === 2 ? 'active' : ''">
            <div class="tab-content">
              <div class="img-section">
                <img src="../../../assets/report.png" alt="Image 1" class="tab-image">
              </div>
              <span class="tab-text">AI ReportGen Pro</span>
            </div>
          </a>
        </li>

        <li>
          <button class="tab-btn btn btn-primary" (click)="openEnhanceCode()">
            Let's Begin
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
            </svg>
          </button>
        </li>
      </ul>
    </div>

    <div class="tab-section">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div *ngIf="activatedTab === 0" class="custom-card">
              <p class="card-title">Talent Admin</p>
              <p class="card-content">
                Track and manage your recruitment process with ease. View and manage all your candidates, interviews in one place. Get insights on the recruitment process and optimize your hiring process.
              </p>
            </div>

            <div *ngIf="activatedTab === 1" class="custom-card">
              <p class="card-title">IntelliQ&A</p>
              <p class="card-content">
                Boost interviewers' pre-interview readiness with AI-powered questions and answers customized to the candidate's skills. This solution provides a curated selection of technical, behavioral, and situational questions, enabling interviewers to thoroughly assess candidates' capabilities.
              </p>
            </div>

            <div *ngIf="activatedTab === 2" class="custom-card">
              <p class="card-title">AI ReportGen Pro</p>
              <p class="card-content">
                Get synthesized transcripts with timestamp for your interviews with AI ReportGen Pro. Generate comprehensive reports for evaluating the candidates. Get insights on the candidate's performance and the interview process.
              </p>
            </div>
          </div>

          <div class="col-md-4">
            <div *ngIf="activatedTab === 0" class="custom-card">
              <p class="card-title">Features</p>
              <ul class="list-group">
                <li class="list-group-item">User management</li>
                <li class="list-group-item">Role-based access control</li>
                <li class="list-group-item">New interview process creation</li>
                <li class="list-group-item">Allowing integration with existing application</li>
                <li class="list-group-item">Compliance with data privacy regulations</li>
                <li class="list-group-item">Streamlining workflows</li>
                <li class="list-group-item">Talent search and filtering</li>
              </ul>
            </div>

            <div *ngIf="activatedTab === 1" class="custom-card">
              <p class="card-title">Features</p>
              <ul class="list-group">
                <li class="list-group-item">AI-generated CV and JD summaries</li>
                <li class="list-group-item">Interview Q&A generation</li>
                <li class="list-group-item">Technical skill evaluation</li>
                <li class="list-group-item">Situational judgment</li>
                <li class="list-group-item">Behavioral competency judgment</li>
                <li class="list-group-item">Custom Difficulty Selection</li>
                <li class="list-group-item">Custom skill selection</li>
                <li class="list-group-item">Integration with talent database</li>
              </ul>
            </div>

            <div *ngIf="activatedTab === 2" class="custom-card">
              <p class="card-title">Features</p>
              <ul class="list-group">
                <li class="list-group-item">Speech Synthesis</li>
                <li class="list-group-item">Audio/video transcription</li>
                <li class="list-group-item">Comprehensive reports for candidates</li>
                <li class="list-group-item">Detailed score card</li>
                <li class="list-group-item">Interviewer and candidate's feedback</li>
                <li class="list-group-item">Overall Summary with suitability score</li>
              </ul>
            </div>
          </div>

          <div class="col-md-4">
            <div *ngIf="activatedTab === 0 || activatedTab === 1 || activatedTab === 2" class="custom-card" style="text-align: center">
              <img src="../../../assets/question.png" alt="" style="width: 120px">
              <p class="card-title-fixed">
                ELEVATE YOUR INTERVIEW EXPERIENCE WITH INTERVIEW-ASSIST
              </p>
              <p class="card-content">
                Streamline your hiring process with AI-powered analysis and generate comprehensive reports for various positions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="home-footer">
    <div class="relentless">
      <div class="container">
        <div class="row">
          <div class="col-6">
            <img src="../../../assets/images/genpact-vertical-logo.svg" alt="">
          </div>
          <div class="col-6 relentless-font-style">
            <p>
              The relentless pursuit of a world that works
              <span>better for </span> <span>people</span>
            </p>
            <img src="../../../assets/images/underline-white.svg" alt="" style="width: 206px; margin-left: auto; margin-top: -4.7rem;">
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-footer></app-footer>
</div>
