import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent {

  constructor(public dialogRef: MatDialogRef<ContactusComponent>
  ) { }

  onCloseClick()  : void {
    this.dialogRef.close();
  }

}
