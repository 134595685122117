<div class="container">

  <main>
    <div class="input-area">
      <div class="source-text">
        <textarea id="source-text" placeholder="Enter text to translate"></textarea>
        <select title="a" id="source-language">
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
        </select>
      </div>
      <div class="target-text">
        <textarea id="target-text" disabled placeholder="Translation"></textarea>
        <select title="b" id="target-language">
          <option value="en">English</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
        </select>
      </div>
    </div>
      <button id="translate-button">Translate</button>
      <br>
      <div class="additional-options">
        <button class="microphone-icon"><i class="fas fa-microphone"></i></button>
        <button class="camera-icon"><i class="fas fa-camera"></i></button>
        <input title="c" type="file" class="upload-icon" accept="image/*,application/pdf">
        <button class="switch-languages"><i class="fas fa-exchange-alt"></i></button>
      </div>

    <div class="output-area">
      <p id="translated-text"></p>
    </div>
  </main>

</div>
