import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs'
import { RoundLoaderService } from '../Services/roundloader.service';
import { SharedService } from '../Services/shared.service';
import { ActivityAudit } from '../data/app-model';
import { environment } from '../../../environments/environment';
import { ApiEndpoints } from '../data/global-vars';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  //activityAudit: ActivityAudit = {
  //  ohr_id: '',
  //  time_stamp: '',
  //  module: '',
  //}
  constructor(private loaderService: RoundLoaderService, private sharedService: SharedService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const isLoader = request.headers.get('loading');
    const isLoaderPostResp = request.headers.get('loading-post-resp');
    if (isLoader && isLoader == 'true') {
      this.loaderService.showLoader(); // Display the loading spinner
    }
    


    return next.handle(request).pipe(
      finalize(() => {
        if (isLoader  &&  isLoaderPostResp == 'false') {
          this.loaderService.hideLoader(); // Hide the loading spinner
        }

      })
    );

    //return next.handle(request);
  }
}