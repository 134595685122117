import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, of } from 'rxjs';




@Component({
  selector: 'app-sop-chatbot',
  templateUrl: './sop-chatbot.component.html',
  styleUrls: ['./sop-chatbot.component.scss']
})
export class SopChatbotComponent {
isMenuOpen: boolean = false;
selectedOption1: string = '';
    selectedOption2: string = '';
    selectedOption3: string = '';

    chatVisible: boolean = false;
    userMessage: string = '';
    chatMessages: any[] = [];

    constructor(private http: HttpClient) {}

  ngOnInit(): void {
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen; // Toggle the menu state

  }
  areOptionsSelected(): boolean {
    // Check if at least one option is selected
    return !!this.selectedOption1 && !!this.selectedOption2 && !!this.selectedOption3;
}
submitOptions() {
  if (!this.areOptionsSelected()) {
      // Don't proceed if options are not selected
      return;
  }

  // Handle selected options and prepare for chat interaction
  this.chatVisible = true;
  //this.chatMessages.push({ user: 'user', text: `Selected options: ${this.selectedOption1}, ${this.selectedOption2}, ${this.selectedOption3}` });
}
sendMessage(): void {
  // Handle user messages and bot responses
  const userMessage = this.userMessage.trim();
  if (!userMessage) return;

  this.chatMessages.push({ user: 'user', text: userMessage });
  this.userMessage = '';

  // Prepare the data to be sent in the API request
  const requestData = {
      prompt: userMessage,
      "dummy":"sdf",
      "sentTime":"dsf",
      "ohr":"fadgsdgds",
      "user_dept":"efsfdsfd"
  };

  // Make an API call to your bot here
  this.http.post(`https://corp-fap-eus-dev-tas-hrp-01.azurewebsites.net/api/func-getanswer?prompt=${userMessage}`, requestData)

    .pipe(
      catchError((error) => {
        console.error(error); // Log the error to the console
        return of({ message: "I can't understand. Please try again." }); // Provide a default error response
      })
    )
    .subscribe((response: any) => {
      const botMessage = response.sys_response; // Assuming your bot API returns a 'message' field
      this.chatMessages.push({ user: 'bot', text: botMessage });
    });
}
}
