<!-- <app-top-bar></app-top-bar> -->
<app-header1></app-header1>
<div class="fine-tune-model-container">
  <div class="chat-result-display-section">
    <div #messageContainer class="_react_scroll_to_bottom">
      <div class="chat-messages">
        <!-- <div class="gpt-message">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            Hello, I am the AI-assisted code development assistant.
            <br /><br />
            Now, please tell me the requirements that need to be completed,
            describing them in as much detail as possible.
          </div>
        </div> -->
        <div class="tab-main">
          <div class="tab-container">
            <ul class="nav nav-tabs">
              <li class="nav-item">
                <a>Document Generation</a>
              </li>
              <li class="nav-item">
                <a>API Modification</a>
              </li>
              <li class="nav-item">
                <a>Code Modification</a>
                </li>
            </ul>
          </div>
        </div>

        <div class="user-message" *ngIf="userReq">
          <div class="message-content" [innerHTML]="UserDiv"></div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="questionsArray.length > 0">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <strong>
                In order to better understand the requirements, I also need to
                confirm the following questions:
              </strong>
            </h4>
            <table class="table w-100 table-bordered  pl-5 align-middle">
              <thead>
                <tr>
                  <th scope="col"><strong>Question</strong></th>
                  <th scope="col"><strong>Answer</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let ques of questionsArray; let i = index">
                  <td>
                    <span>{{ ques.question }}</span
                    >{{ ques.reasoning }}
                  </td>
                  <td>
                    <div class="ui fluid icon input">
                      <input
                        type="text"

                        name="clarify_answer"
                        (change)="quesAnsEdit($any($event.target).value, i)"
                        value="{{ ques.answer_sample }}"

                        placeholder="Answer"
                        autocomplete="off"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              class="btn btn-primary"
              (click)="QuesSubmit()"
              style="background-color: #1BA641 !important; color:#ffffff !important;"

            >
              Submit
            </button>
          </div>
        </div>

        <div class="user-message" *ngIf="userQuesReq">
          <div class="message-content">
            <p *ngFor="let arr of UserQuesDiv; let i = index">
              <b class="fw-500">Question {{ i + 1 }}: </b>{{ arr.question
              }}<br />
              <b class="fw-500">Answer:</b> {{ arr.answer_sample }}
            </p>
          </div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="DevReqText != ''">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <strong>
                The requirements document has been generated, and the interface
                document will be generated after confirmation:
              </strong>
            </h4>
            <div
              [innerText]="DevReqText"
              [contentEditable]="DevReqTextEdit"
              id="devReqTxt"
              [ngClass]="DevReqTextEdit == true ? 'devReqTxt' : ''"
            ></div>
            <br />
            <button
              class="ui green button"
              style="background-color: #1BA641 !important; color:#ffffff !important;"
              (click)="DevReqSubmit()"

            >
              Submit
            </button>
            <button

              class="ui blue button"
              style="background-color: #10548C !important; color:#ffffff !important; margin-left: 2px;"
              (click)="DevReqEdit()"

            >
              Edit
            </button>
            <button
              class="ui secondary button"
              style="background-color: #ececec !important; margin-left: 2px;"
              (click)="DownloadUserRequirement()"

            >
              <fa-icon [icon]="faDownload"></fa-icon>
              Download Document
            </button>
          </div>
        </div>

        <div class="user-message" *ngIf="DevReqDataEnable">
          <div class="message-content" [innerHTML]="DevUserReqData"></div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="preReqData != ''">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <h4>
              <b
                >The interface documentation has been generated, and once
                confirmed, the analysis of how to modify the program code will
                begin:
              </b>
            </h4>
            <br />
            <pre
              [innerHTML]="preReqData"
              [contentEditable]="preReqTxtEdit"
              id="preReqTxt"
            ></pre>
            <br /><br />
            <button
              class="ui green button"
              style="background-color: #1BA641 !important; color:#ffffff !important;"
              (click)="DocCnfrm()"

            >
              Submit
            </button>
            <button
              class="ui blue button"
              style="background-color: #10548C !important; color:#ffffff !important; margin-left: 2px;"
              (click)="preReqEdit()"

            >
              Edit
            </button>
          </div>
        </div>

        <div class="user-message" *ngIf="userDocCnfrm">
          <div class="message-content">Confirm Interface Document</div>
          <fa-icon
            class="odnoklassnikicss"
            [icon]="odnoklassniki"
            size="2x"
          ></fa-icon>
        </div>

        <div class="gpt-message" *ngIf="this.workLocation != undefined">
          <fa-icon [icon]="redditIcon" class="redditcss" size="2x"></fa-icon>
          <div class="message-content">
            <div class="ai-code python_demo">
              <p>
                According to the above development tasks and the product
                database, I analyze that the following contents need to be
                modified:
              </p>
              <h4 class="ui horizontal divider header">
                <fa-icon [icon]="faCoffee" class="coffee"></fa-icon>
                Project Files
              </h4>
              <div class="ui yellow visible message">
                Operation:
                <button class="ui teal button tiny" (click)="pushCode()"
                style="color: #ffffff;
                background-color: #073262;">
                  <fa-icon [icon]="faTask" class="tasks icon"></fa-icon>Submit Code
                </button>
              </div>

              <table class="ui definition table" *ngFor="let data of gptCode, let i = index">
                <tbody id="task_tbod_kwrn0odu31699509962510">
                  <tr>
                    <td class="two wide column">Modify file</td>
                    <td>{{ data.filepath }}</td>
                  </tr>
                  <tr>
                    <td>Reason for modification</td>
                    <td>
                      <div [innerText]="data.code_interpreter"></div>
                    </td>
                  </tr>
                  <tr *ngIf="data.adjustCode != undefined">
                    <td>Adjust code</td>
                    <td>
                      <div [innerHTML]="data.adjustCode"></div>
                    </td>
                  </tr>
                  <tr>
                    <td>Status</td>
                    <td>
                      <button
                        class="ui grey button tiny" style="color:#ffffff; background-color:#1BA641; margin-right: 2px;"
                        *ngIf="data.IsAdjusted == true"
                      >
                        <fa-icon [icon]="faEdit" class="check icon"></fa-icon>
                        Adjusted
                      </button>
                      <button
                        class="ui circular check icon button task_status_button tiny"
                        style="color: #fff;
                        background-color: #1BA641;"
                        [ngClass]="data.code == undefined ? 'olive' : 'green'"
                        (click)="
                          selfCheckBtn(data, data.filepath, 'InitialCode')
                        "

                      >
                        <fa-icon
                          [icon]="data.code == undefined ? spinner : faCheck"
                          [spin]="data.code == undefined ? true : false"
                          class="check icon"
                        ></fa-icon>
                        Initial Code
                      </button>

                      <button
                        class="ui circular check icon button task_status_button tiny"
                        style="margin-left:2px!important;"
                        [ngClass]="
                          data.selfCheck.__zone_symbol__value.length == 0
                            ? 'olive'
                            : data.selfCheck.__zone_symbol__value.success ==
                              'success'
                            ? 'green'
                            : 'red'
                        "
                        (click)="
                          selfCheckBtn(
                            data.selfCheck.__zone_symbol__value,
                            data.filepath,
                            'SelfCheck'
                          )
                        "

                      >
                        <fa-icon
                          [icon]="
                            data.selfCheck.__zone_symbol__value.length == 0
                              ? spinner
                              : data.selfCheck.__zone_symbol__value.success ==
                                'success'
                              ? faCheck
                              : faClose
                          "
                          [spin]="
                            data.selfCheck.__zone_symbol__value.length == 0
                              ? true
                              : false
                          "
                          class="check icon"
                        ></fa-icon>
                        Self Check
                      </button>

                      <button
                        class="ui circular check green icon button task_status_button tiny"
                        style="color: #fff;
                        background-color: #1BA641;
                        margin-left:2px!important;
                        margin-top: 2px;"
                        (click)="
                          selfCheckBtn(
                            data.checklint.__zone_symbol__value,
                            data.filepath,
                            'StaticScan'
                          )
                        "
                        *ngIf="data.selfCheck.__zone_symbol__value.length != 0"
                      >
                        <fa-icon [icon]="faCheck" class="check icon"></fa-icon>
                        Static scan
                      </button>
                    </td>
                  </tr>

                  <tr>
                    <td>Operations</td>
                    <td>
                      <button
                        class="ui blue button tiny"
                        (click)="adjustCodebtn(data)"
                        style="color: #fff;
                        background-color: #007199;
                        "
                      >
                        <fa-icon [icon]="faEdit" class="check icon"></fa-icon>
                        Adjust Code
                      </button>
                      <button
                        class="ui orange button tiny"
                        (click)="EditFileTask(i)"
                        style="color: #fff;
                        background-color: #F1743C;
                        margin-left:2px!important"
                      >
                        <fa-icon [icon]="data.IsRestartLoading == true ? spinner : faPlay" [spin]="data.IsRestartLoading == true ? true : false" class="check icon"></fa-icon>
                        Restart
                      </button>
                      <button
                        class="ui brown button tiny"
                        (click)="reviewCode(data)"
                        style="color: #fff;
                        background-color: #b43b44;
                        margin-left:1px!important;
                        margin-top:1px!important;"
                      >
                        <fa-icon [icon]="faCode" class="check icon"></fa-icon>
                        Review Code
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!-- <div [class]="msg.msgClass" *ngFor="let msg of messages" [ngSwitch]="msg.msgIconClass">
          <fa-icon *ngSwitchCase="'redditcss'" [icon]="msg.msgIcon" [class]="msg.msgIconClass" size="2x"></fa-icon>
          <div class="message-content" [innerHTML]="msg.msgContent">
            <div [innerHTML]="msg.msgContent"></div>
            <button class="ui green button" (click)="msg.submitmethod" [disabled]="msg.SubmitBtnDisable" [hidden]="msg.submitHidden">Submit</button>
            <button class="ui green button" (click)="msg.editMethod" [disabled]="msg.EditBtnDisable" [hidden]="msg.editHidden">Edit</button>
          </div>
          <fa-icon *ngSwitchCase="'odnoklassnikicss'" [icon]="msg.msgIcon" [class]="msg.msgIconClass" size="2x"></fa-icon>
        </div> -->
      </div>

      <!-- EDIT CODE POPUP -->
      <!-- Button trigger modal -->
<button type="button" style="display: none;" id="hbtn3" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal3" style="display: none;">
  Open Modal
</button>

<!-- Modal -->
<div class="modal fade" id="modal3" tabindex="-1" aria-labelledby="modal3Label" aria-hidden="true" *ngIf="codeEdit">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modal3Label">Code Editor</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="editClose()"></button>
      </div>
      <div class="modal-body">
        <div id="diff-output">
          <pre [contentEditable]="EditConsoleEnable" id="code" [innerText]="codeData.updatedCode"></pre>
        </div>
      </div>
      <div class="modal-footer">
        <input type="hidden" id="code-actions-uuid" value="iw68pve7n1699601042887" />
        <!-- <button type="button" class="btn btn-warning" style="margin-left: 1px !important;" (click)="enableCodeEditor()" [hidden]="editBtnHidden">
          Edit
        </button>
        <button type="button" class="btn btn-warning" style="margin-left: 1px !important;" (click)="updateCode('', 'editCode')" [hidden]="!editBtnHidden">
          Update
        </button> -->
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="editClose()">Close</button>
      </div>
    </div>
  </div>
</div>


      <!-- SELF CHECK -->
      <button type="button" class="btn btn-primary" style="display: none;" id="hbtn" data-bs-toggle="modal" data-bs-target="#modal1">
        Open Modal
      </button>
      <div class="modal fade" id="modal1" tabindex="-1" aria-labelledby="modal1Label" aria-hidden="true" *ngIf="selfCheckPopup">
        <div class="modal-dialog modal-dialog-scrollable">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="modal1Label">{{ selfcheckFilepath }}</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="selfCheckClose()"></button>
            </div>
            <div class="modal-body">
              <h4 [innerHTML]="selfCheckReason"></h4>
              <pre [innerText]="selfCheckCode"></pre>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" (click)="selfCheckClose()">Close</button>
            </div>
          </div>
        </div>
      </div>


      <!--  -->

      <!-- EDIT FILE TASK POPUP -->

    <!-- Button trigger modal -->
<!-- Button trigger modal -->
<button type="button" style="display: none;" id="hbtn2" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal2">
  Open Modal
</button>

<!-- Modal -->
<div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="modal1Label" aria-hidden="true">
  <div class="modal-dialog modal-dialog-scrollable">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="modal2Label">Modify File</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <!-- Your modal content goes here -->
        <div class="form-group">
          <label for="filePath">Modify file</label>
          <input type="text" class="form-control" id="filePath" [value]="editFileData?.filepath" />
        </div>
        <div class="form-group">
          <label for="reason">Reason for modification</label>
          <textarea class="form-control" id="reason" rows="4" [value]="editFileData?.code_interpreter"></textarea>
        </div>
        <div class="form-group">
          <label for="NewFileTask">New Task</label>
          <textarea class="form-control" id="NewFileTask" [(ngModel)]="newFileTask"></textarea>
          <span *ngIf="fileTaskError" style="color: red; font-size: smaller">*New task text is mandatory</span>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-success" (click)="adjustCodeSubmit(editFileData?.filepath)">Submit</button>
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>



      <div *ngIf="isLoading" class="LoadingDiv">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Generating answer, please wait....
      </div>
    </div>
  </div>

  <div class="seacrh-bar-container"  [hidden]="promptSearchHidden">
    <div class="search-bar" style="border-radius:0px" [ngClass]="promptError == true ? 'error' : ''">
      <textarea
        class="search-bar-input"
        type="text"

        placeholder="Detail your requirement here"
        [(ngModel)]="formData.userPrompt"
        [value]="formData.userPrompt"
      ></textarea>
    </div>
    <button style="border-radius: 0px;" class="search-btn" (click)="SubmitClick()">
      <fa-icon [icon]="faPaperPlane"></fa-icon> Send
    </button>
    <!-- <button title="Download File" class="download-btn" (click)="generateAndDownloadLastPDF()">
        <fa-icon [icon]="faDownload"></fa-icon>
      </button> -->
  </div>
</div>
