import { Component,ViewChild,ElementRef } from '@angular/core';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { CommonService } from 'src/app/shared/Utils/CommonService';
import { Constants } from 'src/app/shared/Utils/Constants';
import { FileService } from 'src/app/shared/service/file.service';

interface chats {
  author: string;
  content: any;
  timestamp: Date;
  type: number; // 0 for normal text, 1 for response type 1 , 2 for response type 2, 3 for response type 3
  file?: any;
  blob?: Blob;
}

@Component({
  selector: 'app-usecase-two-fine-tune-model',
  templateUrl: './usecase-two-fine-tune-model.component.html',
  styleUrls: ['./usecase-two-fine-tune-model.component.css']
})
export class UsecaseTwoFineTuneModelComponent {
  @ViewChild('messageContainer') messageContainer!: ElementRef;
  faDownload = faDownload;
  faPaperPlane = faPaperPlane;
  isLoading: boolean = false;
  ResponseMessage: string = '';

  messages: chats[] = [];
  textField: string='';

  constructor(private commonService: CommonService, private fileService:FileService) { }

  scrollToBottom(): void {
    try {
      this.messageContainer.nativeElement.scrollTop = this.messageContainer.nativeElement.scrollHeight;
    } catch (err) {
      console.error(err);
    }
  }

  // send text message to get response
  sendMessage(text: string) {
    if (text != '') {
      this.messages.push({ author: 'user', content: text, timestamp: new Date(), type: 0 });
      this.getResponseOneToGetContent(text);
      setTimeout(() => {this.textField = ''; this.scrollToBottom();}, 500);
    }
  }

  // Get Api response for user query
  getResponseOneToGetContent(text: string) {
    this.isLoading = true;
    let url = Constants.config.fine_tune_model_api + 'Extract_Features';
    let body = {
      query: text
    }
    this.commonService.postMethodAxios(url, body)
      .then((response: any) => {
        if (response.data.customer_name) {
          this.messages.push({ author: 'bot', content: response.data, timestamp: new Date(), type: 1 });
          this.getResponseTwoToGetContent(response.data);
          setTimeout(() => {this.scrollToBottom();}, 500);
        }
        else {
          this.messages.push({ author: 'bot', content: response.data, timestamp: new Date(), type: 0 });
          this.isLoading = false;
          setTimeout(() => {this.scrollToBottom();}, 500);
        }
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error.message);
        this.messages.push({ author: 'bot', content: "Didn't get what you are asking for!!", timestamp: new Date(), type: 0 });
        setTimeout(() => {this.scrollToBottom();}, 500);
      })
  }

  getResponseTwoToGetContent(input: any) {
    let url = Constants.config.fine_tune_model_api + 'Append_Content';
    let body = {
      customer_name: input.customer_name,
      features: input.features.toString().split(',')
    }
    this.commonService.postMethodAxios(url, body)
      .then((response: any) => {
        console.log(response.data);
        this.messages.push({ author: 'bot', content: response.data, timestamp: new Date(), type: 2 });
        this.getResponseThreeToGetContent(input, response.data);
        setTimeout(() => {this.scrollToBottom();}, 500);
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error.message);
        this.messages.push({ author: 'bot', content: "There is issue in the backend please try again in a while.", timestamp: new Date(), type: 0 });
        setTimeout(() => {this.scrollToBottom();}, 500);
      })
  }

  async getResponseThreeToGetContent(input1: any, input2: any){
    let url = Constants.config.fine_tune_model_api + 'pdf_Generator';
    let body = {
      customer_name: input1.customer_name,
      project_name: input1.project_name,
      content: input2
    }
    this.commonService.postMethodAxios(url, body)
      .then((response: any) => {
        var blob = new Blob([response.data.toString()], { type: response.headers['content-type'] });
        var fileURL = window.URL.createObjectURL(blob) + '#toolbar=0&navpanes=0&scrollbar=0&view=FitW';
        this.messages.push({ author: 'bot', content: response.data, timestamp: new Date(), blob: blob, file: { url: fileURL, type: response.headers['content-type'], customer_name: input1.customer_name, project_name: input1.project_name, content: input2 }, type: 3 });
        this.isLoading = false;
        setTimeout(() => {this.scrollToBottom();}, 500);
      })
      .catch((error: any) => {
        this.isLoading = false;
        console.error(error.message);
        this.messages.push({ author: 'bot', content: "There is issue in the backend please try again in a while.", timestamp: new Date(), type: 0 });
        setTimeout(() => {this.scrollToBottom();}, 500);
      })
  }
  // generateAndDownloadLastPDF() {
  //   this.messages.reverse().forEach((message) => {
  //     if (message.file) {
  //       this.generateAndDownloadDocx(message.file)
  //       return;
  //     }
  //   })
  //   this.messages.reverse();
  // }
  generateAndDownloadDocx(file:any) {
    let url = Constants.config.fine_tune_model_api + 'docx_Generator'
    let body = {
      customer_name: file.customer_name,
      project_name: file.project_name,
      content: file.content
    }
    this.fileService.post(url,body)
  }

}
