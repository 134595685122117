<!-- <app-sop-sidenav></app-sop-sidenav> -->
<div class="background-container px-md-5 pb-5">

  <!-- <img src="assets/background.png" alt="Background Image" class="background-image"> -->
  <br>

  <div class="dropdown-container">
    <div class="dropdown">
      <label for="converse_function_dropdown">Select Function</label><br>
      <select id="converse_function_dropdown" [(ngModel)]="functionSelected" (ngModelChange)="onFunctionSelected()">
        <option *ngFor="let function of functionList" [value]="function?.name">{{function?.name}}</option>
      </select>
    </div>
    
  <div style="text-align: right;">
  <!-- <button  class="submit-button" (click)="gethistory()">Previous responses</button> -->
  <button  class="bg-primary text-light p-2 px-4 border border-r1" title="Download PDF" (click)="downloadAsPdf()">Download
    <i class="fa fa-download" aria-hidden="true"></i>
  </button>
</div>
</div>
<div>
  </div>

  <div *ngIf="chatMessages.length==0">
    <h6 style="text-align: center;">No record Found!!</h6>
  </div>


  <div *ngIf="showLoading" class="loading-indicator">
    <i class="fas fa-spinner fa-spin"></i> Getting responses...
  </div>

  <div *ngIf="chatMessages.length>0">
    <div class="mt-4">
      <div class="overflow-auto">
        <div style="max-height: 500px; overflow-y: auto;" class="table-responsive-lg"   >
          <table class="table table-sm table-hover table-bordered" id="text-d" >
            <thead class="thead-light">
              <tr style="text-align: center;">
                <th>#</th>
                <th>Function type</th>
                <th>Language</th>
                <th>Question</th>
                <th>Answer</th>
                <!-- <th>Sources</th> -->
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let message of chatMessages;let i = index" style="text-align: center;">
                <td>{{ i + 1 }}</td>
                <td>{{ message?.function_type }}</td>
                <td>{{ message?.language }}</td>
                <td>{{ message?.prompt }}</td>
                <td lineNumbers markdown [innerHTML]="message?.answer" [disableSanitizer]="true"></td>
                <!-- <td>{{ message?.source }}</td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    


  </div>




</div>
