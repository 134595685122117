import { Injectable } from '@angular/core';
import { BaseHttpService } from './base-http.service';
import { SharedService } from './shared.service';
import { Observable } from 'rxjs';
import { CodeEnhance, EnhanceCodeResponse } from '../data/code-enhance-model';
import { FileModel } from '../data/app-model';
import { HttpClient } from '@angular/common/http';
import { ApiEndpoints } from '../data/global-vars';

@Injectable({
  providedIn: 'root'
})
export class CodeEnhanceService {

  constructor(private http: HttpClient,
    private baseHttp: BaseHttpService,
    private sharedService: SharedService) { }

  uploadFile(data: CodeEnhance, files: FileModel[]): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('file', files[0].file_data, files[0].file_data.name);
    //let formData: FormData = this.sharedService.addDataToForm(null, files);
    return this.baseHttp.post<any>(ApiEndpoints.UploadFile, formData);
  }

  enhanceCode(data: string): Observable<any> {
    //let formData: FormData = this.sharedService.addDataToForm(data, null);
    return this.baseHttp.post<any>(ApiEndpoints.Enhance, data, true, true);
  }
}
