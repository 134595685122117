<app-sop-sidenav></app-sop-sidenav>

<div class="row" >

  <app-upload-file  placeholder="Only .docx files are allowed (max Size 16 MB)"
inputAccept=".docx"
(fileuploaded)="postUpload($event)"
maxFileSize=16>
</app-upload-file>





</div>

<div class="row px-5 " >

  <div class="col-12 file-list">
      File Name:
      <h6 style="display: contents; text-decoration: underline;">
          {{fileData?.name}}
          <span (click)="resetFile()">
              <i class="fa fa-close" style="color:red;"></i>
          </span>
      </h6>
  </div>

<div class="tab-content" style="height: 500px; overflow: scroll; padding: 25px;">
  <div class="container mt-2">
    <div class="card" style="background-color: #fff; border-radius: 15px; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);">
      <div class="card-header d-flex align-items-center justify-content-between" style="background-color: #f8f9fa; color: #00aecf; border-top-left-radius: 15px; border-top-right-radius: 15px;">
        <!-- <div class="d-flex align-items-center" style="position:absolute; right:0;"> -->
          <div class="col-12 file-list">
            File Name:
            <h6 style="display: contents; text-decoration: underline;">
                <!-- {{fileData?.name}} -->
                <span (click)="resetFile()">
                    <i class="fa fa-close" style="color:red;"></i>
                </span>
            </h6>
        </div>
        <!-- </div> -->
      </div>


      <div class="card-body chat-body">
        <div class="chat-container">
          <div class="chat-messages">
            <div *ngIf="chatMessages.length === 0">
              <div class="message-container bot">
                <div class="message-content">
                  <!-- <span>Hi, Welcome to Test case generator! I'm here to help you .</span> -->
                </div>
              </div>
            </div>
            <div class="message-container" *ngFor="let message of chatMessages; let i = index" [ngClass]="{'user': message.user === 'user', 'bot': message.user === 'bot'}">
              <div class="message-content">
                <div  class="message-header">
                  <span *ngIf="message.user === 'user'" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                      <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                    </svg>
                  </span>
                  <span *ngIf="message.user === 'bot'" >
                    <img data-v-ac8ca204="" class="app-preview__image-origin" srcset="https://img.icons8.com/?size=256&amp;id=50200&amp;format=png 1x, https://img.icons8.com/?size=512&amp;id=50200&amp;format=png 2x" width="24" height="24" alt="Bot icon" style="">
                  </span>
                  <span lineNumbers markdown>{{ message.text }}</span>
                </div>
                <div class="message-actions" style="display: flex; align-items: center; ">
                  <button style="background-color:transparent;color: #0d0d0d;" class="btn btn-outline-secondary btn-sm" (click)="copyMessage(message.text)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                    </svg>


                  </button>
                  <button style="background-color:transparent;color: #0d0d0d;" *ngIf="message.user === 'user'" class="btn btn-outline-secondary btn-sm" (click)="regenerateAnswer(message.text)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                      <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                      <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <br>
            <br>
            <br>
          </div>
        </div>
      </div>
      <div class="card-footer fixed-bottom custom-card-footer">
        <div class="input-group custom-input-group">
          <textarea
            class="form-control custom-textarea"
            [(ngModel)]="userMessage"
            (keyup.enter)="sendMessage()"
            placeholder="Type your message..."
          ></textarea>
          <button
            *ngIf="!qloader"
            [disabled]="!userMessage.trim()"
            style="background-color: transparent !important; color: #00aecf !important;"
            class="btn btn-primary custom-button"
            (click)="sendMessage()"
          >
            <svg
              *ngIf="!qloader"
              style="width: 24px; height: 24px; vertical-align: middle;"
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
            </svg>

          </button>
        </div>

      </div>
    </div>
  </div>
</div>
