import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  conversation = [
    { text: 'Hey, Shanmukha, what is going on? You seem really stressed.', sender: 'hafeez' },
    { text: 'I\'m really struggling with these interviews. There are so many candidates, and I am stressed. I wish there was a way to make this process easier.', sender: 'shanmukha' },
    { text: 'I just can\'t keep up with all these interviews. I have so many questions to ask which I am not much proficient with, and it\'s difficult to keep track of everything.', sender: 'shanmukha' },
    { text: 'Shanmukha, I understand your frustration. But have you heard about the Interview Assistant tool? It could be the solution to your problems. The Interview Assistant is an incredible tool that streamlines the interview process. It generates questions based on the candidate\'s CV and the job description, saving your time and effort in preparing.', sender: 'hafeez' },
    { text: 'That would be such a time-saver! But what about evaluating the candidates? That\'s another challenge for me.', sender: 'shanmukha' },
    { text: 'The Interview Assistant has got you covered there too. It generates an evaluation report after each interview, capturing the candidate\'s strengths, weaknesses, and overall performance. This will help you make well-informed decisions. Let me assign an interview role to you Shanmukha and give you the access to that module and share the URL as well.', sender: 'monalisa' },
    { text: 'Absolutely, Shanmukha. You\'ll see a positive impact on your productivity and overall experience. We\'re excited to see the tool in action and witness the benefits firsthand.', sender: 'basav' },
    { text: 'Thank you all so much! This will make a huge difference.', sender: 'shanmukha' }
  ];
  messages: { text: string; sender: string }[] = [];
  messageIndex = 0;

  ngOnInit() {
    this.autoChat();
  }

  autoChat() {
    if (this.messageIndex < this.conversation.length) {
      this.messages.push(this.conversation[this.messageIndex]);
      this.messageIndex++;
      setTimeout(() => this.autoChat(), 2000); // Adjust the timing as needed
    }
  }

  getProfilePicture(sender: string): string {
    switch(sender) {
      case 'hafeez':
        return 'assets/izaz.png';
      case 'shanmukha':
        return 'assets/shanmukh.png';
      case 'monalisa':
        return 'assets/mona.png';
      case 'basav':
        return 'assets/basav.png';
      default:
        return 'assets/default.jfif';
    }
  }

  getMessageClass(sender: string): string {
    switch(sender) {
      case 'hafeez':
        return 'hafeez-message';
      case 'shanmukha':
        return 'shanmukha-message';
      case 'monalisa':
        return 'monalisa-message';
      case 'basav':
        return 'basav-message';
      default:
        return '';
    }
  }
}
