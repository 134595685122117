<div class="wrapper">
    <!-- <app-sop-sidenav></app-sop-sidenav> -->
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid responsive">
            <div class="container">

                <div class="row flex-nowrap">
                    <main id="main">
                        <div class="row mt-5 mb-4">
                            <div class="col-12" style="text-align: center;">
                                <h4>
                                    <link rel="stylesheet"
                                        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
                                        integrity="sha384-..." crossorigin="anonymous">

                                    Manage your Files
                                </h4>
                            </div>
                        </div>

                        <div class="row py-4">
                            <div class="col-md-12">
                                <div class="selectlang" align="center">
                                    <ng-container *ngIf="functionList.length > 0">

                                       <!-- Select Function -->
                                        <select class="i" tooltip="Select the function type" id="converse_function_dropdown" name="functionname" title="Select Function" [(ngModel)]="functionSelected">
                                            <option *ngFor="let function of functionList" [value]="function?.value">
                                                {{function?.name}}
                                            </option>
                                        </select>

                                        <button class="bg-primary text-light p-2 px-4 border border-r1"
                                        tooltip="select to get list of files" title="File List"
                                         (click)="filelist()" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                          <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"/>
                                        </svg> </button>

                                     <button class="bg-primary text-light p-2 px-4 border border-r1"
                                        tooltip="select to upload files" title="File Upload"
                                          (click)="fileupload()"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cloud-arrow-up-fill" viewBox="0 0 16 16">
                                            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 5.146a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0z"/>
                                          </svg></button>

                                        <!-- <button class="bg-primary text-light p-2 px-4 border border-r1"
                                            (click)="translateContent()"> Translate </button> -->

                                    </ng-container>
                                    <button *ngIf="downloadStatus"
                                        class="bg-success text-light p-2 px-4 border border-r1"
                                        (click)="downloadFile()"> Download </button>

                                </div>

                            </div>
                        </div>
                        <div id="text-d" class="row" *ngIf="dupload">
                          <div class="container mt-4">
                            <div class="overflow-auto">
                              <div style="height: 400px;">
                          <table class="table table-dark">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>File Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              <tr *ngFor="let fileName of doclist;let i = index">
                                <td>{{ i + 1 }}</td>
                                <td >{{ fileName }}</td>
                                <td>
                                  <button title="delete"
                                  class="btn btn-dark"
                                  (click)="confirmDelete(fileName)">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                  </svg>
                                   </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
</div>
</div>
</div>
                        </div>

                        <div class="row" *ngIf="fupload">

                            <app-upload-file  placeholder="Only .pdf file are allowed (max Size 16 MB)"
                                inputAccept=".pdf,.PDF"
                                (fileuploaded)="postUpload($event)" maxFileSize=16> </app-upload-file>




                        </div>

                        <div class="row px-5 " *ngIf="fileUploadedStatus || downloadStatus">

                            <div class="col-12 file-list">
                                File Name:
                                <h6 style="display: contents; text-decoration: underline;">
                                    {{fileData?.name}}
                                    <span (click)="resetFile()">
                                        <i class="fa fa-close" style="color:red;"></i>
                                    </span>
                                </h6>
                            </div>

                        </div>



                    </main>
                </div>
            </div>
        </div>
    </section>


    <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>
