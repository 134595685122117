import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  private loading: boolean;

  constructor(private http: HttpClient) {
    this.loading = true;
  }

  //baseurl = "http://127.0.0.1:8051";
  //baseurl = GlobalVars.ApiBaseUrl;
  baseurl = environment.apiURL1;

  private setHeaders(loading: boolean, postLoading: boolean = false) {
    let headers = new HttpHeaders({
      "loading": loading ? "true" : "false",
      //"Content-Type": "multipart/form-data",
      //"Accept" :"application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true"
    })

    return headers;
  }
  public setLoading(loading: boolean): void {
    this.loading = loading;
  }


  public get<T>(url: string, loading: boolean = true, httpParams?: HttpParams, loadingpostresp: boolean = false): Observable<T> {
    var Headers = this.setHeaders(loading);
    url = this.baseurl + url;
    return this.http.get<T>(url, { headers: Headers, params: httpParams }).pipe(
      catchError(this.handleError)
    );
  }

  public post<T>(url: string, body?: any, loading: boolean = true, loadingpostresp: boolean = false): Observable<T> {
    var Headers = this.setHeaders(loading, loadingpostresp);
    url = this.baseurl + url;
    return this.http.post<T>(url, body, { headers: Headers }).pipe(
      catchError(this.handleError)
    );
  }

  public put<T>(url: string, body?: any, loading: boolean = true, loadingpostresp: boolean = false): Observable<T> {
    var Headers = this.setHeaders(loading);
    return this.http.put<T>(url, body, { headers: Headers }).pipe(
      catchError(this.handleError)
    );
  }

  public delete<T>(url: string, loading: boolean = true, loadingpostresp: boolean = false): Observable<T> {
    var Headers = this.setHeaders(loading);
    return this.http.delete<T>(url, { headers: Headers }).pipe(
      catchError(this.handleError)
    );
  }

  public getFromFile<T>(url: string, loading: boolean = true, httpParams?: HttpParams): Observable<T> {
    var Headers = this.setHeaders(loading);
    //url = this.baseurl + url;
    return this.http.get<T>(url, { headers: Headers, params: httpParams }).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    // Handle your error logic here, e.g., show error message
    console.error('An error occurred:', error);
    return throwError(() => 'Something went wrong. Please try again later.');
  }

}
