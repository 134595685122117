import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-code-gen-ylite-ai',
  templateUrl: './code-gen-ylite-ai.component.html',
  styleUrls: ['./code-gen-ylite-ai.component.scss']
})
export class CodeGenYLiteAIComponent implements OnInit {
  title: boolean = false;
  showBuildProject: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute) { }

  isBuildProjectActive(): boolean {
    // Dummy implementation, modify as needed
    return this.showBuildProject;
  }

  toggleBuildProject() {
    this.router.navigate(['/codeGenYLiteAI']);

    this.showBuildProject = !this.showBuildProject;
  }
  ngOnInit(): void {
    // Initialize title based on current route
    this.updateTitleBasedOnRoute();

    // Subscribe to router events to update title on route changes
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateTitleBasedOnRoute();
      }
    });
  }
  isTabActive(tabRoute: string): boolean {
    return this.router.url === tabRoute;
  }
  private updateTitleBasedOnRoute(): void {
    const currentRoute = this.router.url;

    // Check if current route matches any of the expected routes
    if (currentRoute === '/codeGenYLiteAI/brd' ||
        currentRoute === '/codeGenYLiteAI/sdd' ||
        currentRoute === '/codeGenYLiteAI/apigenerate' ||
        currentRoute === '/codeGenYLiteAI/codegenerate' ||
        currentRoute === '/codeGenYLiteAI/chatcode' ||
        currentRoute === '/codeGenYLiteAI/tcgenerate') {
      this.title = true;
    }

    if (currentRoute === '/codeGenYLiteAI/brd' ||
    currentRoute === '/codeGenYLiteAI/sdd' ||
    currentRoute === '/codeGenYLiteAI/apigenerate' ||
    currentRoute === '/codeGenYLiteAI/codegenerate' ||
    currentRoute === '/codeGenYLiteAI/tcgenerate'
) {
      this.showBuildProject = true;
    }
    if (currentRoute === '/codeGenYLiteAI/chatcode' ||
    currentRoute === '/codeGenyliteAI/enhacecode' ||
    currentRoute === '/codeGenYLiteAI/documentcode' ) {
      this.showBuildProject = false;
    }
    else {
      this.title = false;
    }
  }
}
