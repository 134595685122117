import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {


  // this is new tab section --------------------- 
  activatedTab:number = 0;

  constructor(private route: ActivatedRoute){}
  ngOnInit(): void {
    const value: number = this.route.snapshot.params['tab'];
    if (value && value > 0 && value < 4) {
      /*setTimeout(() => {    */
        this.setTab(Number(value));
      /*}, 3000);*/
      
    }
    else if(localStorage.getItem('tab')){
      this.activatedTab = Number(localStorage.getItem('tab'));
      this.setTab(this.activatedTab);
      
    }
  }

  setTab(index: number) {
  this.activatedTab = index;
  }
  

}
