import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from '../shared/service/loader.service';
import { SopService } from '../shared/service/sop.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-sop-upload-botdoc',
  templateUrl: './sop-upload-botdoc.component.html',
  styleUrls: ['./sop-upload-botdoc.component.scss']
})
export class SopUploadBotdocComponent implements  OnInit, OnDestroy {
[x: string]: any;

  downloadStatus = false;

  processStatus: boolean = false;


  targetSASUrl: any;
  fileUploadedStatus = false;
  fileData: any;
  userOHR: any;
 fupload:boolean=false;

  intervalId: any; // Variable to store the interval ID

  functionList: any = [];
  functionSelected: any;
  subscription!: Subscription;
  functionname: any;
  dupload: boolean=false;
  doclist: any;
  dfileselected: any;
  //
  // selectedLink: any;
  // maxLink: any;
  // i: any;
  constructor(

    private loaderService: LoaderService,
    private toastMessage:ToastMessageService,
    private sopService: SopService
  ) {

  }

  ngOnInit() {
    this.startInterval();
    this.getUserRole();
  }



  // Upload file for translation
  postUpload(event: any) {
    this.loaderService.loading.next(true);

    if (event?.name) {
      this.fileData = event;
      console.log(this.fileData?.file, this.fileData?.name)

    }
    this.getOHR();

    const formData = new FormData();
    formData.append('file', this.fileData?.file);
    //formData.append('user_id', this.userOHR);
    formData.append('app-name', this.functionSelected);
    this.sopService.uploadfilelist(formData).subscribe(
      (response: any) => {
        this.loaderService.loading.next(false);
        console.log(response)
        if( response?.status == 'success'){
          //this.fileUploadedStatus = true;
          this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
         }else{
          this.toastMessage.showError(response?.message);
         }
      },
      (error) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );

  }
//
fileupload(){
  this.fupload=!this.fupload;
  this.dupload=false;
 }
filelist(){
  this.fupload= false;
  this.dupload=true;
  const formData = new FormData();
  this.loaderService.loading.next(true);
  formData.append('app-name', this.functionSelected);

  this.sopService.filelist(formData).subscribe(
    (response: any) => {
      this.loaderService.loading.next(false);
      this.doclist=response?.data;
      console.log(this.doclist)
      console.log(response);
      if( response?.status == 'success'){
        //this.fileUploadedStatus = true;
        this.toastMessage.showSuccess(response?.message);
       }else{
        this.toastMessage.showError(response?.message);
       }
    },
    (error) => {
      // Handle the error here
      this.loaderService.loading.next(false);
      console.error(error);
      this.toastMessage.showError(error?.message);
    }
  );

}
confirmDelete(fileName: string): void {
  const isConfirmed = confirm(`Are you sure you want to delete ${fileName}?`);

  if (isConfirmed) {
    // Implement your delete logic here using the fileName
    this.toastMessage.showWarning(`Deleting file: ${fileName}`);
    this.deletelist(fileName);
  } else {
    // User clicked 'Cancel' or closed the dialog
    this.toastMessage.showWarning(`Deletion canceled for file: ${fileName}`);
    console.log(`Deletion canceled for file: ${fileName}`);
  }
}
// deletelist(fileName: string){
//   this.toastMessage.showSuccess(`Deleted file: ${fileName}`)
//   console.log(`Deleting file: ${fileName}`)
//   console.log(this.functionSelected)
// }
deletelist(fileName: string){
  this.fupload= false;
this.loaderService.loading.next(true);
  const formData = new FormData();
  formData.append('app-name', this.functionSelected);
  formData.append('doc-name', fileName);

  this.sopService.delfilelist(formData).subscribe(
    (response: any) => {
      console.log(response)
      if( response?.status == 'success'){
        //this.fileUploadedStatus = true;
        this.loaderService.loading.next(false);
        this.toastMessage.showSuccess(response?.message+":"+fileName+"\n refreshing the file list");
        this.filelist();
       }else{
        this.toastMessage.showError(response?.message);
       }
    },
    (error) => {
      // Handle the error here
      this.loaderService.loading.next(false);
      console.error(error);
      this.toastMessage.showError(error?.message);
    }
  );
 // this.filelist();
}
  // translate file  after upload
  translateContent() {


    this.loaderService.loading.next(true);
    this.getOHR();

    const formData = new FormData();
    formData.append('filename', this.fileData?.name);
    formData.append('user_id', this.userOHR);




  }
//file upload



  // check translation status

  getTranslateStatus() {
    let translateData = null;
    this.processStatus = true;
    if(localStorage.getItem('f_tarnslate')){
    this.loaderService.loading.next(true);

     translateData = JSON.parse( localStorage.getItem('f_tarnslate') || '');

     this.fileData = {name:translateData?.file_name};
     this.targetSASUrl = translateData?.target_blob_url || '';


    const formData = new FormData();
    formData.append('translation_id', translateData?.translation_id);



    }
  }


  // Download translated file
  downloadFile() {
    window.location.href = this.targetSASUrl;
    this.loaderService.loading.next(true);

    setTimeout(() => {
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('Downloading File.');
      this.resetFile();
    }, 2000);
  }

  // reset upload file

  resetFile() {
    this.fileUploadedStatus = false;
    this.fileData = '';
    this.downloadStatus = false;
    this.targetSASUrl = '';
    localStorage.removeItem('f_tarnslate');
  }


  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  // checking status of file per INterver till successed

  startInterval(): void {
    if(localStorage.getItem('f_tarnslate')){
    this.getTranslateStatus();

    this.intervalId = setInterval(() => {
      // Your function to be called every 15 seconds
      console.log('interval call')
      this.getTranslateStatus();

    }, 15000); // 15 seconds in milliseconds

  }
}

  stopInterval() {
    this.processStatus = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  resetForm(event: any) {
    localStorage.removeItem('f_tarnslate');

    this.stopInterval();
    this.loaderService.loading.next(false);
  }

  // getUserRole() {
  //   this.subscription = this.sopService.activeUserRole.subscribe(
  //     (res: any) => {
  //       if (res?.data) {
  //         this.functionList = res['data'];
  //         this.functionSelected = res['data'][0]['value'];
  //         this.functionname = res['data'][0]['name'];
  //       }
  //     });
  // }
  getUserRole() {
    this.subscription = this.sopService.activeUserRole.subscribe(
      (res: any) => {
        console.log(res)
        if (res?.all_roles) {
          this.functionList = res['all_roles'];
          this.functionSelected = res['all_roles'][0]['value'];
        //  if(res['all_roles']?.length) this.functionSelected = res['all_roles'][0]['value'];
        //   this['chatPayload'] = null;
        //   this['chatData'] = [];
        }
      });
  }

  ngOnDestroy(): void {
     // Clear the interval when the component is destroyed
     this.stopInterval();
     this.subscription?.unsubscribe();
  }
}
