import { Component, OnInit, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-testcase',
  templateUrl: './testcase.component.html',
  styleUrls: ['./testcase.component.scss']
})
export class TestcaseComponent {

  chatVisible: boolean = false;
  userMessage: string = '';
  chatMessages: any[] = [];
 copied:boolean = false;


















  // uniqId: any[] = [];
  isLoading: boolean = false;
  cmessage!: string;
  qloader: boolean = false;
  fileData: any;








  constructor(private http: HttpClient,
    private route: ActivatedRoute,
    private toastservice : ToastMessageService,
    private loaderservice : LoaderService,
    private modalService: BsModalService,
    private router: Router
) {

}

postUpload(event: any) {
//   this.loaderService.loading.next(true);

  if (event?.name) {
    this.fileData = event;
    console.log(this.fileData?.file, this.fileData?.name)

  }
//   this.getOHR();

//   const formData = new FormData();
//   formData.append('ra_sheet', this.fileData?.file);
//   //formData.append('user_id', this.userOHR);
// //  formData.append('app-name', this.functionSelected);
//   this.tcgService.fileupload(formData).subscribe(
//     (response: any) => {

//       this.loaderService.loading.next(false);
//       console.log(response)
//       if( response?.status == 'success'){
//         this.generatetestcase();
//         this.testcase=response.data;
//         this.fileUploadedStatus = true;
//         this.filestatus1=false;
//         this.toastMessage.showSuccess(response?.message+"\nFileName: "+this.fileData?.name);
//        }else{
//         this.toastMessage.showError(response?.message);
//        }
//     },
//     (error:any) => {
//       // Handle the error here
//       this.loaderService.loading.next(false);
//       console.error(error);
//       this.toastMessage.showError(error?.message);
//     }
//   );

}


resetFile() {
  // this.fileUploadedStatus = false;
  // this.fileData = '';
  // this.downloadStatus = false;
  // this.targetSASUrl = '';
  // this.filestatus1=true;
  // localStorage.removeItem('f_tarnslate');
}














copyMessage(message: string) {
  navigator.clipboard.writeText(message).then(() => {
   this.toastservice.showSuccess('Copied to clipboard');
  });
}


regenerateAnswer(index: any) {

   // this.toastservice.showmessage(this.cmessage);
    const userMessage = index.trim();
    if (!userMessage) return;

    this.chatMessages.push({ user: 'user', text: userMessage });
    this.userMessage = '';

    // Prepare the data to be sent in the API request
    const requestData = {
       // "id": this.uniqId,
        "query": userMessage,
    };

    // Make an API call to your bot here
    // this.panelservice.chatbot(requestData)
    //   .pipe(
    //     catchError((error) => {
    //       console.error(error); // Log the error to the console
    //       return of({ message: "I can't understand. Please try again." }); // Provide a default error response
    //     })
    //   )
    //   .subscribe((response: any) => {
    //     const botMessage = response.data; // Assuming your bot API returns a 'message' field
    //     this.chatMessages.push({ user: 'bot', text: botMessage });
    //   });

}

sendMessage(): void {
  // Handle user messages and bot responses
  this.isLoading = true;
  this.cmessage = this.userMessage;
  const userMessage = this.userMessage.trim();


  if (!userMessage) return;

  this.chatMessages.push({ user: 'user', text: userMessage });
  this.userMessage = '';

  // Prepare the data to be sent in the API request
  const requestData = {
      // "id": this.uniqId,
      "query": userMessage,
  };

  // Make an API call to your bot here
  // this.panelservice.chatbot(requestData)
  //   .pipe(
  //     catchError((error) => {
  //       console.error(error); // Log the error to the console
  //       return of({ message: "I can't understand. Please try again." }); // Provide a default error response
  //     })
  //   )
  //   .subscribe((response: any) => {
  //     const botMessage = response.data; // Assuming your bot API returns a 'message' field
  //     this.chatMessages.push({ user: 'bot', text: botMessage });
  //     this.isLoading=false;
  //   });
}

}
