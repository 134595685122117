<div mat-dialog-content>
  <!-- Your modal content goes here -->
  <div class="close-box" (click)="onClose()">
    <i class="fa-solid fa-xmark"></i>
  </div>
  <div class="popup-content">
  <div class="success">
  <div class="img-header">
    <img src="../../../../../assets/images/done.png" alt="">
  </div>
  <div class="content">
  <p class="awesome">Awesome !</p>
  <p class="text-content">The SDD document is created <br>
    and now ready for download</p>
</div>
</div>
</div>
</div>
<div class="action-btn">
  <!-- <button mat-button (click)="onClose()">Close</button> -->
  <!--<button class="btn btn-custom-open" (click)="openDoc()">Open Document</button>
  <button class="btn btn-custom-Download" (click)="downloadDoc()">Download Document</button>-->
  <!-- <button mat-dialog-close class="btn btn-custom-open">Close</button> -->
  <a mat-dialog-close [href]="fileUrl" class="btn btn-custom-Download" [download]="fileName">Download document</a>

</div>
